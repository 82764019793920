import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/extend.css";

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const nav = [
  // pelajar
  {
    url: "/daftar-pelajar",
    access: ["STUDENT_STUDENT"]
  },
  {
    url: "/form-pelajar/Edit",
    access: ["STUDENT_STUDENT"]
  },
  {
    url: "/form-pelajar/Detail",
    access: ["STUDENT_STUDENT"]
  },
  {
    url: "/daftar-teman-belajar-rekomendasi",
    access: ["STUDENT_RECOMMENDATION"]
  },
  {
    url: "/form-teman-belajar/Detail",
    access: ["STUDENT_RECOMMENDATION"]
  },
  // kelas-private
  // {
  //   url: "/page-error-403",
  //   access: ["CLASS_SLOT-ASSESSMENT"]
  // },
  {
    url: "/daftar-slot-assessment",
    access: ["CLASS_SLOT-ASSESSMENT"]
  },
  {
    url: "/form-slot-assessment/Tambah",
    access: ["CLASS_SLOT-ASSESSMENT"]
  },
  {
    url: "/form-slot-assessment/Ubah",
    access: ["CLASS_SLOT-ASSESSMENT"]
  },
  {
    url: "/jadwal-assessment",
    access: ["SCHEDULE_ASSESSMENT"]
  },
  {
    url: "/form-assessment/Tambah",
    access: ["SCHEDULE_ASSESSMENT"]
  },
  {
    url: "/form-assessment/Ubah",
    access: ["SCHEDULE_ASSESSMENT"]
  },
  {
    url: "/kelola-kelas",
    access: ["CLASS_CLASS"]
  },
  {
    url: "/form-kelas/Tambah",
    access: ["CLASS_CLASS"]
  },
  {
    url: "/form-kelas/Ubah",
    access: ["CLASS_CLASS"]
  },
  {
    url: "/jadwal-kelas",
    access: ["SCHEDULE_SCHEDULE"]
  },
  {
    url: "/form-jadwal/Tambah",
    access: ["SCHEDULE_SCHEDULE"]
  },
  {
    url: "/form-jadwal/Ubah",
    access: ["SCHEDULE_SCHEDULE"]
  },
  {
    url: "/daftar-peminat-kota-lain",
    access: ["ENTHUSIAST_ENTHUSIAST"]
  },
  {
    url: "/form-peminat-kota-lain/Ubah",
    access: ["ENTHUSIAST_ENTHUSIAST"]
  },
  // pencapaian
  {
    url: "/daftar-laporan",
    access: ["CLASS_PARTICIPANT-REPORT"]
  },
  {
    url: "/form-laporan/Detail",
    access: ["CLASS_PARTICIPANT-REPORT"]
  },
  {
    url: "/form-laporan-ubah/Ubah",
    access: ["CLASS_PARTICIPANT-REPORT"]
  },
  {
    url: "/daftar-pencapaian",
    access: ["STUDENT_RECOMMENDATION"]
  },
  {
    url: "/pencapaian-siswa-semua/Detail",
    access: ["STUDENT_RECOMMENDATION"]
  },
  // buku-dan-pesanan
  {
    url: "/daftar-buku",
    access: ["BOOK_BOOK"]
  },
  {
    url: "/form-buku/Tambah",
    access: ["BOOK_BOOK"]
  },
  {
    url: "/form-buku/Ubah",
    access: ["BOOK_BOOK"]
  },
  {
    url: "/form-buku/Detail",
    access: ["BOOK_BOOK"]
  },
  {
    url: "/daftar-paket-buku",
    access: ["BOOK_PACKAGE"]
  },
  {
    url: "/form-paket-buku/Tambah",
    access: ["BOOK_PACKAGE"]
  },
  {
    url: "/form-paket-buku/Edit",
    access: ["BOOK_PACKAGE"]
  },
  {
    url: "/form-paket-buku/Detail",
    access: ["BOOK_PACKAGE"]
  },
  {
    url: "/daftar-pesanan-buku",
    access: ["ORDER_BOOK"]
  },
  {
    url: "/form-pesanan-buku/Edit",
    access: ["ORDER_BOOK"]
  },
  // donasi-dan-infaq
  {
    url: "/daftar-donasi",
    access: ["DONATION_DONATION"]
  },
  {
    url: "/form-donasi/Detail",
    access: ["DONATION_DONATION"]
  },
  {
    url: "/daftar-alokasi-buku",
    access: ["DONATION_ALLOCATION"]
  },
  {
    url: "/daftar-alokasi-buku-pelajar/Alokasi",
    access: ["DONATION_ALLOCATION"]
  },
  {
    url: "/daftar-alokasi-pengeluaran-infaq",
    access: ["INFAQ_ALOKASI-PENGELUARAN"]
  },
  {
    url: "/tambah-pengeluaran/Tambah",
    access: ["INFAQ_ALOKASI-PENGELUARAN"]
  },
  {
    url: "/tambah-pengeluaran/Detail",
    access: ["INFAQ_ALOKASI-PENGELUARAN"]
  },
  // master-data
  {
    url: "/daftar-master-data",
    access: ["MASTER-DATA_MASTER-DATA"]
  },
  {
    url: "/form-master-data/Tambah",
    access: ["MASTER-DATA_MASTER-DATA"]
  },
  {
    url: "/form-master-data/Detail",
    access: ["MASTER-DATA_MASTER-DATA"]
  },
  {
    url: "/form-master-data/Ubah",
    access: ["MASTER-DATA_MASTER-DATA"]
  },
  {
    url: "/master-data-periode",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "form-master-data-periode/Tambah",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "form-master-data-periode/Detail",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "form-master-data-periode/Ubah",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "/master-data-periode",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "master-data-config",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "form-config-master-data/Tambah",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "form-config-master-data/Ubah",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "form-config-master-data/Detail",
    access: ["MASTER-DATA_PERIOD"]
  },
  {
    url: "daftar-provinsi",
    access: ["PROVINCE_PROVINCE"]
  },
  {
    url: "form-provinsi-kabupaten/Edit",
    access: ["PROVINCE_PROVINCE"]
  },
  {
    url: "form-provinsi-kabupaten/Tambah",
    access: ["PROVINCE_PROVINCE"]
  },
  {
    url: "daftar-kabupaten",
    access: ["DISTRICT_DISTRICT"]
  },
  {
    url: "form-kabupaten/Tambah",
    access: ["DISTRICT_DISTRICT"]
  },
  {
    url: "form-kabupaten/Edit",
    access: ["DISTRICT_DISTRICT"]
  },
  // berita-cerita-dan-hikmah
  {
    url: "/daftar-inbox",
    access: ["INBOX_INBOX"]
  },
  {
    url: "/daftar-inbox/Kirim",
    access: ["INBOX_INBOX"]
  },
  {
    url: "/daftar-form-inbox/Ubah",
    access: ["INBOX_INBOX"]
  },
  {
    url: "/daftar-form-inbox/Detail",
    access: ["INBOX_INBOX"]
  },
  {
    url: "/daftar-inbox-config",
    access: ["INBOX_CONFIG"]
  },
  {
    url: "/form-inbox-config/Tambah",
    access: ["INBOX_CONFIG"]
  },
  {
    url: "/form-inbox-config/Detail",
    access: ["INBOX_CONFIG"]
  },
  {
    url: "/daftar-cerita-hikmah",
    access: ["WISDOM_STORY"]
  },
  {
    url: "/form-cerita-hikmah/Tambah",
    access: ["WISDOM_STORY"]
  },
  {
    url: "/form-cerita-hikmah/Detail",
    access: ["WISDOM_STORY"]
  },
  {
    url: "/form-cerita-hikmah/Ubah",
    access: ["WISDOM_STORY"]
  },
  // informasi dan saran
  {
    url: "/tentang-yayasan-dan-aplikasi",
    access: ["ABOUT_ABOUT"]
  },
  {
    url: "/form-tentang/Tambah",
    access: ["ABOUT_ABOUT"]
  },
  {
    url: "/form-tentang/Detail",
    access: ["ABOUT_ABOUT"]
  },
  {
    url: "/form-tentang/Ubah",
    access: ["ABOUT_ABOUT"]
  },
  {
    url: "/daftar-panduan",
    access: ["GUIDE_GUIDE"]
  },
  {
    url: "/form-panduan/Tambah",
    access: ["GUIDE_GUIDE"]
  },
  {
    url: "/form-panduan/Detail",
    access: ["GUIDE_GUIDE"]
  },
  {
    url: "/form-panduan/Edit",
    access: ["GUIDE_GUIDE"]
  },
  {
    url: "/daftar-saran",
    access: ["SUGGESTION_SUGGESTION"]
  },
  // konten dan materi belajar
  {
    url: "/daftar-konten",
    access: ["MASTER-DATA_CONTENT"]
  },
  {
    url: "/form-konten/Detail",
    access: ["MASTER-DATA_CONTENT"]
  },
  {
    url: "/form-konten/Tambah",
    access: ["MASTER-DATA_CONTENT"]
  },
  {
    url: "/form-konten/Edit",
    access: ["MASTER-DATA_CONTENT"]
  },
  // pengaturan
  {
    url: "/daftar-role",
    access: ["ROLE_ROLE"]
  },
  {
    url: "/detail",
    access: ["ROLE_ROLE"]
  },
  {
    url: "/form-role",
    access: ["USER_ROLE"]
  },
  {
    url: "/edit",
    access: ["USER_ROLE"]
  },
  {
    url: "/daftar-resource",
    access: ["RESOURCE_RESOURCE"]
  },
  {
    url: "/form-resource",
    access: ["RESOURCE_RESOURCE"]
  },
  {
    url: "/edit",
    access: ["RESOURCE_RESOURCE"]
  },
  {
    url: "/detail",
    access: ["RESOURCE_RESOURCE"]
  },
  {
    url: "/daftar-pengguna",
    access: ["USER_WEB"]
  },
  {
    url: "/form-pengguna",
    access: ["USER_WEB"]
  },
  {
    url: "/edit",
    access: ["USER_USER"]
  },
  {
    url: "/detail",
    access: ["USER_WEB"]
  },
  {
    url: "/daftar-fitur",
    access: ["MODULE_MODULE"]
  },
  {
    url: "/form-fitur",
    access: ["MODULE_MODULE"]
  },
  {
    url: "/detail",
    access: ["MODULE_MODULE"]
  },
  {
    url: "/set-Set-or-copy-progress",
    access: ["MATERI_MATERI"]
  },

]

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("accessToken");
  const view = nav.map(x => x.url).indexOf(window.location.pathname.replace(process.env.PUBLIC_URL, ''));
  const fullPath = window.location.pathname.replace(process.env.PUBLIC_URL, '');

  const pathSegments = fullPath.split('/').filter(Boolean);
  const lastTwoSegments = `/${pathSegments.slice(-2).join('/')}`;
  const ceking = `/${pathSegments.slice(-1).join('/')}`;

  if (view !== -1) {
    const accessFromStorage = nav[view].access.map(
      (key) => JSON.parse(localStorage.getItem(key)) || []
    );
    console.log("accessFromStorage", accessFromStorage);
    

    const hasViewAccess = accessFromStorage.some((actions) =>
      actions.includes("VIEW")
    );

    if (!hasViewAccess) {
      console.log("tidak bisa view");
      // return <Navigate to="/page-error-403" replace />;
    }
  } else if (ceking == "/Edit" || ceking == "/Ubah") {
    const lastTwoView = nav.map(x => x.url).indexOf(lastTwoSegments);
    if (lastTwoView !== -1) {
      const accessFromStorage = nav[lastTwoView].access.map(
        (key) => JSON.parse(localStorage.getItem(key)) || []
      );

      const hasUpdateAccess = accessFromStorage.some((actions) =>
        actions.includes("UPDATE")
      );

      if (!hasUpdateAccess) {
        console.log("tidak bisa update");
        return <Navigate to="/page-error-403" replace />;
      }
    }
  } else if (ceking == "/Tambah" || ceking == "/Kirim") {
    const lastTwoView = nav.map(x => x.url).indexOf(lastTwoSegments);
    if (lastTwoView !== -1) {
      const accessFromStorage = nav[lastTwoView].access.map(
        (key) => JSON.parse(localStorage.getItem(key)) || []
      );

      const hasCreateAccess = accessFromStorage.some((actions) =>
        actions.includes("CREATE")
      );

      if (!hasCreateAccess) {
        console.log("tidak bisa tambah");
        return <Navigate to="/page-error-403" replace />;
      }
    }
  } else if (ceking == "/Detail" || ceking == "/Alokasi") {
    const lastTwoView = nav.map(x => x.url).indexOf(lastTwoSegments);
    if (lastTwoView !== -1) {
      const accessFromStorage = nav[lastTwoView].access.map(
        (key) => JSON.parse(localStorage.getItem(key)) || []
      );

      const hasUpdateAccess = accessFromStorage.some((actions) =>
        actions.includes("VIEW")
      );

      if (!hasUpdateAccess) {
        console.log("tidak bisa detail");
        return <Navigate to="/page-error-403" replace />;
      }
    }
  }

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App(props) {
  let routeblog = (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/page-register' element={<SignUp />} />
      <Route path='/page-forgot-password' element={<ForgotPassword />} />
    </Routes>
  );
  return (
    <>
      <Suspense fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }>
        <ProtectedRoute>
          <Index />
        </ProtectedRoute>
        {routeblog}
      </Suspense>
    </>
  );
};


// function App(props) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   useEffect(() => {
//     checkAutoLogin(dispatch, navigate);
//   }, []);



//   let routeblog = (

//     <Routes>
//       <Route path='/login' element={<Login />} />
//       <Route path='/page-register' element={<SignUp />} />
//       <Route path='/page-forgot-password' element={<ForgotPassword />} />
//     </Routes>
//   );
//   if (props.isAuthenticated) {
//     return (
//       <>
//         <Suspense fallback={
//           <div id="preloader">
//             <div className="sk-three-bounce">
//               <div className="sk-child sk-bounce1"></div>
//               <div className="sk-child sk-bounce2"></div>
//               <div className="sk-child sk-bounce3"></div>
//             </div>
//           </div>
//         }
//         >
//           <Index />
//         </Suspense>
//       </>
//     );

//   } else {
//     return (
//       <div className="vh-100">
//         <Suspense fallback={
//           <div id="preloader">
//             <div className="sk-three-bounce">
//               <div className="sk-child sk-bounce1"></div>
//               <div className="sk-child sk-bounce2"></div>
//               <div className="sk-child sk-bounce3"></div>
//             </div>
//           </div>
//         }
//         >
//           {routeblog}
//         </Suspense>
//       </div>
//     );
//   }
// };


const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

//export default connect((mapStateToProps)(App)); 
export default withRouter(connect(mapStateToProps)(App)); 