import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { DELETE, GET, PATCH } from '../../../services/AxiosService';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";

const DaftarLaporan = () => {
  const navigate = useNavigate();
  const [periode, setPeriode] = useState([]);
  const [selectedPeriode, setSelectedPeriode] = useState('');
  const [selectedPeriodName, setSelectedPeriodName] = useState("");
  const [data, setData] = useState([]);
  const [dataBulan, setDataBulan] = useState([]);
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  // period Aktif
  const [periodeAktif, setPeriodeAktif] = useState([]);
  const [selectedPeriodeAktif, setSelectedPeriodeAktif] = useState('');
  const [selectedPeriodNameAktif, setSelectedPeriodNameAktif] = useState("");

  const fetchData = async (page, perPage, sortField, sortOrder, search, selectedPeriode,selectedPeriodeAktif) => {
    const period = await GET(`class/periode`);
    const periodAktif = await  GET(`/master-data/period`);
    setPeriode(period.data)
    setPeriodeAktif(periodAktif.data);

    if (Array.isArray(periodAktif.data) && periodAktif.data.length > 0) {
      const defaultPeriod = periodAktif.data.find((item) => item.status === 1);
      // if (defaultPeriod) {
      //   setSelectedPeriodeAktif(defaultPeriod.id);
      //   setSelectedPeriodNameAktif(defaultPeriod.name);
      // }

      const params = {
        page,
        size: perPage,
        sort: sortField,
        orderby: sortOrder,
        search,
      };
      const res = await GET(`class/students/${selectedPeriode === "" ? defaultPeriod.id : selectedPeriode}`, params);
      console.log('ini respon class id',res)
      setData(res?.data);
      setDataBulan(res?.month);
      setTotalRows(res?.meta?.amount);
    }
  };
  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedPeriode);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };
  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedPeriode]);
  // useEffect(() => {
  //   console.log('Updated active period ID and name:', selectedPeriodeAktif, selectedPeriodNameAktif);
  // }, [selectedPeriodeAktif, selectedPeriodNameAktif]);

  const baseColumns = [
    {
      name: 'Pelajar',
      selector: row => row.user_name,
      cell: row =>
        <button onClick={() => navigate(`/${row?.student_id}/form-pelajar/Detail`, { state: { from: 'Detail' } })} style={{ background: "transparent", border: "none" }}>
          <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user_name}</span>
        </button>,
    },
    {
      name: 'Kelas',
      selector: row => row.class_name,
    },
    {
      name: 'Kota / Kabupaten',
      selector: row => row.location_name,
    },
    {
      name: 'Pengajar',
      selector: row => row.teachers,
    },
  ];
  const bulanColumns = (dataMonth) => {
    return dataMonth?.map((monthData) => ({
      name: monthData.monthEntity.name,
      selector: (row) => {
        const report = row.reports.find((rep) => rep.period.id === monthData.monthEntity.id);
        return report ? report.status : 'Belum Terisi';
      },
      cell: (row) => {
        const report = row.reports.find((rep) => rep.period.id === monthData.monthEntity.id);
        const statusText = report 
          ? (report.status === 1 ? 'Disetujui' : 'Menunggu Persetujuan') 
          : 'Belum Terisi';
        const textColor = statusText === 'Belum Terisi' ? 'red' : '#0C718C';
        return (
          <div>
            <button 
              onClick={() => navigate(`/${row.id}/${monthData.monthEntity.id}/form-laporan/Detail`, { state: { ids: row.id, idPeriode: monthData.monthEntity.id } })} 
              style={{ background: "transparent", border: "none" }}
            >
              <span style={{ color: textColor, textDecoration: 'underline' }}>
                {statusText}
              </span>
            </button>
          </div>
        );
      },
    }));
  };
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const filteredBulanColumns = bulanColumns(dataBulan);
  const columns = [...baseColumns, ...filteredBulanColumns];

  const handleSelectPeriode = (e) => {
    setSelectedPeriode(e.target.getAttribute('value'));
    setSelectedPeriodName(e.target.textContent);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Laporan</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedPeriodName === "" ? periode[0]?.period_name : selectedPeriodName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Array.isArray(periodeAktif) && (periodeAktif).map((periodes) => (
                      <Dropdown.Item key={periodes.id} value={periodes.id} onClick={handleSelectPeriode}>
                        {periodes.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {/* {selectedTipeDonasi?.length > 0 ? selectedTipeDonasi : "Semua Tipe Donasi"} */}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      key="all"
                      value=""
                    // onClick={handleSelectTipeDonasi}
                    >
                      Semua Tipe Pelajar
                    </Dropdown.Item>
                    {/* {Array.isArray(tipeDonasi) && (tipeDonasi).map((donasiTipe) => (
                      <Dropdown.Item key={donasiTipe.id} value={donasiTipe.name} onClick={handleSelectTipeDonasi}>
                        {donasiTipe.name}
                      </Dropdown.Item>
                    ))} */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Form.Check inline label="Terverifikasi" name="group1" /> */}
                {/* <Form.Check inline label="Pengajar" name="group1" /> */}
              </div>

              <div className="d-flex align-self-baseline px-4">
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Laporan"
                  // value={searchTerm}
                  // onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex justify-content-end mt-4 mr-5">
              <div className="input-group search-area d-xl-inline-flex">
                <input type="text" className="form-control" placeholder="Cari Laporan"
                // value={searchTerm} onChange={handleSearchChange} 
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <Link to="#">
                      <i className="flaticon-381-search-2" />
                    </Link>
                  </span>
                </div>
              </div>
            </div> */}
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarLaporan