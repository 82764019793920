import React, { Fragment, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { GET, PATCH, POST, DELETE } from "../../../services/AxiosService";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { InputGroup, FormControl } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { HexColorPicker, HexColorInput } from "react-colorful";

const DaftarMateri = () => {
  const [selectedValue, setSelectedValue] = useState("BAB");
  const [detailData, setDetailData] = useState("");
  const [data, setData] = useState([]);
  const [type, setType] = useState([]);
  const [jenisTampilan, setJenisTampilan] = useState([]);
  const [tipeSimulasi, setTipeSimulasi] = useState([]);
  const [tipeAkhir, setTipeAkhir] = useState([]);

  const [jenisVolume, setJenisVolume] = useState([]);
  const [order, setOrder] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [tambahMateri, setTambahMateri] = useState("");
  const [tambahAkhirKuis, setTambahAkhirKuis] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = (row) => {
    const parentId = row?.row?.id;
    setFormData({ parent: parentId });
    setOrder(row?.row?.child);
    setVoicePreviewUrl(null);

    setShow(true);

    if (row.from === "level 1") {
      setFormData({ type: "Volume" });
      setTambahMateri(row.from);
      setOrder(data);
    }
    if (row.from === "level 2") {
      setTambahMateri(row.from);
    }
    if (row.from === "level 3") {
      setTambahMateri(row.from);
    }
    if (row.from === "level 3 Kuis") {
      setFormData({ parent: parentId, random: 1 });
      setTambahMateri(row.from);
      setTambahAkhirKuis(row.row.type);
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    page: "",
    parent: "",
    order: "",
    content: "",
    sound: "",
    color: "",
    color: "",
    // new
    volume_type: "",
    talkback: "",
    display_type: "",
    information: "",
    line: "",
  });
  const [formDataRandom, setFormDataRandom] = useState({});
  const [showNested, setShowNested] = useState(false);
  const [validated, setValidated] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  // file suara
  const [fileVoice, setFileVoice] = useState([]);
  const [voicePreviewUrl, setVoicePreviewUrl] = useState(null);

  const [file, setFile] = useState([]);
  const audioRef = useRef(null);

  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalId, setEditModalId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    name: "",
    type: "",
    page: "",
    // parent: "",
    // order: "",
    content: "",
    sound: "",
    color: "",

    // new
    talkback: "",
    display_type: "",
    line: "",
    final_type: "",
    loop_to_page: "",
    information: "",
    volume_type: "",
  });
  const [editLatihanModalShow, setEditLatihanModalShow] = useState(false);
  const [editLatihanModalId, setEditLatihanModalId] = useState(null);
  const [editLatihanFormData, setEditLatihanFormData] = useState({
    name: "",
    sound: "",
    content: "",
    talkback: "",
    meaning: "",
    image: "",
  });

  const [editSimulasiModalShow, setEditSimulasiModalShow] = useState(false);

  const [dataLatihan, setDataLatihan] = useState([]);
  const [dataLatihanRandom, setDataLatihanRandom] = useState([]);
  const [formDataLatihan, setFormDataLatihan] = useState({
    name: "",
    order: "",
    sound: "",
    content: "",
    talkback: "",
    meaning: "",
  });
  const [showLatihan, setShowLatihan] = useState(false);
  const [showLatihanRandom, setShowLatihanRandom] = useState(false);
  const [showSimulasi, setShowSimulasi] = useState(false);
  const [showTest, setShowTest] = useState(false);

  const [showButtonDelete, setShowButtonDelete] = useState(false);
  const [isDeleteSound, setIsDeleteSound] = useState(false);
  const [image, setImage] = useState(null);
  const [isNewFileSelected, setIsNewFileSelected] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const fileSizeLimit = 10 * 1024 * 1024;
  // validate
  const permissionsMateri = JSON.parse(localStorage.getItem('MATERI_LATIHAN') || '[]');
  const canModifyMateri = permissionsMateri.some(permissionsMateri => ['CREATE', 'UPDATE', 'DELETE', 'LOCK', 'VIEW', 'APPROVE'].includes(permissionsMateri));
  const permissionsMateriLatihan = JSON.parse(localStorage.getItem('MATERI_LATIHAN') || '[]');
  const canModifyMateriLatihan = permissionsMateriLatihan.some(permissionsMateriLatihan => ['CREATE', 'UPDATE', 'DELETE', 'LOCK', 'VIEW', 'APPROVE'].includes(permissionsMateriLatihan));

  const handleShowLatihan = () => {
    // const parentId = row.id;
    // setFormDataLatihan({ parent: parentId })
    if (detailData.row.random === 1) {
      setShowLatihanRandom(true);
    } else {
      setOrder(dataLatihan);
      setVoicePreviewUrl(null);

      setShowLatihan(true);
    }
  };
  const handleCloseLatihan = () => {
    setShowLatihan(false);
    setVoicePreviewUrl(null);
    setShowNested(false);
    setImagePreviewUrl(null);
  };

  const handleShowSimulasi = () => {
    // const parentId = row.id;
    // setFormDataLatihan({ parent: parentId })
    // if (detailData.row.random === 1) {
    //   setShowLatihanRandom(true);
    // } else {
    //   setOrder(dataLatihan);
    //   setVoicePreviewUrl(null);

    //   setShowSimulasi(true);
    // }
    setShowSimulasi(true);
    setOrder(dataLatihan);
    setVoicePreviewUrl(null);
  };
  const handleCloseSimulasi = () => {
    setShowSimulasi(false);
    setVoicePreviewUrl(null);
    setFormDataLatihan({
      name: "",
      order: "",
      sound: "",
      content: "",
      talkback: "",
      meaning: "",
    });
  };

  const handleShowTest = () => {
    setOrder(dataLatihan);
    setShowTest(true);
  };
  const handleCloseTest = () => {
    setShowTest(false);
  };

  const handleCloseLatihanRandom = () => {
    setShowLatihanRandom(false);
    setFormDataRandom({});
  };

  const handleSelect = (e) => {
    setSelectedValue(e.target.textContent);
  };

  const fetchData = async () => {
    const params = {
      search: searchTerm,
      sort: "order",
    };
    const res = await GET(`materi`, params);
    setData(res?.data);
  };
  const fetchDataType = async () => {
    const res = await GET(`master-data/type?type=materi`);
    setType(res?.data);
  };
  const fetchDataTypeLevel1 = async () => {
    const res = await GET(`master-data/type?type=Materi Level 1`);
    setType(res?.data);
  };
  const fetchDataJenisVolume = async () => {
    const res = await GET(`master-data/type?type=Jenis Volume`);
    setJenisVolume(res?.data);
  };
  const fetchDataTypeLevel2 = async () => {
    const res = await GET(`master-data/type?type=Materi Level 2`);
    setType(res?.data);
  };
  const fetchDataTypeLevel3 = async () => {
    const res = await GET(`master-data/type?type=Materi Level 3`);
    setType(res?.data);
  };
  const fetchDataTypeLevel3Kuis = async () => {
    const res = await GET(`master-data/type?type=Materi Level 3 Kuis`);
    setType(res?.data);
  };
  const fetchDataJenisTampian = async () => {
    const res = await GET(`master-data/type?type=Jenis Tampilan`);
    setJenisTampilan(res?.data);
  };
  const fetchDataTypeSimulasi = async () => {
    const res = await GET(`master-data/type?type=Tipe Latihan`);
    setTipeSimulasi(res?.data);
  };
  const fetchDataTypeAkhir = async () => {
    const res = await GET(`master-data/type?type=Akhir`);
    setTipeAkhir(res?.data);
  };
  const fetchDataTypeAkhirkuis = async () => {
    const res = await GET(`master-data/type?type=Tipe Akhir Kuis`);
    setTipeAkhir(res?.data);
  };

  useEffect(() => {
    fetchData();
    // fetchDataType();
  }, []);

  useEffect(() => {
    if (tambahMateri === "level 1") {
      fetchDataTypeLevel1();
      fetchDataJenisVolume();
    }
    if (tambahMateri === "level 2") {
      fetchDataTypeLevel2();
      fetchDataJenisTampian();
      fetchDataTypeSimulasi();
      fetchDataTypeAkhir();
    }
    if (tambahMateri === "level 3") {
      fetchDataTypeLevel3();
      fetchDataJenisTampian();
      fetchDataTypeSimulasi();
      fetchDataTypeAkhir();
    }
    if (tambahMateri === "level 3 Kuis") {
      fetchDataTypeLevel3Kuis();
      fetchDataTypeAkhirkuis();
      fetchDataJenisTampian();
    }
  }, [tambahMateri]);

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  useEffect(() => {
    if (dataLatihan?.length > 0) {
      fetchFile();
    }
  }, [dataLatihan]);

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const boxStyle = (index) => ({
    width: "100%",
    cursor: "pointer",
    // backgroundColor: hoveredRowIndex === index ? 'lightblue' : 'rgb(0, 191, 255)',
    // color: hoveredRowIndex === index ? 'red' : '',
  });

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const [showColor, setShowColor] = useState(false);
  const handleColorClick = (row, index) => {
    const cleanColor = row?.color?.startsWith("#") ? row?.color?.slice(1) : row?.color;
    setEditModalId(row.id);
    if (showColor === index) {
      setShowColor(null);
    } else {
      setShowColor(index);
      setEditFormData({
        color: cleanColor,
      });
    }
  };
  const handleChangeColor = (newColor) => {
    setEditFormData((prevData) => ({
      ...prevData,
      color: newColor,
    }));
  };

  const columns = [
    {
      selector: (row) => row.name,
      field: "name",
      cell: (row, index) => {
        const isDeleteDisabled = row.child?.length > 0 || row.childs?.length > 0;
        const maxOrder = Math.max(...data.map((item) => item.order));
        return (
          <div className="d-flex justify-content-between" style={boxStyle(index)} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
            <div className="d-flex">
              <div>
                {row?.color?.length > 0 && (
                  <>
                    <div className="d-flex mr-2">
                      <button className="rounded-circle" onClick={() => handleColorClick(row, index)} style={{ background: `${row?.color}`, height: "25px", width: "25px", border: "none" }}></button>
                      {showColor === index && (
                        <div className="card" style={{ padding: "5px", marginBottom: "0", borderRadius: "10px", margin: "5px" }}>
                          <HexColorPicker color={editFormData.color} onChange={handleChangeColor} style={{ width: "150px", height: "140px" }} />
                          <div className="d-flex mt-1 mb-1 justify-content-md-end">
                            <HexColorInput className="text-center" color={editFormData.color} style={{ maxWidth: "60px" }} onChange={handleChangeColor} />
                            <Link className="btn btn-primary shadow btn-xs sharp mx-1" onClick={handleEditSubmit} title="Edit">
                              <i className="fa fa-pencil"></i>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div>{row.name}</div>
            </div>
            <div>
              {hoveredRowIndex === index && (
                <>
                  {permissionsMateri.includes('CREATE') && (
                    <Link className="btn btn-info shadow btn-xs sharp" title="Tambah" onClick={() => handleShow({ row, from: "level 2" })}>
                      <i className="fa fa-plus"></i>
                    </Link>
                  )}
                  {permissionsMateri.includes('UPDATE') && (
                    <Link className="btn btn-primary shadow btn-xs sharp mx-1" onClick={() => handleEditClick({ row, from: "level 1" })} title="Edit">
                      <i className="fa fa-pencil"></i>
                    </Link>
                  )}
                  {permissionsMateri.includes('DELETE') && (
                    <Link className={`btn btn-danger shadow btn-xs sharp ${isDeleteDisabled ? "disabled" : ""}`} title="Delete" onClick={() => handleDeleteClick(row.id)}>
                      <i className="fa fa-trash"></i>
                    </Link>
                  )}
                  {permissionsMateri.includes('UPDATE') && (
                    <>
                      <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.order === 1 ? "disabled" : ""}`} title="up" onClick={() => handleUpClick(row.id)}>
                        <i className="fa fa-arrow-up"></i>
                      </Link>
                      <Link className={`btn btn-success shadow btn-xs sharp ${row.order === maxOrder ? "disabled" : ""}`} title="down" onClick={() => handleDownClick(row.id)}>
                        <i className="fa fa-arrow-down"></i>
                      </Link>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
    table: {
      style: {
        // minHeight: selectedValue ? "320px" : "unset",
        // marginBottom: "0",
      },
    },
  };
  const customExpandStyles = {
    headRow: {
      style: {
        display: "none",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
        "&:disabled": {
          // contoh penggunaan &:disabled untuk styling yang disabled
          backgroundColor: "red", // warna latar belakang yang berbeda untuk disabled
          color: "blue",
        },
      },
    },
    table: {
      style: {
        marginBottom: "0",
      },
    },
  };
  // const conditionalRowStyles = [
  //   {
  //     when: row => row.disabled,
  //     style: {
  //       backgroundColor: 'blue',
  //       color: 'red'
  //     }
  //   }
  // ]

  const ExpandedComponent = ({ data, depth = 0 }) => {
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    const boxStyle = (index) => ({
      width: "100%",
      cursor: "pointer",
    });

    const handleMouseEnter = (index) => {
      setHoveredRowIndex(index);
    };

    const handleMouseLeave = () => {
      setHoveredRowIndex(null);
    };

    const handleRowExpandToggled = (isExpanded, row) => {
      setExpandedRows((prevState) => ({
        ...prevState,
        [row.id]: isExpanded,
      }));
    };

    const childColumnsWithWidth = [
      {
        selector: (row) => row.name,
        selector: (row) => row.page,
        selector: (row) => row.type,
        cell: (row, index) => {
          const isDeleteDisabled = row.child?.length > 0 || row.childs?.length > 0;
          const maxOrder = Math.max(...data.child?.map((item) => item.order));
          return (
            <div className="d-flex justify-content-between" style={boxStyle(index)} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
              {row.type === "Latihan" || row.type === "Catatan" || row.type === "Deskripsi" || row.type === "Simulasi" || row.type === "Test" || row.type === "Akhir" ? (
                <div className="d-flex">
                  <div>
                    <Button className="p-1" title="Detail" style={{ background: "transparent", border: "none", color: "#0C718C" }} onClick={() => handleDetailClick(data, row)}>
                      {row.name}
                    </Button>
                    {/* ({row.type}, Halaman {row.page}) */}
                    {row.type === "Catatan" ? (
                      <Badge className="mx-1" as="a" variant="outline-success badge-xs badge-rounded">
                        {row.type}
                      </Badge>
                    ) : row.type === "Deskripsi" ? (
                      <Badge className="mx-1" as="a" variant="outline-warning badge-xs badge-rounded">
                        {row.type}
                      </Badge>
                    ) : row.type === "Latihan" ? (
                      <Badge className="mx-1" as="a" variant="outline-info badge-xs badge-rounded">
                        {row.type}
                      </Badge>
                    ) : row.type === "Simulasi" ? (
                      <Badge className="mx-1" as="a" variant="outline-danger badge-xs badge-rounded">
                        {row.type}
                      </Badge>
                    ) : row.type === "Test" ? (
                      <Badge className="mx-1" as="a" variant="outline-success badge-xs badge-rounded">
                        {row.type}
                      </Badge>
                    ) : row.type === "Akhir" ? (
                      <Badge className="mx-1" as="a" variant="outline-success badge-xs badge-rounded">
                        {row.type}
                      </Badge>
                    ) : null}
                    {row.page && (
                      <span className="mx-1" style={{ color: "#AEAEAE" }}>
                        Halaman {row.page}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {row.name}
                  {(row.type === "Topik" || row.type === "BAB" || row.type === "QUIZ" || row.type === "Kuis" || row.type === "Kecepatan" || row.type === "Donasi") && (
                    <Badge className="mx-2" as="a" variant="outline-primary badge-xs badge-rounded">
                      {row.type}
                    </Badge>
                  )}
                  {row.page && (
                    <span className="" style={{ color: "#AEAEAE" }}>
                      Halaman {row.page}
                    </span>
                  )}
                </div>
              )}

              <div className="d-flex">
                {hoveredRowIndex === index && (
                  <>
                    {permissionsMateri.includes('CREATE') && (
                      <>
                        {(row.type === "BAB" || row.type === "Sub Bab" || row.type === "QUIZ" || row.type === "Topik") && (
                          <Link className="btn btn-info shadow btn-xs sharp" title="Detail" onClick={() => handleShow({ row, from: "level 3" })}>
                            <i className="fa fa-plus"></i>
                          </Link>
                        )}
                        {row.type === "Kuis" && (
                          <Link className="btn btn-info shadow btn-xs sharp" title="Detail" onClick={() => handleShow({ row, from: "level 3 Kuis" })}>
                            <i className="fa fa-plus"></i>
                          </Link>
                        )}
                      </>
                    )}
                    {permissionsMateri.includes('UPDATE') && (
                      <>
                        {row.type !== "Kecepatan" && (
                          <Link className="btn btn-primary shadow btn-xs sharp mx-1" onClick={() => handleEditClick({ row, from: "level 2", data })} title="Edit">
                            <i className="fa fa-pencil"></i>
                          </Link>
                        )}
                      </>
                    )}
                    {permissionsMateri.includes('DELETE') && (
                      <Link className={`btn btn-danger shadow btn-xs sharp ${isDeleteDisabled ? "disabled" : ""}`} title="delete" onClick={() => handleDeleteClick(row.id)}>
                        <i className="fa fa-trash"></i>
                      </Link>
                    )}
                    {permissionsMateri.includes('UPDATE') && (
                      <>
                        <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.order === 1 ? "disabled" : ""}`} title="up" onClick={() => handleUpClick(row.id)}>
                          <i className="fa fa-arrow-up"></i>
                        </Link>
                        <Link className={`btn btn-success shadow btn-xs sharp ${row.order === maxOrder ? "disabled" : ""}`} title="down" onClick={() => handleDownClick(row.id)}>
                          <i className="fa fa-arrow-down"></i>
                        </Link>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        },
      },
    ];

    const lastColumns = [
      {
        selector: (row) => row.name,
        selector: (row) => row.page,
        selector: (row) => row.type,
        // width: "26%",
        cell: (row) => {
          const maxOrder = Math.max(...data.childs?.map((item) => item.order));
          return (
            <div className="d-flex justify-content-between" style={{ width: "100%" }}>
              <Button className="p-1 text-info" title="Detail" style={{ background: "transparent", border: "none" }} onClick={() => handleDetailClick(data, row)}>
                <span>{row.name}</span> ({row.type}, Halaman{row.page})
              </Button>
              <div className="d-flex">
                <Link className="btn btn-primary shadow btn-xs sharp mx-1" onClick={() => handleEditClick(row)} title="Edit">
                  <i className="fa fa-pencil"></i>
                </Link>

                <Link className="btn btn-danger shadow btn-xs sharp" title="delete" onClick={() => handleDeleteClick(row.id)}>
                  <i className="fa fa-trash"></i>
                </Link>

                <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.order === 1 ? "disabled" : ""}`} title="up" onClick={() => handleUpClick(row.id)}>
                  <i className="fa fa-arrow-up"></i>
                </Link>
                <Link className={`btn btn-success shadow btn-xs sharp ${row.order === maxOrder ? "disabled" : ""}`} title="down" onClick={() => handleDownClick(row.id)}>
                  <i className="fa fa-arrow-down"></i>
                </Link>
              </div>
            </div>
          );
        },
      },
    ];

    const expandDisable = data?.child?.map((item) => {
      let disabled = false;
      if (
        item.type === "Latihan" ||
        item.type === "Catatan" ||
        item.type === "Deskripsi" ||
        item.type === "Kecepatan" ||
        item.type === "Donasi" ||
        item.type === "Simulasi" ||
        item.type === "Test" ||
        item.type === "Akhir" ||
        item.type === "Tutorial"
      ) {
        disabled = true;
      }
      const expanded = !!expandedRows[item.id];
      return { ...item, disabled, expanded };
    });

    return (
      <div style={{ paddingLeft: "30px" }}>
        {data.child && data.child.length > 0 ? (
          <DataTable
            columns={childColumnsWithWidth}
            data={expandDisable}
            customStyles={customExpandStyles}
            noHeader
            expandableRows
            expandableRowsComponent={(props) => <ExpandedComponent {...props} depth={depth + 1} />}
            expandableRowDisabled={(row) => row.disabled}
            expandableRowExpanded={(row) => row.expanded}
            onRowExpandToggled={(isExpanded, row) => {
              handleRowExpandToggled(isExpanded, row);
            }}
          />
        ) : data.childs && data.childs.length > 0 ? (
          <div style={{ paddingLeft: "15px" }}>
            <DataTable columns={lastColumns} data={data.childs} customStyles={customExpandStyles} noHeader />
          </div>
        ) : (
          <p>Tidak ada data.</p>
        )}
      </div>
    );
  };

  const handleDetailClick = async (data, row) => {
    if (row.sound?.length > 0) {
      const res = await GET(`/files/${row.sound}/download`, {}, { responseType: "arraybuffer" });
      const blob = new Blob([res], { type: "audio/mpeg" });
      const reader = new FileReader();

      reader.onload = () => {
        const base64Audio = reader.result;
        setVoicePreviewUrl(base64Audio);
      };

      reader.readAsDataURL(blob);
    }

    setDetailData({
      row,
      name: row.name,
      nameSub: data.name,
      type: row.type,
      page: row.page,
    });

    setEditFormData({
      name: row.name,
      type: row.type,
      page: row.page,
      parent: row.parent,
      // order: row.order,
      content: row.content,
      sound: row.sound,
    });

    setEditModalId(row.id);

    if (row.type === "Latihan") {
      if (row.random === 1) {
        setDataRandom(row.content);
      } else {
        setFormDataLatihan({
          materi_id: row.id,
        });
        const res = await GET(`/materi-latihan/${row.id}/materi`);
        setDataLatihan(res?.data);
      }
    }

    if (row.type === "Test") {
      setDataRandom(row.content);
    }

    if (row.type === "Simulasi") {
      setFormDataLatihan({
        materi_id: row.id,
      });
      const res = await GET(`/materi-latihan/${row.id}/materi`);
      setDataLatihan(res?.data);
    }
  };

  const handleClose = () => {
    setShow(false);

    setFormData({
      name: "",
      type: "",
      page: "",
      // parent: "",
      order: "",
      content: "",
      sound: "",
    });
    setTambahMateri("");
    setOrder([]);
    setShowNested(false);
    setVoicePreviewUrl(null);
    setImagePreviewUrl(null);
  };
  const handleChangeOrder = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "palingAtas") {
      setFormData((prevData) => ({
        ...prevData,
        order: "Paling Atas",
      }));
    } else if (selectedValue === "palingBawah") {
      setFormData((prevData) => ({
        ...prevData,
        order: "Paling Bawah",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        order: selectedValue,
      }));
    }

    setShowNested(selectedValue !== "palingAtas" && selectedValue !== "palingBawah" && selectedValue !== "");

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validasi form di sini
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      name: formData.name,
      type: formData.type,
      page: formData.page,
      parent: formData.parent,
      // order: formData.order === 'setelahMateri' ? 2 : formData.order,
      position: formData.order == "Paling Atas" ? formData.order : formData.order == "Paling Bawah" ? formData.order : "Setelah Materi",
      materi_id: formData.order == "Paling Atas" || formData.order == "Paling Bawah" ? "" : formData.order,
      sound: formData.sound,
      color: "",
      content: formData.content,
      // status: 0,
      correct: formData.correct,
      random: formData.type == "Akhir" || formData.type == "Tutorial" ? null : formData.random ? 1 : 0,
      hint: formData.hint ? 1 : 0,
      donation: formData.donation ? 1 : 0,
      first_time_only: formData.first_time_only ? 1 : 0,
      image: formData.image,
      braille_interaction: formData.braille_interaction ? 1 : 0,
      // new
      volume_type: formData.volume_type,
      talkback: formData.talkback,
      display_type: formData.display_type,
      final_type: formData.final_type,
      loop_to_page: formData.loop_to_page,
      information: formData.information,
      line: formData.line,

      volume: formData.type === "Volume" ? true : false,
      child_volume: formData.type === "Topik" || formData.type === "Deskripsi" || formData.type === "Kuis" ? true : false,
      child_topik: formData.type === "Tutorial" || formData.type === "Latihan" || formData.type === "Simulasi" || formData.type === "Akhir" || formData.type === "Kecepatan" ? true : false,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (file.length !== 0) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "latihan");
      formDataObject.append("file", file);

      const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

      const newVoiceFileId = fileUploadResponse?.file.id;
      requestBody.sound = newVoiceFileId;
    }

    if (image) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "materi");
      formDataObject.append("file", image);

      const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

      requestBody.image = fileUploadResponse?.file.id;
    }

    const res = await POST(`/materi`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat materi!",
      }).then(() => {
        Swal.close();
        setFormData({
          name: "",
          type: "",
          page: "",
          parent: "",
          order: "",
        });
        fetchData();
        setShow(false);
        handleClose();
        setFile([]);
        setImage(null);
        setImagePreviewUrl(null);
        setIsNewFileSelected(false);
      });
    }
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/materi/${id}/delete`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus materi!",
        }).then(() => {
          Swal.close();
          fetchData();
        });
      }
    }
  };

  const handleEditClick = async (row) => {
    setTambahMateri(row.from);
    setEditFormData({
      name: row?.row.name,
      type: row?.row.type,
      page: row?.row.page,
      parent: row?.row.parent,
      // order: row?.row.order,
      content: row?.row.content,
      sound: row?.row.sound,
      correct: row?.row.correct,
      random: row?.row.random,
      hint: row?.row.hint,
      donation: row?.row.donation,
      first_time_only: row?.row.first_time_only,
      image: row?.row.image,
      braille_interaction: row?.row.braille_interaction,
      // new
      talkback: row?.row?.talkback,
      display_type: row?.row?.display_type,
      line: row?.row?.line,
      final_type: row?.row?.final_type,
      loop_to_page: row?.row?.loop_to_page,
      information: row?.row?.information,
      volume_type: row?.row?.volume_type,
    });

    if (row?.row?.type === "Akhir") {
      const ulangiHalaman = row?.data?.child?.filter((child) => child.id !== row.row.id) || [];
      setOrder(ulangiHalaman);
    }

    setEditModalShow(true);
    setEditModalId(row?.row.id);
    if (row?.row.sound?.length > 0) {
      const res = await GET(`/files/${row?.row.sound}/download`, {}, { responseType: "arraybuffer" });
      const blob = new Blob([res], { type: "audio/mpeg" });
      const reader = new FileReader();

      reader.onload = () => {
        const base64Audio = reader.result;
        setVoicePreviewUrl(base64Audio);
      };

      reader.readAsDataURL(blob);
    }
  };
  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleEditModalClose = () => {
    setEditModalShow(false);
    setVoicePreviewUrl(null);
    setImagePreviewUrl(null);
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      type,
      page,
      content,
      sound,
      color,
      correct,
      random,
      hint,
      donation,
      first_time_only,
      // image,
      braille_interaction,
      // new
      talkback,
      display_type,
      line,
      final_type,
      loop_to_page,
      information,
    } = editFormData;

    const requestBody = {
      name,
      type,
      page,
      content,
      sound,
      color,
      correct,
      random: random ? 1 : 0,
      hint: hint ? 1 : 0,
      donation: donation ? 1 : 0,
      first_time_only: first_time_only ? 1 : 0,
      image: editFormData.image,
      braille_interaction: braille_interaction ? 1 : 0,
      // new
      talkback,
      display_type,
      line,
      final_type,
      loop_to_page,
      information,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (file.length !== 0) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "latihan");
      formDataObject.append("file", file);

      const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

      const newVoiceFileId = fileUploadResponse?.file.id;
      requestBody.sound = newVoiceFileId;
    }

    if (image) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "latihan");
      formDataObject.append("file", image);

      const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

      requestBody.image = fileUploadResponse?.file.id;
    }

    if (showButtonDelete) {
      requestBody.image = null;
    }

    const res = await PATCH(`/materi/${editModalId}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Materi berhasil diubah!",
      }).then(async () => {
        Swal.close();
        fetchData();
        setEditModalShow(false);
        setFile([]);
        setImage(null);
        setIsNewFileSelected(false);
        setShowColor(false);

        if (res.data.sound?.length > 0) {
          const response = await GET(`/files/${res.data.sound}/download`, {}, { responseType: "arraybuffer" });
          const blob = new Blob([response], { type: "audio/mpeg" });
          const reader = new FileReader();

          reader.onload = () => {
            const base64Audio = reader.result;
            setVoicePreviewUrl(base64Audio);
          };

          reader.readAsDataURL(blob);
        }
      });
    }
  };

  const handleUpClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/materi/${id}/move-up`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke atas!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };
  const handleDownClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/materi/${id}/move-down`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke bawah!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const columnsLatihan = [
    {
      name: "Judul",
      selector: (row) => row.name,
      // width: "180px",
      // cell: (row) => (
      //   <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user?.name}</span>
      // ),
    },
    {
      name: "Jawaban",
      selector: (row) => row.answer,
    },
    {
      name: "Text to Speech",
      selector: (row) => row.talkback,
      // width: "180px",
      // cell: (row) => (
      //   <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user?.name}</span>
      // ),
    },
    {
      name: "Suara",
      selector: (row) => row.sound,
      width: "160px",
      cell: (row) => (
        <div className="">
          {!fileVoice[row.id] ? (
            "file suara tidak ada"
          ) : (
            <audio controls style={{ maxWidth: "150px" }}>
              <source src={fileVoice[row.id]} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
        // <div style={{ width: "100%", textAlign: "center" }}>
        //   {fileProfile[row.picture_file_id] && (
        //     <img
        //       src={fileProfile[row.picture_file_id]}
        //       // alt={row.description}
        //       style={{ maxWidth: "100px", maxHeight: "100px" }} // Sesuaikan ukuran gambar sesuai kebutuhan
        //     />
        //   )}
        // </div>
      ),
    },
    ...(canModifyMateriLatihan
      ? [{
        name: "Aksi",
        width: "30%",
        cell: (row) => {
          const maxOrder = Math.max(...dataLatihan?.map((item) => item.order));
          return (
            <>
              <div className="d-flex">
                {permissionsMateriLatihan.includes('UPDATE') && (
                  <Link className="btn btn-primary shadow btn-xs sharp mx-1" onClick={() => handleEditLatihanClick(row)} title="Edit">
                    <i className="fa fa-pencil"></i>
                  </Link>
                )}

                {permissionsMateriLatihan.includes('DELETE') && (
                  <Link className="btn btn-danger shadow btn-xs sharp" title="delete" onClick={() => handleDeleteLatihanClick(row.id)}>
                    <i className="fa fa-trash"></i>
                  </Link>
                )}

                {permissionsMateriLatihan.includes('UPDATE') && (
                  <>
                    <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.order === 1 ? "disabled" : ""}`} title="up" onClick={() => handleUpClickLatihan(row.id)}>
                      <i className="fa fa-arrow-up"></i>
                    </Link>
                    <Link className={`btn btn-success shadow btn-xs sharp ${row.order === maxOrder ? "disabled" : ""}`} title="down" onClick={() => handleDownClickLatihan(row.id)}>
                      <i className="fa fa-arrow-down"></i>
                    </Link>
                  </>
                )}
              </div>
            </>
          );
        },
      }]
      : [])
  ];

  const columnsLatihanRandom = [
    {
      name: "Jumlah Soal",
      selector: (row) => row.numberOfQuestions,
    },
    {
      name: "Judul Awal / Prefix Soal",
      selector: (row) => row.title,
    },
    {
      name: "Soal (pisahkan dengan spasi)",
      selector: (row) => row.question,
    },
    {
      name: "Jawaban (pisahkan dengan spasi)",
      selector: (row) => row.answer,
    },
    ...(canModifyMateriLatihan
      ? [{
        name: "Aksi",
        cell: (row) => {
          return (
            <>
              <div className="d-flex">
                {permissionsMateriLatihan.includes('UPDATE') && (
                  <Link className="btn btn-primary shadow btn-xs sharp mx-1" onClick={() => handleEditLatihanRandomClick(row)} title="Edit">
                    <i className="fa fa-pencil"></i>
                  </Link>
                )}

                {permissionsMateriLatihan.includes('DELETE') && (
                  <Link className="btn btn-danger shadow btn-xs sharp" title="delete" onClick={() => handleDeleteLatihanRandomClick(row)}>
                    <i className="fa fa-trash"></i>
                  </Link>
                )}
              </div>
            </>
          );
        },
      }]
      : [])
  ];

  const columnsSimulasi = [
    {
      name: "Soal",
      selector: (row) => row.name,
      // width: "180px",
      // cell: (row) => (
      //   <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user?.name}</span>
      // ),
    },
    {
      name: "Jawaban",
      selector: (row) => row.answer,
    },
    {
      name: "Text to Speech",
      selector: (row) => row.talkback,
      // width: "180px",
      // cell: (row) => (
      //   <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user?.name}</span>
      // ),
    },
    {
      name: "Suara",
      selector: (row) => row.sound,
      width: "160px",
      cell: (row) => (
        <div className="">
          {!fileVoice[row.id] ? (
            "file suara tidak ada"
          ) : (
            <audio controls style={{ maxWidth: "150px" }}>
              <source src={fileVoice[row.id]} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
        // <div style={{ width: "100%", textAlign: "center" }}>
        //   {fileProfile[row.picture_file_id] && (
        //     <img
        //       src={fileProfile[row.picture_file_id]}
        //       // alt={row.description}
        //       style={{ maxWidth: "100px", maxHeight: "100px" }} // Sesuaikan ukuran gambar sesuai kebutuhan
        //     />
        //   )}
        // </div>
      ),
    },
    ...(canModifyMateriLatihan
      ? [{
        name: "Aksi",
        width: "30%",
        cell: (row) => {
          const maxOrder = Math.max(...dataLatihan?.map((item) => item.order));
          return (
            <>
              <div className="d-flex">
                {permissionsMateriLatihan.includes('UPDATE') && (
                  <Link className="btn btn-primary shadow btn-xs sharp mx-1" onClick={() => handleEditSimulasiClick(row)} title="Edit">
                    <i className="fa fa-pencil"></i>
                  </Link>
                )}

                {permissionsMateriLatihan.includes('DELETE') && (
                  <Link className="btn btn-danger shadow btn-xs sharp" title="delete" onClick={() => handleDeleteLatihanClick(row.id)}>
                    <i className="fa fa-trash"></i>
                  </Link>
                )}

                {permissionsMateriLatihan.includes('UPDATE') && (
                  <>
                    <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.order === 1 ? "disabled" : ""}`} title="up" onClick={() => handleUpClickLatihan(row.id)}>
                      <i className="fa fa-arrow-up"></i>
                    </Link>
                    <Link className={`btn btn-success shadow btn-xs sharp ${row.order === maxOrder ? "disabled" : ""}`} title="down" onClick={() => handleDownClickLatihan(row.id)}>
                      <i className="fa fa-arrow-down"></i>
                    </Link>
                  </>
                )}
              </div>
            </>
          );
        },
      }]
      : [])
  ];

  const handleChangeOrderLatihan = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "palingAtas") {
      setFormDataLatihan((prevData) => ({
        ...prevData,
        order: "Paling Atas",
      }));
    } else if (selectedValue === "palingBawah") {
      // const maxOrder = Math.max(...order.map(item => item.order));
      setFormDataLatihan((prevData) => ({
        ...prevData,
        order: "Paling Bawah",
      }));
    } else {
      setFormDataLatihan((prevData) => ({
        ...prevData,
        order: selectedValue,
      }));
    }

    setShowNested(selectedValue !== "palingAtas" && selectedValue !== "palingBawah" && selectedValue !== "");

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleChangeLatihan = (e) => {
    const { name, value } = e.target;
    setFormDataLatihan((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validasi form di sini
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleChangeLatihanRandom = (e) => {
    const { name, value } = e.target;
    setFormDataRandom((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validasi form di sini
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSubmitLatihan = async (e) => {
    e.preventDefault();
    const requestBody = {
      name: formDataLatihan.name,
      materi_id: formDataLatihan.materi_id,
      sound: formDataLatihan.sound,
      content: formDataLatihan.content,
      answer: formDataLatihan.answer,
      talkback: formDataLatihan.talkback,
      meaning: formDataLatihan.meaning,
      // new
      position: formDataLatihan.order == "Paling Atas" ? formDataLatihan.order : formDataLatihan.order == "Paling Bawah" ? formDataLatihan.order : "Setelah Materi",
      materiLatihan_id: formDataLatihan.order == "Paling Atas" || formDataLatihan.order == "Paling Bawah" ? "" : formDataLatihan.order,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (file.length !== 0) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "latihan");
      formDataObject.append("file", file);

      const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

      const newVoiceFileId = fileUploadResponse?.file.id;
      requestBody.sound = newVoiceFileId;
    }

    if (image) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "latihan");
      formDataObject.append("file", image);

      const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

      requestBody.image = fileUploadResponse?.file.id;
    }

    const res = await POST(`/materi-latihan`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: res.message,
      }).then(async () => {
        Swal.close();
        setFormDataLatihan({
          name: "",
          order: "",
        });
        setShowLatihan(false);
        setShowSimulasi(false);
        handleCloseLatihan();
        setFormDataLatihan({
          name: "",
          order: "",
          sound: "",
          materi_id: res.data?.materi_id,
          talkback: "",
          meaning: "",
        });
        setImage(null);
        setImagePreviewUrl(null);
        setIsNewFileSelected(false);
        setFile([]);

        const response = await GET(`/materi-latihan/${res.data?.materi_id}/materi`);
        if (response) {
          setDataLatihan(response?.data);
        }
      });
    }
  };

  const handleSubmitLatihanRandom = async (e) => {
    e.preventDefault();

    function normalizeString(str) {
      return str
        .normalize("NFKD") // Normalize to decomposed form
        .replace(/[\u064B-\u065F\u0610-\u061A\u06D6-\u06ED]/g, "") // Remove Arabic diacritics
        .replace(/\s+/g, " ") // Replace multiple spaces with a single space
        .trim(); // Trim spaces
    }
    // Normalize the question and answer
    const normalizedQuestion = normalizeString(formDataRandom.question);
    const normalizedAnswer = normalizeString(formDataRandom.answer);

    console.log("Normalized Question Length", normalizedQuestion.split(" ").length);
    console.log("Normalized Answer Length", normalizedAnswer.split(" ").length);
    if (normalizedQuestion.split(" ").length !== normalizedAnswer.split(" ").length) {
      return Swal.fire({
        icon: "error",
        title: `Jumlah jawaban harus sama dengan jumlah soal`,
      });
    }
    const formatting = `${formDataRandom.numberOfQuestions}:${formDataRandom.title} :${formDataRandom.question}:${formDataRandom.answer}`;
    if (detailData?.row?.content?.includes(formatting)) {
      console.log("Question", formDataRandom.question);
      return Swal.fire({
        icon: "error",
        title: `Soal tidak boleh sama dengan yang sudah ada`,
      });
    }
    let content = detailData.row.content ? `${detailData.row.content};${formatting}` : formatting;
    if (formDataRandom.id) {
      const dataRandomOld = dataLatihanRandom[formDataRandom.id - 1];
      const formattingOld = `${dataRandomOld.numberOfQuestions}:${dataRandomOld.title} :${dataRandomOld.question}:${dataRandomOld.answer}`;
      content = detailData.row.content.replace(formattingOld, formatting);
    }
    const requestBody = {
      content,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/materi/${detailData.row.id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat materi latihan!",
      }).then(async () => {
        Swal.close();
        handleCloseLatihanRandom();
        await reloadRandom();
      });
    }
  };

  const handleUpClickLatihan = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/materi-latihan/${id}/move-up`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke atas!",
      }).then(async () => {
        Swal.close();
        const response = await GET(`/materi-latihan/${detailData.row.id}/materi`);
        if (response) {
          setDataLatihan(response?.data);
        }
      });
    }
  };
  const handleDownClickLatihan = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/materi-latihan/${id}/move-down`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke bawah!",
      }).then(async () => {
        Swal.close();
        const response = await GET(`/materi-latihan/${detailData.row.id}/materi`);
        if (response) {
          setDataLatihan(response?.data);
        }
      });
    }
  };

  const handleEditLatihanClick = async (row) => {
    setEditLatihanFormData({
      name: row.name,
      content: row.content,
      answer: row.answer,
      sound: row.sound,
      meaning: row.meaning,
      talkback: row.talkback,
      image: row.image,
    });

    // setOrder(data)
    setEditLatihanModalShow(true);
    setEditLatihanModalId(row.id);

    if (row.sound?.length > 0) {
      const res = await GET(`/files/${row.sound}/download`, {}, { responseType: "arraybuffer" });
      const blob = new Blob([res], { type: "audio/mpeg" });
      const reader = new FileReader();

      reader.onload = () => {
        const base64Audio = reader.result;
        setVoicePreviewUrl(base64Audio);
      };

      reader.readAsDataURL(blob);
    }
    setShowButtonDelete(row.sound?.length > 0);
  };

  const handleEditSimulasiClick = async (row) => {
    setEditLatihanFormData({
      name: row.name,
      content: row.content,
      answer: row.answer,
      sound: row.sound,
      meaning: row.meaning,
      talkback: row.talkback,
      image: row.image,
    });

    // setOrder(data)
    setEditSimulasiModalShow(true);
    setEditLatihanModalId(row.id);

    if (row.sound?.length > 0) {
      const res = await GET(`/files/${row.sound}/download`, {}, { responseType: "arraybuffer" });
      const blob = new Blob([res], { type: "audio/mpeg" });
      const reader = new FileReader();

      reader.onload = () => {
        const base64Audio = reader.result;
        setVoicePreviewUrl(base64Audio);
      };

      reader.readAsDataURL(blob);
    }
    setShowButtonDelete(row.sound?.length > 0);
  };

  const handleEditLatihanRandomClick = async (row) => {
    setFormDataRandom(row);
    setShowLatihanRandom(true);
  };

  const handleChangeEditLatihan = (e) => {
    const { name, value } = e.target;
    setEditLatihanFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleEditLatihanModalClose = () => {
    setEditLatihanModalShow(false);
    setVoicePreviewUrl(null);
    setImagePreviewUrl(null);
    setEditSimulasiModalShow(false);
  };
  const handleEditLatihanSubmit = async (e) => {
    e.preventDefault();

    const { name, sound, answer, content, meaning, talkback } = editLatihanFormData;

    const requestBody = {
      name,
      sound,
      answer,
      content,
      meaning,
      talkback,
      image,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (file.length !== 0) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "latihan");
      formDataObject.append("file", file);

      const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

      const newVoiceFileId = fileUploadResponse?.file.id;
      requestBody.sound = newVoiceFileId;
    }

    if (image) {
      // Upload the new file
      const formDataObject = new FormData();
      formDataObject.append("type", "latihan");
      formDataObject.append("file", image);

      const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });

      requestBody.image = fileUploadResponse?.file.id;
    }

    if (isDeleteSound) {
      requestBody.sound = null;
    }

    const res = await PATCH(`/materi-latihan/${editLatihanModalId}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: res.message,
      }).then(async () => {
        Swal.close();
        setEditLatihanModalShow(false);
        setEditSimulasiModalShow(false);
        setFile([]);
        setImage(null);
        setImagePreviewUrl(null);
        setIsNewFileSelected(false);
        setFileVoice([]);

        const response = await GET(`/materi-latihan/${res.data?.materi_id}/materi`);
        if (response) {
          setDataLatihan(response?.data);
        }
      });
    }
  };

  const fetchFile = async () => {
    dataLatihan.forEach(async (item) => {
      if (item.sound) {
        const res = await GET(`/files/${item.sound}/download`, {}, { responseType: "arraybuffer" });
        const blob = new Blob([res], { type: "audio/mpeg" });
        const reader = new FileReader();

        reader.onload = () => {
          const base64Audio = reader.result;
          setFileVoice((prevState) => ({
            ...prevState,
            [item.id]: base64Audio,
          }));
        };
        reader.readAsDataURL(blob);
      } else {
        setFileVoice((prevState) => ({
          ...prevState,
          [item.id]: null,
        }));
      }
    });
  };

  const handleDeleteLatihanClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/materi-latihan/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus latihan!",
        }).then(async () => {
          Swal.close();
          const response = await GET(`/materi-latihan/${editModalId}/materi`);
          if (response) {
            setDataLatihan(response?.data);
          }
        });
      }
    }
  };

  const handleDeleteLatihanRandomClick = async (row) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let content;
      if (detailData.row.content.split(";").length === 1) {
        content = null;
      } else {
        if (row.id === detailData.row.content.split(";").length) {
          content = detailData.row.content.replace(`;${row.numberOfQuestions}:${row.title} :${row.question}:${row.answer}`, "");
        } else {
          content = detailData.row.content.replace(`${row.numberOfQuestions}:${row.title} :${row.question}:${row.answer};`, "");
        }
      }

      const requestBody = {
        content,
      };

      const res = await PATCH(`/materi/${detailData.row.id}`, requestBody);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus latihan!",
        }).then(async () => {
          Swal.close();
          await reloadRandom();
        });
      }
    }
  };

  const reloadRandom = async () => {
    const response = await GET(`/materi/${detailData.row.id}`);
    if (response) {
      setDataRandom(response?.data.content);
      setDetailData((prevData) => ({
        ...prevData,
        row: {
          ...prevData.row,
          content: response?.data.content,
        },
      }));
    }
  };

  const setDataRandom = (content) => {
    setDataLatihanRandom(
      content?.split(";").map((item, idx) => {
        const split = item.split(":");
        return { id: idx + 1, numberOfQuestions: split[0], title: split[1]?.substring(0, split[1]?.length - 1), question: split[2], answer: split[3] };
      })
    );
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setIsDeleteSound(false);
      const reader = new FileReader();
      reader.onload = () => {
        setVoicePreviewUrl(reader.result);
        setFile(selectedFile);

        if (audioRef.current) {
          audioRef.current.src = reader.result;
          audioRef.current.load();
          audioRef.current.play().catch((error) => {
            console.error("Error playing audio:", error);
          });
        }
      };
      reader.readAsDataURL(selectedFile);

      const form = event.currentTarget.form;
      const isValid = form.checkValidity();

      setValidated(isValid);
    }
  };

  const handleCancleClick = () => {
    setDetailData("");
  };

  const tipeLatihan = [
    {
      value: 0,
      text: "Skip",
    },
    {
      value: 1,
      text: "Chance",
    },
    {
      value: 2,
      text: "Mandatory",
    },
  ];

  const handleDeleteSound = () => {
    setEditFormData((prevData) => ({
      ...prevData,
      sound: null,
    }));
    setVoicePreviewUrl(null);
    setShowButtonDelete(false);
    setIsDeleteSound(true);
    setValidated(true);
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > fileSizeLimit) {
        return Swal.fire({
          icon: "error",
          title: `Ukuran file melebihi batas maksimum (10 MB)`,
        });
      }

      const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      if (!allowedTypes.includes(selectedFile.type)) {
        return Swal.fire({
          icon: "error",
          title: `File harus dalam format (jpg, jpeg, png, gif)`,
        });
      }

      setImage(selectedFile);
      setValidated(true);
      setIsNewFileSelected(true); // Set the flag when a new file is selected
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleDeletePhotoAdditional = () => {
    setEditFormData((prevData) => ({
      ...prevData,
      image: null,
    }));

    setImagePreviewUrl(null);
    setImage(null);
    setShowButtonDelete(false);
    setValidated(true);

    setEditFormData((prevData) => ({
      ...prevData,
      image: null,
    }));
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Materi Belajar</Card.Title>
            </Card.Header>

            <Card.Body>
              <div className="d-flex">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div className="d-flex justify-content-end mb-4">
                    {permissionsMateri.includes('CREATE') && (
                      <button className="btn btn-primary mr-2" onClick={() => handleShow({ from: "level 1" })}>
                        Tambah Materi
                      </button>
                    )}
                    <div className="input-group search-area d-xl-inline-flex">
                      <input type="text" className="form-control" placeholder="Cari Materi" value={searchTerm} onChange={handleSearchChange} />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <Link to="#">
                            <i className="flaticon-381-search-2" />
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                  {data && data.length > 0 ? <DataTable columns={columns} data={data} customStyles={customStyles} expandableRows expandableRowsComponent={ExpandedComponent} /> : <p>Tidak ada data.</p>}
                </div>
                {detailData && detailData?.row?.type === "Deskripsi" && (
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6" style={{ marginTop: "5rem" }}>
                    <form>
                      <h1>{detailData.nameSub}</h1>
                      <div className="form-group">
                        <div className="mb-1">
                          <label className="mx-2" style={{ wordWrap: "break-word", maxWidth: "100%", fontSize: "14px" }}>
                            {detailData.name}
                          </label>
                          {detailData.type === "Catatan" ? (
                            <Badge as="a" variant="outline-success badge-xs badge-rounded">
                              {detailData.type}
                            </Badge>
                          ) : detailData.type === "Deskripsi" ? (
                            <Badge as="a" variant="outline-warning badge-xs badge-rounded">
                              {detailData.type}
                            </Badge>
                          ) : detailData.type === "Latihan" ? (
                            <Badge as="a" variant="outline-info badge-xs badge-rounded">
                              {detailData.type}
                            </Badge>
                          ) : detailData.type === "BAB" ? (
                            <Badge as="a" variant="outline-primary badge-xs badge-rounded">
                              {detailData.type}
                            </Badge>
                          ) : null}
                          <span className="mx-2" style={{ color: "#AEAEAE", fontSize: "13px" }}>
                            Halaman {detailData.page}
                          </span>
                        </div>
                        <textarea
                          className="form-control"
                          name="content"
                          rows="5"
                          value={editFormData.content}
                          onChange={handleChangeEdit}
                        // readOnly
                        />
                      </div>
                      <div className="text-right">
                        <audio controls ref={audioRef}>
                          {voicePreviewUrl ? <source src={voicePreviewUrl} type="audio/mpeg" /> : "Tidak ada file"}
                        </audio>
                      </div>
                      <div className="form-group d-flex justify-content-end">
                        <label>Sound:</label>
                        <input className="ml-2" type="file" accept="audio/*" title="tes" onChange={handleFileChange} style={{ maxWidth: "230px" }} />
                      </div>

                      {/* <InputGroup>
                        <Button variant="primary" onClick={() => audioRef.current.click()}>
                          Pilih File
                        </Button>
                        <FormControl
                          readOnly
                          value={'tidak ada file'}
                        />
                        <input
                          type="file"
                          accept="audio/*"
                          onChange={handleFileChange}
                          ref={audioRef}
                          style={{ display: 'none' }}
                        />
                      </InputGroup> */}
                    </form>
                    <div className="form-group mb-3 d-flex mt-2">
                      <div className="col-xl-6">
                        <button className="btn btn-light w-100" onClick={handleCancleClick}>
                          Batal
                        </button>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={handleEditSubmit}>
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {detailData && detailData?.row?.type === "Catatan" && (
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6" style={{ marginTop: "5rem" }}>
                    <form>
                      <h1>{detailData.nameSub}</h1>
                      <div className="form-group">
                        <label className="mx-2" style={{ wordWrap: "break-word", maxWidth: "100%", fontSize: "14px" }}>
                          {detailData.name}
                        </label>
                        {detailData.type === "Catatan" ? (
                          <Badge as="a" variant="outline-success badge-xs badge-rounded">
                            {detailData.type}
                          </Badge>
                        ) : detailData.type === "Deskripsi" ? (
                          <Badge as="a" variant="outline-warning badge-xs badge-rounded">
                            {detailData.type}
                          </Badge>
                        ) : detailData.type === "Latihan" ? (
                          <Badge as="a" variant="outline-info badge-xs badge-rounded">
                            {detailData.type}
                          </Badge>
                        ) : detailData.type === "BAB" ? (
                          <Badge as="a" variant="outline-primary badge-xs badge-rounded">
                            {detailData.type}
                          </Badge>
                        ) : null}
                        <span className="mx-2" style={{ color: "#AEAEAE", fontSize: "13px" }}>
                          Halaman {detailData.page}
                        </span>
                        <textarea
                          className="form-control"
                          name="content"
                          rows="5"
                          value={editFormData.content}
                          onChange={handleChangeEdit}
                        // readOnly
                        />
                      </div>
                      <div className="text-right">
                        <audio controls ref={audioRef}>
                          {voicePreviewUrl ? <source src={voicePreviewUrl} type="audio/mpeg" /> : "Tidak ada file"}
                        </audio>
                      </div>
                      <div className="form-group d-flex justify-content-end">
                        <label>Sound:</label>
                        <input className="ml-2" type="file" accept="audio/*" title="tes" onChange={handleFileChange} style={{ maxWidth: "230px" }} />
                      </div>
                    </form>
                    <div className="form-group mb-3 d-flex mt-2">
                      <div className="col-xl-6">
                        <button className="btn btn-light w-100" onClick={handleCancleClick}>
                          Batal
                        </button>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={handleEditSubmit}>
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {detailData && detailData?.row?.type === "Latihan" && (
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-4">
                    <h1>{detailData.nameSub}</h1>
                    <div className="d-flex mt-2 mb-2">
                      <h4 className="mr-auto mt-2" style={{ wordWrap: "break-word", maxWidth: "100%", fontSize: "14px" }}>
                        {detailData.name}
                      </h4>
                      {permissionsMateriLatihan.includes('CREATE') && (
                        <button type="submit" className="btn btn-primary btn-sm" style={{ padding: "5px" }} onClick={handleShowLatihan}>
                          Tambah
                        </button>
                      )}
                    </div>

                    {(dataLatihan && dataLatihan?.length > 0) || (dataLatihanRandom && dataLatihanRandom?.length > 0) ? (
                      <DataTable
                        columns={detailData.row.random === 1 ? columnsLatihanRandom : columnsLatihan}
                        data={detailData.row.random === 1 ? dataLatihanRandom : dataLatihan}
                        // defaultSortFieldId={3}
                        customStyles={customStyles}
                      // pagination
                      // paginationServer
                      // paginationResetDefaultPage={resetPaginationToggle}
                      // paginationPerPage={perPage}
                      // paginationRowsPerPageOptions={[10, 25, 50]}
                      // paginationTotalRows={totalRows}
                      // onChangePage={handlePageChange}
                      // onChangeRowsPerPage={handlePerPageChange}
                      // onSort={handleSort}
                      />
                    ) : (
                      <p>Tidak ada data</p>
                    )}
                    {/* <div className="form-group mb-3 d-flex mt-2">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100">
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100">
                          Simpan
                        </button>
                      </div>
                    </div> */}
                  </div>
                )}
                {detailData &&
                  detailData?.row?.type === "Simulasi" && ( //Simulasi
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-4">
                      <h1>{detailData.nameSub}</h1>
                      <div className="d-flex mt-2 mb-2">
                        <h4 className="mr-auto mt-2" style={{ wordWrap: "break-word", maxWidth: "100%", fontSize: "14px" }}>
                          {detailData.name}
                        </h4>
                        {permissionsMateriLatihan.includes('CREATE') && (
                          <button type="submit" className="btn btn-primary btn-sm" style={{ padding: "5px" }} onClick={handleShowSimulasi}>
                            Tambah
                          </button>
                        )}
                      </div>

                      {(dataLatihan && dataLatihan?.length > 0) || (dataLatihanRandom && dataLatihanRandom?.length > 0) ? <DataTable columns={columnsSimulasi} data={dataLatihan} customStyles={customStyles} /> : <p>Tidak ada data</p>}
                    </div>
                  )}
                {detailData &&
                  detailData?.row?.type === "Test" && ( //test
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-4">
                      <h1>{detailData.nameSub}</h1>
                      <div className="d-flex mt-2 mb-2">
                        <h4 className="mr-auto mt-2" style={{ wordWrap: "break-word", maxWidth: "100%", fontSize: "14px" }}>
                          {detailData.name}
                        </h4>
                        {permissionsMateriLatihan.includes('CREATE') && (
                          <button type="submit" className="btn btn-primary btn-sm" style={{ padding: "5px" }} onClick={handleShowTest}>
                            Tambah
                          </button>
                        )}
                      </div>
                      {(dataLatihan && dataLatihan?.length > 0) || (dataLatihanRandom && dataLatihanRandom?.length > 0) ? (
                        <DataTable columns={detailData.row.random === 1 ? columnsLatihanRandom : columnsSimulasi} data={detailData.row.random === 1 ? dataLatihanRandom : dataLatihan} customStyles={customStyles} />
                      ) : (
                        <p>Tidak ada data</p>
                      )}
                    </div>
                  )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Tambah Materi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-flex">
              <div className="col-12 col-md-12">
                <Form.Group controlId="tipeMateri">
                  {/* Tipe Materi */}
                  <Form.Label>Tipe Materi</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.type}
                    onChange={(e) => {
                      handleChange(e);
                      const value = e.target.value;
                      setFormData({
                        ...formData,
                        type: value,
                      });
                    }}
                    required
                    disabled={tambahMateri === "level 1"}
                  >
                    <option value="">Pilih Tipe Materi</option>
                    {type.map((dataType) => (
                      <option key={dataType.id} value={dataType.name}>
                        {dataType.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                </Form.Group>

                {formData.type !== "Kecepatan" && formData.type !== "Donasi" && (
                  <>
                    <div className="form-group">
                      <label>
                        Nama Materi<span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" value={formData.name} name="name" onChange={handleChange} minLength="3" required />
                      <Form.Control.Feedback type="invalid">Nama Materi harus diisi minimal 3 karakter.</Form.Control.Feedback>
                    </div>

                    {formData.type === "Volume" && (
                      <Form.Group controlId="tipeMateri">
                        {/* Tipe Materi */}
                        <Form.Label>Jenis Volume</Form.Label>
                        <Form.Control
                          as="select"
                          value={formData.volume_type}
                          onChange={(e) => {
                            handleChange(e);
                            const value = e.target.value;
                            setFormData({
                              ...formData,
                              volume_type: value,
                            });
                          }}
                          required
                        // disabled={tambahMateri === 'level 1'}
                        >
                          <option value="">Pilih Jenis Volume</option>
                          {jenisVolume.map((dataType) => (
                            <option key={dataType.description} value={dataType.description}>
                              {dataType.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                      </Form.Group>
                    )}

                    {formData.type === "Latihan" && (
                      <>
                        <Form.Group>
                          {/* Tipe Latihan */}
                          <Form.Label>Tipe Latihan</Form.Label>
                          <Form.Control
                            as="select"
                            value={formData.correct}
                            onChange={(e) => {
                              handleChange(e);
                              const value = e.target.value;
                              setFormData({
                                ...formData,
                                correct: value,
                              });
                            }}
                            required
                          >
                            {/* <option value="">Pilih Tipe Latihan</option>
                            {tipeLatihan.map((dataType) => (
                              <option key={dataType.value} value={dataType.value}>
                                {dataType.text}
                              </option>
                            ))} */}
                            <option value="">Pilih Tipe Latihan</option>
                            {tipeSimulasi.map((dataType) => (
                              <option key={dataType.id} value={dataType.description}>
                                {dataType.name}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}

                    {formData.type === "Simulasi" && (
                      <Form.Group controlId="tipeMateri">
                        {/* Tipe Materi */}
                        <Form.Label>Tipe Simulasi</Form.Label>
                        <Form.Control
                          as="select"
                          value={formData.correct}
                          onChange={(e) => {
                            handleChange(e);
                            const value = e.target.value;
                            setFormData({
                              ...formData,
                              correct: value,
                            });
                          }}
                          required
                          disabled={tambahMateri === "level 1"}
                        >
                          <option value="">Pilih Tipe Simulasi</option>
                          {tipeSimulasi.map((dataType) => (
                            <option key={dataType.id} value={dataType.description}>
                              {dataType.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                      </Form.Group>
                    )}

                    {(formData.type === "Latihan" || formData.type === "Simulasi") && (
                      <>
                        <input
                          type="checkbox"
                          id="flexCheckDefault"
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setFormData({
                              ...formData,
                              random: checked,
                            });
                          }}
                          checked={formData.random}
                          disabled={formData.correct == 3 || formData.correct == 2 || formData.type == "Simulasi"}
                        />
                        <label class="ml-1" for="flexCheckDefault">
                          Random
                        </label>
                      </>
                    )}

                    {formData.type === "Tutorial" && (
                      <>
                        <div className="form-group">
                          <label>Gambar</label>
                          <div className="input-group">
                            <button type="button" className="btn btn-primary" onClick={() => fileInputRef.current.click()}>
                              Pilih File
                            </button>
                            <span className="input-group-text">{image ? (image.name.length > 25 ? `${image.name.slice(0, 25)}...` : image.name) : "Tidak ada file yang dipilih..."}</span>
                            <input type="file" ref={fileInputRef} className="d-none" onChange={handleImageChange} accept=".jpg, .jpeg, .png, .gif" isInvalid={image === null && validated} />
                            {image && <Form.Control.Feedback type="invalid">Konten harus diisi.</Form.Control.Feedback>}
                          </div>
                          {imagePreviewUrl && isNewFileSelected && (
                            <>
                              <div className="d-flex flex-column my-1">
                                <Form.Label>Preview</Form.Label>
                                <img src={imagePreviewUrl} alt="Foto Konten Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                              </div>
                              <button type="button" className="btn btn-danger ml-2" onClick={() => handleDeletePhotoAdditional()}>
                                Hapus
                              </button>
                            </>
                          )}
                        </div>

                        {/* {imagePreviewUrl && ( */}
                        <div className="form-group">
                          <label>Konten</label>
                          <textarea
                            className="form-control"
                            name="content"
                            rows="5"
                            value={formData.content}
                            onChange={handleChange}
                          // minLength="3"
                          // required
                          />
                          <Form.Control.Feedback type="invalid">Konten harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                        {/* )} */}

                        {!voicePreviewUrl && (
                          <div className="form-group">
                            <label>Text to Speech</label>
                            <textarea type="text" className="form-control" value={formData.talkback} name="talkback" onChange={handleChange} maxLength="1000" minLength="3" rows="5" />
                            <Form.Control.Feedback type="invalid">Text to Speech harus diisi minimal 3 karakter.</Form.Control.Feedback>
                          </div>
                        )}

                        {!formData?.talkback && (
                          <>
                            <div className="form-group">
                              <label>Suara:</label>
                              <input className="ml-2" type="file" accept="audio/*" onChange={handleFileChange} />
                            </div>

                            {voicePreviewUrl && (
                              <div className="">
                                <audio controls ref={audioRef}>
                                  {voicePreviewUrl ? <source src={voicePreviewUrl} type="audio/mpeg" /> : "Tidak ada file"}
                                </audio>
                              </div>
                            )}

                            {voicePreviewUrl && (
                              <button type="button" className="btn btn-primary my-2 ml-2" onClick={() => handleDeleteSound()}>
                                Hapus Sound
                              </button>
                            )}
                          </>
                        )}

                        <Form.Group controlId="jenisTampilan">
                          {/* Tipe Materi */}
                          <Form.Label>Jenis Tampilan</Form.Label>
                          <Form.Control
                            as="select"
                            value={formData.display_type}
                            onChange={(e) => {
                              handleChange(e);
                              const value = e.target.value;
                              setFormData({
                                ...formData,
                                display_type: value,
                              });
                            }}
                            required
                          // disabled={tambahMateri === 'level 1'}
                          >
                            <option value="">Pilih Jenis Tampilan</option>
                            {jenisTampilan.map((dataType) => (
                              <option key={dataType.id} value={dataType.description}>
                                {dataType.name}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}

                    {formData.type !== "Akhir" && formData.type !== "Simulasi" && formData.type !== "Volume" && formData.type !== "Deskripsi" && formData.type !== "Kuis" && formData.type !== "Test" && (
                      <div className="form-group">
                        <label>Halaman</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.page}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setFormData((prevState) => ({
                              ...prevState,
                              page: numericValue,
                            }));

                            const form = e.currentTarget.form;
                            const isValid = form.checkValidity();
                            setValidated(isValid);
                            if (isValid) {
                              form.classList.remove("was-validated");
                            } else {
                              form.classList.add("was-validated");
                            }
                          }}
                          maxLength="5"
                          pattern="[0-9]{1,}"
                        />
                        <Form.Control.Feedback type="invalid">Halaman harus diisi.</Form.Control.Feedback>
                      </div>
                    )}

                    {(formData.type === "Tutorial" || formData.type === "Latihan") && (
                      <div className="form-group">
                        <label>Baris</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.line}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setFormData((prevState) => ({
                              ...prevState,
                              line: numericValue,
                            }));

                            const form = e.currentTarget.form;
                            const isValid = form.checkValidity();
                            setValidated(isValid);
                            if (isValid) {
                              form.classList.remove("was-validated");
                            } else {
                              form.classList.add("was-validated");
                            }
                          }}
                          maxLength="5"
                          pattern="[0-9]{1,}"
                        />
                        <Form.Control.Feedback type="invalid">Baris harus diisi.</Form.Control.Feedback>
                      </div>
                    )}

                    {formData.type === "Latihan" && (
                      <div className="form-group">
                        <label>Keterangan</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.information}
                          name="information"
                          onChange={handleChange}
                        // minLength="3"
                        // required
                        />
                        <Form.Control.Feedback type="invalid">Keterangan harus diisi.</Form.Control.Feedback>
                      </div>
                    )}
                  </>
                )}

                {formData.type === "Akhir" && (
                  <>
                    <Form.Group controlId="tipeMateri">
                      {/* Tipe Materi */}
                      <Form.Label>Tipe Akhir</Form.Label>
                      <Form.Control
                        as="select"
                        value={formData.final_type}
                        onChange={(e) => {
                          handleChange(e);
                          const value = e.target.value;
                          setFormData({
                            ...formData,
                            final_type: value,
                          });
                        }}
                        required
                      // disabled={tambahMateri === 'level 1'}
                      >
                        <option value="">Pilih Tipe Akhir</option>
                        {tipeAkhir.map((dataType) => (
                          <option key={dataType.id} value={dataType.description}>
                            {dataType.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                    </Form.Group>

                    <div className="form-group">
                      <label>Text to Speech</label>
                      <textarea rows="5" type="text" className="form-control" value={formData.talkback} name="talkback" onChange={handleChange} maxLength="1000" minLength="3" />
                      <Form.Control.Feedback type="invalid">Text to Speech harus diisi minimal 3 karakter.</Form.Control.Feedback>
                    </div>

                    <div className="form-group">
                      <label>Konten</label>
                      <textarea
                        className="form-control"
                        name="content"
                        rows="5"
                        value={formData.content}
                        onChange={handleChange}
                      // minLength="3"
                      />
                      <Form.Control.Feedback type="invalid">Konten harus diisi minimal 3 karakter.</Form.Control.Feedback>
                    </div>

                    <Form.Group controlId="tipeMateri">
                      {/* Tipe Materi */}
                      <Form.Label>Ulangi ke Halaman</Form.Label>
                      <Form.Control
                        as="select"
                        value={formData.loop_to_page}
                        onChange={(e) => {
                          handleChange(e);
                          const value = e.target.value;
                          setFormData({
                            ...formData,
                            loop_to_page: value,
                          });
                        }}
                        required
                      // disabled={tambahMateri === 'level 1'}
                      >
                        <option value="">Pilih Halaman</option>
                        {order &&
                          order
                            .sort((a, b) => a.order - b.order)
                            .map((dataOrder) => (
                              <option key={dataOrder.id} value={dataOrder.id}>
                                {dataOrder.name}
                              </option>
                            ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}

                <Form.Group controlId="posisi">
                  {/* Tipe Letak/Posisi */}
                  <Form.Label>Letak/Posisi</Form.Label>
                  <Form.Control as="select" onChange={handleChangeOrder} required>
                    <option value="">Pilih Letak/Posisi</option>
                    <option value="palingAtas">Paling Atas</option>
                    {order && order.length > 1 && <option value="setelahMateri">Setelah Materi</option>}
                    {order && order.length > 0 && <option value="palingBawah">Paling Bawah</option>}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>

                  {showNested && (
                    <Form.Group className="mt-3" controlId="typeSelection">
                      <Form.Label>Pilih Materi</Form.Label>
                      <Form.Control as="select" onChange={handleChangeOrder}>
                        <option>Pilih Materi</option>
                        {order &&
                          order
                            .sort((a, b) => a.order - b.order)
                            .map((dataOrder) => (
                              <option key={dataOrder.id} value={dataOrder.id}>
                                {dataOrder.name}
                              </option>
                            ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                </Form.Group>

                {/* {formData.type !== "BAB" && formData.type !== "Latihan" && formData.type !== "Kecepatan" && formData.type !== "Donasi" && formData.type !== "" && formData.type !== "Akhir" && formData.type !== "Tutorial" && formData.type !== "Simulasi" && formData.type !== "Voume" && (
                  <>
                    <div className="form-group">
                      <label>Sound:</label>
                      <input
                        className="ml-2"
                        type="file"
                        accept="audio/*"
                        onChange={handleFileChange}
                      />
                    </div>

                    {voicePreviewUrl && (
                      <div className="">
                        <audio controls ref={audioRef}>
                          {voicePreviewUrl ? (
                            <source src={voicePreviewUrl} type="audio/mpeg" />
                          ) : (
                            "Tidak ada file"
                          )}
                        </audio>
                      </div>
                    )}
                  </>
                )} */}

                {formData.type === "Donasi" && (
                  <div className="form-group">
                    <label>Konten</label>
                    <div className="input-group">
                      <button type="button" className="btn btn-primary" onClick={() => fileInputRef.current.click()}>
                        Pilih File
                      </button>
                      <span className="input-group-text">{image ? (image.name.length > 25 ? `${image.name.slice(0, 25)}...` : image.name) : "Tidak ada file yang dipilih..."}</span>
                      <input type="file" ref={fileInputRef} className="d-none" onChange={handleImageChange} accept=".jpg, .jpeg, .png, .gif" isInvalid={image === null && validated} />
                      {image && <Form.Control.Feedback type="invalid">Konten harus diisi.</Form.Control.Feedback>}
                    </div>
                    {imagePreviewUrl && isNewFileSelected && (
                      <div className="d-flex flex-column my-1">
                        <Form.Label>Preview</Form.Label>
                        <img src={imagePreviewUrl} alt="Foto Konten Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                      </div>
                    )}
                  </div>
                )}

                {/* {(formData.type === "Deskripsi" || formData.type === "Donasi") && (
                  <div className='form-group'>
                    <label>Konten</label>
                    <textarea
                      className='form-control'
                      name="content"
                      rows="5"
                      value={formData.content}
                      onChange={handleChange}
                      minLength="3"
                    />
                    <Form.Control.Feedback type="invalid">
                      Konten harus diisi minimal 3 karakter.
                    </Form.Control.Feedback>
                  </div>
                )} */}

                {formData.type === "Donasi" && (
                  <>
                    <Form.Group>
                      <input
                        type="checkbox"
                        id="donationCheckDefault"
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setFormData({
                            ...formData,
                            donation: checked,
                          });

                          const form = e.currentTarget.form;
                          const isValid = form.checkValidity();
                          setValidated(isValid);
                          if (isValid) {
                            form.classList.remove("was-validated");
                          } else {
                            form.classList.add("was-validated");
                          }
                        }}
                        checked={formData.donation}
                      />
                      <label class="ml-1" for="donationCheckDefault">
                        Donasi
                      </label>
                    </Form.Group>
                  </>
                )}

                {/* {formData.type === "Deskripsi" && (
                  <>
                    <Form.Group>
                      <input
                        type="checkbox"
                        id="firstTimeOnlyCheckDefault"
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setFormData({
                            ...formData,
                            first_time_only: checked,
                          });

                          const form = e.currentTarget.form;
                          const isValid = form.checkValidity();
                          setValidated(isValid);
                          if (isValid) {
                            form.classList.remove("was-validated");
                          } else {
                            form.classList.add("was-validated");
                          }
                        }}
                        checked={formData.first_time_only}
                      />
                      <label class="ml-1" for="firstTimeOnlyCheckDefault">
                        Pertama kali saja
                      </label>

                      <input
                        type="checkbox"
                        id="brailleInteractionCheckDefault"
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setFormData({
                            ...formData,
                            braille_interaction: checked,
                          });

                          const form = e.currentTarget.form;
                          const isValid = form.checkValidity();
                          setValidated(isValid);
                          if (isValid) {
                            form.classList.remove("was-validated");
                          } else {
                            form.classList.add("was-validated");
                          }
                        }}
                        checked={formData.braille_interaction}
                      />
                      <label class="ml-1" for="brailleInteractionCheckDefault">
                        Interaksi braille
                      </label>
                    </Form.Group>
                  </>
                )} */}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group mb-3 d-flex mt-2">
            <div className="col-xl-6">
              <Link className="btn btn-light w-100" onClick={handleClose}>
                Batal
              </Link>
            </div>
            <div className="col-xl-6">
              <button type="submit" className="btn btn-primary w-100" onClick={handleSubmit} disabled={!validated}>
                Simpan
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={editModalShow} onHide={handleEditModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Ubah Materi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSubmit} noValidate validated={validated}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Tipe Materi</Form.Label>
              <Form.Control
                type="text"
                name="type"
                placeholder="Pilih Tipe Materi"
                value={editFormData.type}
                // onChange={handleChangeEdit}
                pattern=".{5,}"
                disabled
              />
              <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group controlId="tipeMateri">
              <Form.Label>Tipe Materi</Form.Label>
              <Form.Control
                as="select"
                value={editFormData.type}
                onChange={(e) => {
                  handleChange(e);
                  const value = e.target.value;
                  setEditFormData({
                    ...editFormData,
                    type: value,
                  });
                }}
                // disabled
                required
              >
                <option value="">Pilih Tipe Materi</option>
                {type.map((dataType) => (
                  <option key={dataType.id} value={dataType.name}>
                    {dataType.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Harus pilih salah satu.
              </Form.Control.Feedback>
            </Form.Group> */}

            {editFormData.type !== "Donasi" && (
              <>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Nama Materi</Form.Label>
                  <Form.Control type="text" name="name" placeholder="Enter name" value={editFormData.name} onChange={handleChangeEdit} pattern=".{5,}" required />
                  <Form.Control.Feedback type="invalid">Nama Materi harus diisi minimal 5 karakter.</Form.Control.Feedback>
                </Form.Group>
              </>
            )}

            {editFormData.type === "Volume" && (
              <Form.Group controlId="jenisVolume">
                <Form.Label>Jenis Volume</Form.Label>
                <Form.Control
                  as="select"
                  value={editFormData.volume_type}
                  // onChange={(e) => {
                  //   handleChange(e);
                  //   const value = e.target.value;
                  //   setEditFormData({
                  //     ...editFormData,
                  //     type: value,
                  //   });
                  // }}
                  disabled
                >
                  <option value="">Pilih Jenis Volume</option>
                  {jenisVolume.map((dataType) => (
                    <option key={dataType.id} value={dataType.description}>
                      {dataType.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
              </Form.Group>
            )}

            {editFormData.type === "Topik" && (
              <>
                <div className="form-group">
                  <label>Halaman</label>
                  <input
                    type="text"
                    name="page"
                    className="form-control"
                    value={editFormData.page}
                    onChange={(e) => {
                      const { value } = e.target;
                      // Hanya karakter angka
                      const numericValue = value.replace(/\D/g, "");
                      setEditFormData((prevState) => ({
                        ...prevState,
                        page: numericValue,
                      }));

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    maxLength="5"
                    pattern="[0-9]{1,}"
                  />
                  <Form.Control.Feedback type="invalid">Halaman harus diisi.</Form.Control.Feedback>
                </div>
              </>
            )}

            {/* {editFormData.type !== "Volume" && editFormData.type !== "BAB" && editFormData.type !== "Latihan" && editFormData.type !== "Donasi" && editFormData.type !== "" && (
              <>
                <div className="form-group">
                  <label>Sound:</label>
                  <input
                    className="ml-2"
                    type="file"
                    accept="audio/*"
                    onChange={handleFileChange}
                  />
                </div>

                {voicePreviewUrl && (
                  <div className="">
                    <audio controls ref={audioRef}>
                      {voicePreviewUrl ? (
                        <source src={voicePreviewUrl} type="audio/mpeg" />
                      ) : (
                        "Tidak ada file"
                      )}
                    </audio>
                  </div>
                )}
              </>
            )} */}
            {(editFormData.type === "Tutorial" || editFormData.type === "Donasi") && (
              <>
                <div className="form-group">
                  <label>Gambar</label>
                  <div>
                    {!isNewFileSelected && editFormData && editFormData.image ? (
                      <div>
                        {editFormData?.image ? (
                          <img src={`${process.env.REACT_APP_API_BASE_URL}files/${editFormData.image}/download`} alt="konten" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                        ) : (
                          <p>Tidak Ada Photo</p>
                        )}
                      </div>
                    ) : null}
                    {imagePreviewUrl && isNewFileSelected && (
                      <>
                        <div className="d-flex flex-column my-1">
                          <Form.Label>Preview</Form.Label>
                          <img src={imagePreviewUrl} alt="Foto Konten Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                        </div>
                      </>
                    )}

                    <div>
                      <div className="input-group">
                        <button type="button" className="btn btn-primary my-2" onClick={() => fileInputRef.current.click()}>
                          {editFormData?.image || imagePreviewUrl ? "Ganti File" : "Pilih File"}
                        </button>
                        {(editFormData?.image || imagePreviewUrl) && (
                          <button type="button" className="btn btn-danger ml-2 my-2" onClick={() => handleDeletePhotoAdditional()}>
                            Hapus
                          </button>
                        )}
                      </div>
                      <input type="file" ref={fileInputRef} className="d-none" onChange={handleImageChange} accept=".jpg, .jpeg, .png, .gif" />
                      <Form.Control.Feedback type="invalid">Silakan pilih file foto konten</Form.Control.Feedback>
                    </div>
                  </div>
                </div>

                {/* {imagePreviewUrl && ( */}
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Konten</Form.Label>
                  <textarea
                    className="form-control"
                    name="content"
                    placeholder="Enter content"
                    rows="5"
                    value={editFormData.content}
                    onChange={handleChangeEdit}
                  // pattern=".{5,}"
                  />
                  <Form.Control.Feedback type="invalid">Konten harus diisi minimal 5 karakter.</Form.Control.Feedback>
                </Form.Group>
                {/* )} */}

                {!voicePreviewUrl && (
                  <div className="form-group">
                    <label>Text to Speech</label>
                    <textarea rows="5" type="text" className="form-control" value={editFormData.talkback} name="talkback" onChange={handleChangeEdit} maxLength="1000" minLength="3" />
                    <Form.Control.Feedback type="invalid">Text to Speech harus diisi minimal 3 karakter.</Form.Control.Feedback>
                  </div>
                )}

                {!editFormData?.talkback && (
                  <>
                    <div className="form-group">
                      <label>Suara:</label>
                      <input className="ml-2" type="file" accept="audio/*" onChange={handleFileChange} />
                    </div>
                    {voicePreviewUrl && (
                      <div className="">
                        <audio controls ref={audioRef}>
                          {voicePreviewUrl ? <source src={voicePreviewUrl} type="audio/mpeg" /> : "Tidak ada file"}
                        </audio>
                      </div>
                    )}
                    {showButtonDelete && (
                      <button type="button" className="btn btn-primary my-2 ml-2" onClick={() => handleDeleteSound()}>
                        Hapus Sound
                      </button>
                    )}
                  </>
                )}

                <Form.Group controlId="jenisVolume">
                  <Form.Label>Jenis Tampilan</Form.Label>
                  <Form.Control
                    as="select"
                    value={editFormData.display_type}
                    onChange={(e) => {
                      handleChange(e);
                      const value = e.target.value;
                      setEditFormData({
                        ...editFormData,
                        display_type: value,
                      });
                    }}
                    // disabled
                    required
                  >
                    <option value="">Pilih Jenis Tampilan</option>
                    {jenisTampilan.map((dataType) => (
                      <option key={dataType.id} value={dataType.description}>
                        {dataType.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                </Form.Group>

                <div className="form-group">
                  <label>Halaman</label>
                  <input
                    type="text"
                    name="page"
                    className="form-control"
                    value={editFormData.page}
                    onChange={(e) => {
                      const { value } = e.target;
                      // Hanya karakter angka
                      const numericValue = value.replace(/\D/g, "");
                      setEditFormData((prevState) => ({
                        ...prevState,
                        page: numericValue,
                      }));

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    maxLength="5"
                    pattern="[0-9]{1,}"
                  />
                  <Form.Control.Feedback type="invalid">Halaman harus diisi.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Baris</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editFormData.line}
                    onChange={(e) => {
                      const { value } = e.target;
                      // Hanya karakter angka
                      const numericValue = value.replace(/\D/g, "");
                      setEditFormData((prevState) => ({
                        ...prevState,
                        line: numericValue,
                      }));

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    maxLength="5"
                    pattern="[0-9]{1,}"
                  />
                  <Form.Control.Feedback type="invalid">Baris harus diisi.</Form.Control.Feedback>
                </div>
              </>
            )}

            {editFormData.type === "Simulasi" && (
              <>
                <Form.Group controlId="tipeMateri">
                  {/* Tipe Materi */}
                  <Form.Label>Tipe Simulasi</Form.Label>
                  <Form.Control
                    as="select"
                    value={editFormData.correct}
                    onChange={(e) => {
                      handleChange(e);
                      const value = e.target.value;
                      setEditFormData({
                        ...editFormData,
                        correct: value,
                      });
                    }}
                    required
                    disabled={tambahMateri === "level 1"}
                  >
                    <option value="">Pilih Tipe Simulasi</option>
                    {tipeSimulasi.map((dataType) => (
                      <option key={dataType.id} value={dataType.description}>
                        {dataType.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                </Form.Group>

                <input
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setEditFormData({
                      ...editFormData,
                      random: checked,
                    });
                  }}
                  checked={editFormData.random}
                  disabled={editFormData.correct == "Check" || editFormData.correct == "Mandatory" || editFormData.type == "Simulasi"}
                />
                <label class="ml-1" for="flexCheckDefault">
                  Random
                </label>
              </>
            )}

            {editFormData.type === "Latihan" && (
              <>
                <Form.Group>
                  {/* Tipe Latihan */}
                  <Form.Label>Tipe Latihan</Form.Label>
                  <Form.Control as="select" name="correct" value={editFormData.correct} onChange={handleChangeEdit} required>
                    {/* <option value="">Pilih Tipe Latihan</option>
                    {tipeLatihan.map((dataType) => (
                      <option key={dataType.value} value={dataType.value}>
                        {dataType.text}
                      </option>
                    ))} */}
                    <option value="">Pilih Tipe Latihan</option>
                    {tipeSimulasi.map((dataType) => (
                      <option key={dataType.id} value={dataType.description}>
                        {dataType.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>

                  <input
                    type="checkbox"
                    id="flexCheckDefaultEdit"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setEditFormData({
                        ...editFormData,
                        random: checked,
                      });

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    checked={editFormData.random}
                    disabled={editFormData.correct == "Check" || editFormData.correct == "Mandatory" || editFormData.type == "Simulasi"}
                  />
                  <label class="ml-1" for="flexCheckDefaultEdit">
                    Random
                  </label>

                  <div className="form-group">
                    <label>Halaman</label>
                    <input
                      type="text"
                      name="page"
                      className="form-control"
                      value={editFormData.page}
                      onChange={(e) => {
                        const { value } = e.target;
                        // Hanya karakter angka
                        const numericValue = value.replace(/\D/g, "");
                        setEditFormData((prevState) => ({
                          ...prevState,
                          page: numericValue,
                        }));

                        const form = e.currentTarget.form;
                        const isValid = form.checkValidity();
                        setValidated(isValid);
                        if (isValid) {
                          form.classList.remove("was-validated");
                        } else {
                          form.classList.add("was-validated");
                        }
                      }}
                      maxLength="5"
                      pattern="[0-9]{1,}"
                    />
                    <Form.Control.Feedback type="invalid">Halaman harus diisi.</Form.Control.Feedback>
                  </div>

                  <div className="form-group">
                    <label>Baris</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editFormData.line}
                      onChange={(e) => {
                        const { value } = e.target;
                        // Hanya karakter angka
                        const numericValue = value.replace(/\D/g, "");
                        setEditFormData((prevState) => ({
                          ...prevState,
                          line: numericValue,
                        }));

                        const form = e.currentTarget.form;
                        const isValid = form.checkValidity();
                        setValidated(isValid);
                        if (isValid) {
                          form.classList.remove("was-validated");
                        } else {
                          form.classList.add("was-validated");
                        }
                      }}
                      maxLength="5"
                      pattern="[0-9]{1,}"
                    />
                    <Form.Control.Feedback type="invalid">Baris harus diisi.</Form.Control.Feedback>
                  </div>

                  <div className="form-group">
                    <label>Keterangan</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editFormData.information}
                      name="information"
                      onChange={handleChangeEdit}
                    // minLength="3"
                    // required
                    />
                    <Form.Control.Feedback type="invalid">Keterangan harus diisi.</Form.Control.Feedback>
                  </div>

                  {/* <input
                    type="checkbox"
                    id="hintCheckDefaultEdit"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setEditFormData({
                        ...editFormData,
                        hint: checked,
                      });

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    checked={editFormData.hint}
                  />
                  <label class="ml-1" for="hintCheckDefaultEdit">
                    Hint
                  </label> */}
                </Form.Group>
              </>
            )}

            {editFormData.type === "Donasi" && (
              <>
                <Form.Group>
                  <input
                    type="checkbox"
                    id="donationCheckDefaultEdit"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setEditFormData({
                        ...editFormData,
                        donation: checked,
                      });

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    checked={editFormData.donation}
                  />
                  <label class="ml-1" for="donationCheckDefaultEdit">
                    Donasi
                  </label>
                </Form.Group>
              </>
            )}

            {/* {editFormData.type === "Deskripsi" && (
              <>
                <Form.Group>
                  <input
                    type="checkbox"
                    id="firstTimeOnlyCheckDefaultEdit"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setEditFormData({
                        ...editFormData,
                        first_time_only: checked,
                      });

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    checked={editFormData.first_time_only}
                  />
                  <label class="ml-1" for="firstTimeOnlyCheckDefaultEdit">
                    Pertama kali saja
                  </label>

                  <input
                    type="checkbox"
                    id="brailleInteractionCheckDefaultEdit"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setEditFormData({
                        ...editFormData,
                        braille_interaction: checked,
                      });

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    checked={editFormData.braille_interaction}
                  />
                  <label class="ml-1" for="brailleInteractionCheckDefaultEdit">
                    Interaksi braille
                  </label>
                </Form.Group>
              </>
            )} */}

            {editFormData.type === "Akhir" && (
              <>
                <Form.Group controlId="tipeMateri">
                  {/* Tipe Materi */}
                  <Form.Label>Tipe Akhir</Form.Label>
                  <Form.Control
                    as="select"
                    value={editFormData.final_type}
                    onChange={(e) => {
                      handleChange(e);
                      const value = e.target.value;
                      setEditFormData({
                        ...editFormData,
                        final_type: value,
                      });
                    }}
                    required
                  // disabled={tambahMateri === 'level 1'}
                  >
                    <option value="">Pilih Tipe AKhir</option>
                    {tipeAkhir.map((dataType) => (
                      <option key={dataType.id} value={dataType.description}>
                        {dataType.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                </Form.Group>

                <div className="form-group">
                  <label>Text to Speech</label>
                  <textarea rows="5" type="text" className="form-control" value={editFormData.talkback} name="talkback" onChange={handleChangeEdit} maxLength="1000" minLength="3" />
                  <Form.Control.Feedback type="invalid">Text to Speech harus diisi minimal 3 karakter.</Form.Control.Feedback>
                </div>

                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Konten</Form.Label>
                  <textarea className="form-control" name="content" placeholder="Enter content" rows="5" value={editFormData.content} onChange={handleChangeEdit} pattern=".{5,}" />
                  <Form.Control.Feedback type="invalid">Konten harus diisi minimal 5 karakter.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="tipeMateri">
                  {/* Tipe Materi */}
                  <Form.Label>Ulangi ke Halaman</Form.Label>
                  <Form.Control
                    as="select"
                    value={editFormData.loop_to_page}
                    onChange={(e) => {
                      handleChangeEdit(e);
                      const value = e.target.value;
                      setEditFormData({
                        ...editFormData,
                        loop_to_page: value,
                      });
                    }}
                    required
                  // disabled={tambahMateri === 'level 1'}
                  >
                    <option value="">Pilih Halaman</option>
                    {order &&
                      order
                        .sort((a, b) => a.order - b.order)
                        .map((dataOrder) => (
                          <option key={dataOrder.id} value={dataOrder.id}>
                            {dataOrder.name}
                          </option>
                        ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>
                </Form.Group>
              </>
            )}

            <div className="form-group mb-3 d-flex justify-content-end mt-2">
              <div className="mx-4">
                <Link className="btn btn-light" onClick={handleEditModalClose}>
                  Batal
                </Link>
              </div>
              <div className="">
                <Button variant="primary" type="submit" disabled={!validated}>
                  Simpan
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showLatihan} onHide={handleCloseLatihan} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Tambah Latihan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitLatihan} noValidate validated={validated}>
            <div className="d-flex">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label>
                    Judul<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formDataLatihan.name}
                    name="name"
                    onChange={handleChangeLatihan}
                    maxLength="100"
                  // minLength="1"
                  // required
                  />
                  <Form.Control.Feedback type="invalid">Judul harus diisi minimal 1 karakter.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Jawaban</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formDataLatihan.answer}
                    name="answer"
                    onChange={handleChangeLatihan}
                    maxLength="100"
                  // minLength="1"
                  />
                  <Form.Control.Feedback type="invalid">Jawaban harus diisi minimal 1 karakter.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Text to Speech</label>
                  <textarea rows="5" type="text" className="form-control" value={formDataLatihan.talkback} name="talkback" onChange={handleChangeLatihan} maxLength="1000" minLength="3" />
                  <Form.Control.Feedback type="invalid">Text to Speech harus diisi minimal 3 karakter.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Suara:</label>
                  <input className="ml-2" type="file" accept="audio/*" onChange={handleFileChange} />
                </div>

                {voicePreviewUrl && (
                  <div className="">
                    <audio controls ref={audioRef}>
                      {voicePreviewUrl ? <source src={voicePreviewUrl} type="audio/mpeg" /> : "Tidak ada file"}
                    </audio>
                  </div>
                )}

                <div className="form-group">
                  <label>Gambar</label>
                  <div className="input-group">
                    <button type="button" className="btn btn-primary" onClick={() => fileInputRef.current.click()}>
                      Pilih File
                    </button>
                    <span className="input-group-text">{image ? (image.name.length > 25 ? `${image.name.slice(0, 25)}...` : image.name) : "Tidak ada file yang dipilih..."}</span>
                    <input type="file" ref={fileInputRef} className="d-none" onChange={handleImageChange} accept=".jpg, .jpeg, .png, .gif" isInvalid={image === null && validated} />
                    {image && <Form.Control.Feedback type="invalid">Konten harus diisi.</Form.Control.Feedback>}
                  </div>
                  {imagePreviewUrl && isNewFileSelected && (
                    <>
                      <div className="d-flex flex-column my-1">
                        <Form.Label>Preview</Form.Label>
                        <img src={imagePreviewUrl} alt="Foto Konten Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                      </div>
                      <button type="button" className="btn btn-danger ml-2 my-2" onClick={() => handleDeletePhotoAdditional()}>
                        Hapus
                      </button>
                    </>
                  )}
                </div>

                {/* {imagePreviewUrl && ( */}
                <div className="form-group">
                  <label>Konten</label>
                  <textarea
                    className="form-control"
                    name="content"
                    rows="5"
                    value={formDataLatihan.content}
                    onChange={handleChangeLatihan}
                  // minLength="3"
                  />
                  <Form.Control.Feedback type="invalid">Konten harus diisi minimal 3 karakter.</Form.Control.Feedback>
                </div>
                {/* )} */}

                <div className="form-group">
                  <label>Arti</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formDataLatihan.meaning}
                    name="meaning"
                    onChange={handleChangeLatihan}
                    // required
                    // minLength="3"
                    maxLength="1000"
                  />
                  <Form.Control.Feedback type="invalid">Arti harus diisi minimal 5 karakter.</Form.Control.Feedback>
                </div>

                <Form.Group controlId="posisi">
                  <Form.Label>Letak/Posisi</Form.Label>
                  <Form.Control as="select" onChange={handleChangeOrderLatihan} required>
                    <option value="">Pilih Letak/Posisi</option>
                    <option value="palingAtas">Paling Atas</option>
                    {order && order.length > 1 && <option value="setelahMateri">Setelah Materi</option>}
                    {order && order.length > 0 && <option value="palingBawah">Paling Bawah</option>}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>

                  {showNested && (
                    <Form.Group className="mt-3" controlId="typeSelection">
                      <Form.Label>Pilih Materi</Form.Label>
                      <Form.Control as="select" onChange={handleChangeOrderLatihan}>
                        <option>pilih materi</option>
                        {order &&
                          order
                            .sort((a, b) => a.order - b.order)
                            .map((dataOrder) => (
                              <option key={dataOrder.id} value={dataOrder.id}>
                                {dataOrder.name}
                              </option>
                            ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                </Form.Group>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group mb-3 d-flex mt-2">
            <div className="col-xl-6">
              <Link className="btn btn-light w-100" onClick={handleCloseLatihan}>
                Batal
              </Link>
            </div>
            <div className="col-xl-6">
              <button type="submit" className="btn btn-primary w-100" onClick={handleSubmitLatihan} disabled={!validated}>
                Simpan
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={editLatihanModalShow} onHide={handleEditLatihanModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Ubah Latihan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditLatihanSubmit} noValidate validated={validated}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Judul</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter name"
                value={editLatihanFormData.name}
                onChange={handleChangeEditLatihan}
                maxLength="100"
              // pattern=".{1,}"
              // required
              />
              <Form.Control.Feedback type="invalid">Judul harus diisi minimal 1 karakter.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Jawaban</Form.Label>
              <Form.Control
                type="text"
                name="answer"
                placeholder="Enter answer"
                value={editLatihanFormData.answer}
                onChange={handleChangeEditLatihan}
                maxLength="100"
              // pattern=".{1,}"
              />
              <Form.Control.Feedback type="invalid">Jawaban harus diisi minimal 1 karakter.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Text to Speech</Form.Label>
              <textarea className="form-control" rows="5" type="text" name="talkback" placeholder="Enter Text to Speech" value={editLatihanFormData.talkback} onChange={handleChangeEditLatihan} maxLength="1000" pattern=".{3,}" />
              <Form.Control.Feedback type="invalid">Text to Speech harus diisi minimal 3 karakter.</Form.Control.Feedback>
            </Form.Group>

            <div className="form-group">
              <label>Sound:</label>
              <input className="ml-2" type="file" accept="audio/*" onChange={handleFileChange} />
            </div>

            {voicePreviewUrl && (
              <div className="">
                <audio controls ref={audioRef}>
                  {voicePreviewUrl ? <source src={voicePreviewUrl} type="audio/mpeg" /> : "Tidak ada file"}
                </audio>
              </div>
            )}
            {showButtonDelete && (
              <button type="button" className="btn btn-primary my-2 ml-2" onClick={() => handleDeleteSound()}>
                Hapus Sound
              </button>
            )}

            <div className="form-group">
              <label>Gambar</label>
              <div>
                {!isNewFileSelected && editLatihanFormData && editLatihanFormData.image ? (
                  <div>
                    {editLatihanFormData?.image ? (
                      <img src={`${process.env.REACT_APP_API_BASE_URL}files/${editLatihanFormData.image}/download`} alt="konten" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                    ) : (
                      <p>Tidak Ada Photo</p>
                    )}
                  </div>
                ) : null}
                {imagePreviewUrl && isNewFileSelected && (
                  <>
                    <div className="d-flex flex-column my-1">
                      <Form.Label>Preview</Form.Label>
                      <img src={imagePreviewUrl} alt="Foto Konten Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                    </div>
                  </>
                )}

                <div>
                  <div className="input-group">
                    <button type="button" className="btn btn-primary my-2" onClick={() => fileInputRef.current.click()}>
                      {editLatihanFormData?.image || imagePreviewUrl ? "Ganti File" : "Pilih File"}
                    </button>
                    {(editLatihanFormData?.image || imagePreviewUrl) && (
                      <button type="button" className="btn btn-danger ml-2 my-2" onClick={() => handleDeletePhotoAdditional()}>
                        Hapus
                      </button>
                    )}
                  </div>
                  <input type="file" ref={fileInputRef} className="d-none" onChange={handleImageChange} accept=".jpg, .jpeg, .png, .gif" />
                  <Form.Control.Feedback type="invalid">Silakan pilih file foto konten</Form.Control.Feedback>
                </div>
              </div>
            </div>
            {/* <div className='form-group'>
              <label>Gambar</label>
              <div className='input-group'>
                <button type='button' className="btn btn-primary" onClick={() => fileInputRef.current.click()}>
                  Pilih File
                </button>
                <span className="input-group-text">
                  {image ? image.name.length > 25 ? `${image.name.slice(0, 25)}...` : image.name : "Tidak ada file yang dipilih..."}
                </span>
                <input
                  type='file'
                  ref={fileInputRef}
                  className='d-none'
                  onChange={handleImageChange}
                  accept='.jpg, .jpeg, .png, .gif'
                  isInvalid={image === null && validated}
                />
                {image && (
                  <Form.Control.Feedback type="invalid">
                    Konten harus diisi.
                  </Form.Control.Feedback>
                )}
              </div>
              {imagePreviewUrl && isNewFileSelected && (
                <div className="d-flex flex-column my-1">
                  <Form.Label>
                    Preview
                  </Form.Label>
                  <img
                    src={imagePreviewUrl}
                    alt="Foto Konten Preview"
                    className="img-fluid rounded my-1"
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                </div>
              )}
            </div> */}

            {/* {imagePreviewUrl && ( */}
            <div className="form-group">
              <label>Konten</label>
              <textarea
                className="form-control"
                name="content"
                rows="5"
                value={editLatihanFormData.content}
                onChange={handleChangeEditLatihan}
              // minLength="3"
              />
              <Form.Control.Feedback type="invalid">Konten harus diisi minimal 3 karakter.</Form.Control.Feedback>
            </div>
            {/* )} */}

            <div className="form-group">
              <label>Arti</label>
              <input
                type="text"
                className="form-control"
                value={editLatihanFormData.meaning}
                name="meaning"
                onChange={handleChangeEditLatihan}
                // required
                // minLength="3"
                maxLength="1000"
              />
              <Form.Control.Feedback type="invalid">Arti harus diisi minimal 5 karakter.</Form.Control.Feedback>
            </div>

            <div className="form-group mb-3 d-flex justify-content-end mt-2">
              <div className="mx-4">
                <Link className="btn btn-light w-100" onClick={handleEditLatihanModalClose}>
                  Batal
                </Link>
              </div>
              <div className="">
                <Button variant="primary" type="submit" disabled={!validated}>
                  Simpan
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showLatihanRandom} onHide={handleCloseLatihanRandom} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Tambah Latihan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitLatihanRandom} noValidate validated={validated}>
            <div className="d-flex">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label>
                    Jumlah Soal<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formDataRandom.numberOfQuestions}
                    onChange={(e) => {
                      const { value } = e.target;
                      // Hanya karakter angka
                      const numericValue = value.replace(/\D/g, "");
                      setFormDataRandom((prevState) => ({
                        ...prevState,
                        numberOfQuestions: numericValue,
                      }));

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Jumlah Soal harus diisi.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Judul Awal / Prefix Soal</label>
                  <input type="text" className="form-control" value={formDataRandom.title} name="title" onChange={handleChangeLatihanRandom} maxLength="100" />
                </div>

                <div className="form-group">
                  <label>
                    Soal (pisahkan dengan spasi)<span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" value={formDataRandom.question} name="question" onChange={handleChangeLatihanRandom} maxLength="200" minLength="1" required />
                  <Form.Control.Feedback type="invalid">Soal harus diisi minimal 1 karakter.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>
                    Jawaban (pisahkan dengan spasi)<span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" value={formDataRandom.answer} name="answer" onChange={handleChangeLatihanRandom} maxLength="200" minLength="1" required />
                  <Form.Control.Feedback type="invalid">Jawaban harus diisi minimal 1 karakter.</Form.Control.Feedback>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group mb-3 d-flex mt-2">
            <div className="col-xl-6">
              <Link className="btn btn-light w-100" onClick={handleCloseLatihanRandom}>
                Batal
              </Link>
            </div>
            <div className="col-xl-6">
              <button type="submit" className="btn btn-primary w-100" onClick={handleSubmitLatihanRandom} disabled={!validated}>
                Simpan
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showSimulasi} onHide={handleCloseSimulasi} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Tambah Simulasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitLatihan} noValidate validated={validated}>
            <div className="d-flex">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label>
                    Soal<span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" value={formDataLatihan.name} name="name" onChange={handleChangeLatihan} maxLength="100" minLength="1" required />
                  <Form.Control.Feedback type="invalid">Soal harus diisi minimal 1 karakter.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Jawaban</label>
                  <input type="text" className="form-control" value={formDataLatihan.answer} name="answer" onChange={handleChangeLatihan} maxLength="100" minLength="1" />
                  <Form.Control.Feedback type="invalid">Jawaban harus diisi minimal 1 karakter.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Text to Speech</label>
                  <textarea rows="5" type="text" className="form-control" value={formDataLatihan.talkback} name="talkback" onChange={handleChangeLatihan} maxLength="1000" minLength="3" />
                  <Form.Control.Feedback type="invalid">Text to Speech harus diisi minimal 3 karakter.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Suara:</label>
                  <input className="ml-2" type="file" accept="audio/*" onChange={handleFileChange} />
                </div>

                {voicePreviewUrl && (
                  <div className="">
                    <audio controls ref={audioRef}>
                      {voicePreviewUrl ? <source src={voicePreviewUrl} type="audio/mpeg" /> : "Tidak ada file"}
                    </audio>
                  </div>
                )}

                <Form.Group controlId="posisi">
                  <Form.Label>Letak/Posisi</Form.Label>
                  <Form.Control as="select" onChange={handleChangeOrderLatihan} required>
                    <option value="">Pilih Letak/Posisi</option>
                    <option value="palingAtas">Paling Atas</option>
                    {order && order.length > 1 && <option value="setelahMateri">Setelah Materi</option>}
                    {order && order.length > 0 && <option value="palingBawah">Paling Bawah</option>}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Harus pilih salah satu.</Form.Control.Feedback>

                  {showNested && (
                    <Form.Group className="mt-3" controlId="typeSelection">
                      <Form.Label>Pilih Materi</Form.Label>
                      <Form.Control as="select" onChange={handleChangeOrderLatihan}>
                        <option>Pilih Materi</option>
                        {order &&
                          order
                            .sort((a, b) => a.order - b.order)
                            .map((dataOrder) => (
                              <option key={dataOrder.id} value={dataOrder.id}>
                                {dataOrder.name}
                              </option>
                            ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                </Form.Group>

                {/* <div className="form-group">
                  <label>Sound:</label>
                  <input
                    className="ml-2"
                    type="file"
                    accept="audio/*"
                    onChange={handleFileChange}
                  />
                </div>

                {voicePreviewUrl && (
                  <div className="">
                    <audio controls ref={audioRef}>
                      {voicePreviewUrl ? (
                        <source src={voicePreviewUrl} type="audio/mpeg" />
                      ) : (
                        "Tidak ada file"
                      )}
                    </audio>
                  </div>
                )} */}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group mb-3 d-flex mt-2">
            <div className="col-xl-6">
              <Link className="btn btn-light w-100" onClick={handleCloseSimulasi}>
                Batal
              </Link>
            </div>
            <div className="col-xl-6">
              <button type="submit" className="btn btn-primary w-100" onClick={handleSubmitLatihan} disabled={!validated}>
                Simpan
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={editSimulasiModalShow} onHide={handleEditLatihanModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Ubah Simulasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditLatihanSubmit} noValidate validated={validated}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Judul</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter name"
                value={editLatihanFormData.name}
                onChange={handleChangeEditLatihan}
                maxLength="100"
              // pattern=".{1,}"
              // required
              />
              <Form.Control.Feedback type="invalid">Judul harus diisi minimal 1 karakter.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Jawaban</Form.Label>
              <Form.Control
                type="text"
                name="answer"
                placeholder="Enter answer"
                value={editLatihanFormData.answer}
                onChange={handleChangeEditLatihan}
                maxLength="100"
              // pattern=".{1,}"
              />
              <Form.Control.Feedback type="invalid">Jawaban harus diisi minimal 1 karakter.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Text to Speech</Form.Label>
              <textarea className="form-control" rows="5" type="text" name="talkback" placeholder="Enter Text to Speech" value={editLatihanFormData.talkback} onChange={handleChangeEditLatihan} maxLength="1000" pattern=".{3,}" />
              <Form.Control.Feedback type="invalid">Text to Speech harus diisi minimal 3 karakter.</Form.Control.Feedback>
            </Form.Group>

            <div className="form-group">
              <label>Sound:</label>
              <input className="ml-2" type="file" accept="audio/*" onChange={handleFileChange} />
            </div>

            {voicePreviewUrl && (
              <div className="">
                <audio controls ref={audioRef}>
                  {voicePreviewUrl ? <source src={voicePreviewUrl} type="audio/mpeg" /> : "Tidak ada file"}
                </audio>
              </div>
            )}
            {showButtonDelete && (
              <button type="button" className="btn btn-primary my-2 ml-2" onClick={() => handleDeleteSound()}>
                Hapus Sound
              </button>
            )}

            <div className="form-group mb-3 d-flex justify-content-end mt-2">
              <div className="mx-4">
                <Link className="btn btn-light w-100" onClick={handleEditLatihanModalClose}>
                  Batal
                </Link>
              </div>
              <div className="">
                <Button variant="primary" type="submit" disabled={!validated}>
                  Simpan
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showTest} onHide={handleCloseTest} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Tambah Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitLatihanRandom} noValidate validated={validated}>
            <div className="d-flex">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label>
                    Jumlah Soal<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formDataRandom.numberOfQuestions}
                    onChange={(e) => {
                      const { value } = e.target;
                      // Hanya karakter angka
                      const numericValue = value.replace(/\D/g, "");
                      setFormDataRandom((prevState) => ({
                        ...prevState,
                        numberOfQuestions: numericValue,
                      }));

                      const form = e.currentTarget.form;
                      const isValid = form.checkValidity();
                      setValidated(isValid);
                      if (isValid) {
                        form.classList.remove("was-validated");
                      } else {
                        form.classList.add("was-validated");
                      }
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Jumlah Soal harus diisi.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>Judul Awal / Prefix Soal</label>
                  <input type="text" className="form-control" value={formDataRandom.title} name="title" onChange={handleChangeLatihanRandom} maxLength="100" />
                </div>

                <div className="form-group">
                  <label>
                    Soal (pisahkan dengan spasi)<span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" value={formDataRandom.question} name="question" onChange={handleChangeLatihanRandom} maxLength="200" minLength="1" required />
                  <Form.Control.Feedback type="invalid">Soal harus diisi minimal 1 karakter.</Form.Control.Feedback>
                </div>

                <div className="form-group">
                  <label>
                    Jawaban (pisahkan dengan spasi)<span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" value={formDataRandom.answer} name="answer" onChange={handleChangeLatihanRandom} maxLength="200" minLength="1" required />
                  <Form.Control.Feedback type="invalid">Jawaban harus diisi minimal 1 karakter.</Form.Control.Feedback>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group mb-3 d-flex mt-2">
            <div className="col-xl-6">
              <Link className="btn btn-light w-100" onClick={handleCloseTest}>
                Batal
              </Link>
            </div>
            <div className="col-xl-6">
              <button type="submit" className="btn btn-primary w-100" onClick={handleSubmitLatihanRandom} disabled={!validated}>
                Simpan
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default DaftarMateri;
