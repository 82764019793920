import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import Select from "react-select";
import DataTable, { SortOrder } from "react-data-table-component";

const DaftarFormInbox = () => {
  const location = useLocation();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const { id, flag } = useParams();
  const formRef = useRef(null);
  const fileInputRef = useRef(null);
  const fileSizeLimit = 2 * 1024 * 1024;
  const [file, setFile] = useState(null);
  const [tipePelajar, setTipePelajar] = useState([]);
  const [isNewFileSelected, setIsNewFileSelected] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [validated, setValidated] = useState(false);
  const [sender, setSender] = useState([]);
  const [klasifikasi, setKlasifikasi] = useState([]);
  const [event, setEvent] = useState([]);
  const [link, setLink] = useState([]);
  const [subEvent, setSubEvent] = useState([]);
  const [tipePesan, setTipePesan] = useState([]);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedNameTipePesan, setSelectedNameTipePesan] = useState("");
  const [selectedTipePesan, setSelectedTipePesan] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    sender: "",
    event: "",
    classification: "",
    notification: 0,
    sub_event: "",
    message_type: "",
    link_to: "",
    button_message: "",
    image: "",
    penerima: "",
  });
  const fecthTableData = async (page, perPage, sortField, SortOrder, search, studentType) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: SortOrder,
      search,
      student_type: studentType,
    };
    const res = await GET(`/inbox/pelajar`, params);
    setData(res?.data?.student || []);
    setTotalRows(res?.meta?.total);
  };

  const fetchServerData = () => {
    fecthTableData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedTipePesan);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  const fetchDataTipePelajar = async () => {
    const res = await GET(`master-data/type?type=tipe pelajar`);
    setTipePelajar(res?.data);
  };
  console.log("llll", tipePelajar);

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTipePesan]);

  const fetchData = async () => {
    const res = await GET(`/inbox/${id}`);
    console.log("respon edit", res);
    //const penerimaNames = res?.data?.penerima?.map(p => p.name).join(", ") || "";
    //const penerimaData = res?.data?.penerima?.map(p => ({ id: p.id, name: p.name })) || [];
    const penerimaData = Array.isArray(res?.data?.penerima) ? res?.data?.penerima.map((p) => ({ id: p.id, name: p.name })) : [];
    setFormData({
      title: res?.data?.title,
      content: res?.data?.content,
      sender: res?.data?.sender,
      classification: res?.data?.classification?.description,
      sub_event: res?.data?.sub_event?.id,
      event: res?.data?.sub_event?.event?.id,
      notification: res?.data?.notification,
      message_type: res?.data?.message_type?.description,
      link_to: res?.data?.link_to?.id,
      button_message: res?.data?.button_message,
      image: res?.data?.image,
      penerima: penerimaData,
    });
  };
  const fetchSender = async () => {
    const res = await GET(`master-data/type?type=Sender`);
    setSender(res?.data);
  };
  const fetchKlasifikasi = async () => {
    const res = await GET(`master-data/type?type=Klasifikasi`);
    setKlasifikasi(res?.data);
  };
  const fetchLink = async () => {
    const res = await GET(`master-data/type?type=Link Halaman`);
    setLink(res?.data);
  };
  const fetchEvent = async () => {
    const res = await GET(`master-data/type?type=Event`);
    setEvent(res?.data);
  };
  const fetchSubEvent = async (event) => {
    const res = await GET(`master-data/parent/${event}`);
    setSubEvent(res?.data);
  };
  const fetchTipePesan = async () => {
    const res = await GET(`master-data/type?type=Tipe Pesan`);
    setTipePesan(res?.data);
  };

  useEffect(() => {
    if (flag === "Detail") {
      fetchData();
    }
    if (flag === "Ubah") {
      fetchData();
    }
    fetchDataTipePelajar();
    fetchSender();
    fetchKlasifikasi();
    fetchLink();
    fetchEvent();
    fetchTipePesan();
  }, []);

  useEffect(() => {
    if (formData?.event) {
      fetchSubEvent(formData.event);
    }
  }, [formData.event]);

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "message_type") {
      setFormData((prevData) => ({
        ...prevData,
        link_to: "",
        button_message: "",
        image: "",
      }));
    }

    if (name === "event") {
      formData.sub_event = "";
    }
    const form = e.currentTarget.form;
    const isValid = form.checkValidity();
    setValidated(isValid);
    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleCheckboxEdit = (e) => {
    const checked = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      notification: checked ? 1 : 0,
    }));
  };

  const handleRowSelected = (selectedRows) => {
    const studentIds = selectedRows.selectedRows
      .map((row) => row.student_id) // Pastikan ini field student_id yang benar
      .filter((id) => id); // Menghilangkan id yang null/undefined
    setSelectedStudents(studentIds);
  };

  const columns = [
    {
      name: "Nama Student",
      selector: (row) => row.user_name,
    },
    {
      name: "Nomor Telepon",
      selector: (row) => row.hp_no,
    },
    {
      name: "Tipe Pelajar",
      selector: (row) => row.type_name,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };
  const handleSelectChange = (selectedOptions, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions.map((option) => option.value),
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // File size validation
      if (selectedFile.size > fileSizeLimit) {
        return Swal.fire({
          icon: "error",
          title: `Ukuran file melebihi batas maksimum (2 MB)`,
        });
      }

      // File type validation
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      if (!allowedTypes.includes(selectedFile.type)) {
        return Swal.fire({
          icon: "error",
          title: `File harus dalam format (jpg, jpeg, png, gif)`,
        });
      }

      // Set file states if valid
      setFile(selectedFile);
      setValidated(true);
      setIsNewFileSelected(true); // Set the flag when a new file is selected

      // Generate preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      if (file) {
        const formDataObject = new FormData();
        formDataObject.append("type", "image");
        formDataObject.append("file", file);

        const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });
        formData.image = fileUploadResponse?.file?.id;
      }
      const requestBody = {
        title: formData.title,
        content: formData.content,
        sender: formData.sender,
        classification: formData.classification,
        notification: formData.notification,
        sub_event: formData.sub_event,
        message_type: parseInt(formData.message_type),
        link_to: formData.link_to,
        button_message: formData.button_message,
        image: formData.image,
        student_id: selectedStudents,
        //status: formData.status,
      };

      const res = await POST("/inbox", requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Berhasil membuat Inbox!",
        }).then(() => {
          Swal.close();
          // setFormData({
          //     title: "",
          //     content: "",
          //     sender: "",
          //     classification: "",
          //     notification: "",
          //     sub_event: "",
          //     message_type: "",
          //     link_to: "",
          //     button_message: "",
          //     image: "",
          //     //status: "",
          // });
          router("/daftar-inbox");
        });
      }
    }
    if (selectedStudents.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Tidak ada penerima yang dipilih!",
      });
      return;
    }
  };

  console.log("formData luar", formData);
  const handleDeleteImage = () => {
    setImagePreviewUrl(null); // Clear the preview URL
    setFile(null); // Clear the file
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input field
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    console.log("formData edit", formData);
    const { title, content, sender, classification, notification, sub_event, message_type, link_to, button_message, image, penerima } = formData;
    const studentIds = penerima.map((p) => p.id);
    const requestBody = {
      title,
      content,
      sender,
      classification: Number(classification),
      notification,
      sub_event,
      message_type: Number(message_type),
      link_to,
      button_message,
      image,
      student_id: studentIds,
    };
    console.log("body Edit", requestBody);
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan Perubahan",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      if (file) {
        const formDataObject = new FormData();
        formDataObject.append("type", "image");
        formDataObject.append("file", file);
        const fileUploadResponse = await POST(`/files`, formDataObject, undefined, { "Content-Type": "multipart/form-data" });
        // Update the image in requestBody directly with the uploaded file ID
        requestBody.image = fileUploadResponse?.file?.id;

        // Also update formData for preview purposes
        setFormData((prev) => ({
          ...prev,
          image: { id: fileUploadResponse?.file?.id },
        }));
      }

      const res = await PATCH(`/inbox/${id}`, requestBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Data Config Berhasil diubah!",
        }).then(() => {
          Swal.close();
          fetchData();
          router("/daftar-inbox");
        });
      }
    }
  };

  const handleSelectTipePesan = (e) => {
    setSelectedTipePesan(e.target.getAttribute("value"));
    setSelectedNameTipePesan(e.target.textContent);
  };
  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Inbox`} motherMenu="Daftar Inbox " />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Inbox</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-inbox`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === "Kirim" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Judul Pesan<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="title" value={formData.title} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Isi Pesan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" value={formData.content} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Sender<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sender" value={formData.sender} onChange={handleChangeEdit} required>
                            <option value="">Pilih Sender</option>
                            {Array.isArray(sender) &&
                              sender.map((sende) => (
                                <option key={sende.id} value={sende.order}>
                                  {sende.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Klasifikasi<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="classification" value={formData.classification} onChange={handleChangeEdit} required>
                            <option value="">Pilih Klasifikasi</option>
                            {Array.isArray(klasifikasi) &&
                              klasifikasi.map((klasik) => (
                                <option key={klasik.id} value={klasik.description}>
                                  {klasik.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="event" value={formData.event} onChange={handleChangeEdit} required>
                            <option value="">Pilih Event</option>
                            {Array.isArray(event) &&
                              event.map((vent) => (
                                <option key={vent.id} value={vent.id}>
                                  {vent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Sub Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sub_event" value={formData.sub_event} onChange={handleChangeEdit} required>
                            <option value="">Pilih Sub Event</option>
                            {Array.isArray(subEvent) &&
                              subEvent.map((subvent) => (
                                <option key={subvent.id} value={subvent.id}>
                                  {subvent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <input type="checkbox" checked={formData.notification === 1} onChange={handleCheckboxEdit} />
                          <label className="ml-2">Muncul di Inbox? </label>
                        </div>
                        <div className="form-group">
                          <label>
                            Tipe Pesan<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="message_type" value={formData.message_type} onChange={handleChangeEdit} required>
                            <option value="">Pilih Tipe Pesan</option>
                            {Array.isArray(tipePesan) &&
                              tipePesan.map((tipes) => (
                                <option key={tipePesan.id} value={tipes.description}>
                                  {tipes.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {formData.message_type === "2" && (
                          <div className="form-group">
                            <label>
                              Link To<span className="text-danger">*</span>
                            </label>
                            <select className="form-control" name="link_to" value={formData.link_to} onChange={handleChangeEdit} required>
                              <option value="">Pilih Link</option>
                              {Array.isArray(link) &&
                                link.map((lint) => (
                                  <option key={lint.id} value={lint.id}>
                                    {lint.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                        {formData.message_type === "2" && (
                          <div className="form-group">
                            <label>
                              Button Message<span className="text-danger">*</span>
                            </label>
                            <input className="form-control" name="button_message" value={formData.button_message} onChange={handleChangeEdit} required />
                          </div>
                        )}
                        {formData.message_type === "3" && (
                          <div className="form-group">
                            <label>
                              Gambar <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <button type="button" className="btn btn-primary" onClick={() => fileInputRef.current.click()}>
                                Pilih File
                              </button>
                              <input
                                type="file"
                                ref={fileInputRef}
                                className="d-none"
                                //name='image'
                                accept=".jpg, .jpeg, .png, .gif"
                                onChange={handleFileChange}
                                // isInvalid={file === null}
                                //className="d-none"
                                //placeholder="Upload Gambar"
                                //required
                              />
                              {imagePreviewUrl && isNewFileSelected && (
                                <div className="mt-3">
                                  <div className="d-flex flex-column align-items-center">
                                    <Form.Label className="mb-2">Preview</Form.Label>
                                    <img src={imagePreviewUrl} alt="Gambar Preview" className="img-fluid rounded" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                    <span className="input-group-text">{file ? (file.name.length > 25 ? `${file.name.slice(0, 25)}` : file.name) : "Tidak ada file yang dipilih..."}</span>
                                  </div>
                                </div>
                              )}
                              {imagePreviewUrl && (
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm" // Smaller button
                                  onClick={handleDeleteImage}
                                  style={{
                                    padding: "0.25rem 0.5rem",
                                    fontSize: "0.75rem",
                                    marginLeft: "10px",
                                    width: "80px", // Adjust width to make it compact
                                  }}
                                >
                                  Hapus
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <Fragment>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <div className="d-flex justify-content-between mt-4 ">
                              <div className="d-flex flex-wrap px-4">
                                <Dropdown className="mr-4">
                                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                                    {selectedTipePesan?.length > 0 ? selectedNameTipePesan : "Semua Tipe Pelajar"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item key="all" value="" onClick={handleSelectTipePesan}>
                                      Semua Tipe Pelajar
                                    </Dropdown.Item>
                                    {Array.isArray(tipePelajar) &&
                                      tipePelajar.map((pesantipe) => (
                                        <Dropdown.Item key={pesantipe.id} value={pesantipe.id} onClick={handleSelectTipePesan}>
                                          {pesantipe.name}
                                        </Dropdown.Item>
                                      ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="d-flex align-self-baseline px-4">
                                <div className="input-group search-area d-xl-inline-flex">
                                  <input type="text" className="form-control" placeholder="Cari Pesan" value={searchTerm} onChange={handleSearchChange} />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <Link to="#">
                                        <i className="flaticon-381-search-2" />
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-inbox-config`, { state: { from: 'Tambah' } })}>
                                                        Tambah Pesan
                                                        </button> */}
                            </div>
                            <Card.Body>
                              {data && data.length > 0 ? (
                                <DataTable
                                  columns={columns}
                                  data={data}
                                  selectableRows
                                  onSelectedRowsChange={handleRowSelected}
                                  customStyles={customStyles}
                                  pagination
                                  paginationServer
                                  paginationRowsPerPageOptions={[10, 25, 50]}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                  paginationPerPage={perPage}
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerPageChange}
                                  onSort={handleSort}
                                />
                              ) : (
                                <p>Tidak ada data</p>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Fragment>

                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-inbox`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100">
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Ubah" ? (
                  <form className="form-valide" ref={formRef} onSubmit={handleEditSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Judul Pesan<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="title" value={formData.title} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Isi Pesan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" value={formData.content} onChange={handleChangeEdit} required />
                        </div>
                        <div className="form-group">
                          <label>
                            Sender<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="sender" value={formData.sender} onChange={handleChangeEdit} required disabled />
                        </div>
                        <div className="form-group">
                          <label>
                            Klasifikasi<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="classification" value={formData.classification} onChange={handleChangeEdit} required>
                            <option value="">Pilih Klasifikasi</option>
                            {Array.isArray(klasifikasi) &&
                              klasifikasi.map((klasik) => (
                                <option key={klasik.id} value={klasik.description}>
                                  {klasik.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="event" onChange={handleChangeEdit} value={formData.event} required>
                            <option value="">Pilih Event</option>
                            {Array.isArray(event) &&
                              event.map((vent) => (
                                <option key={vent.id} value={vent.id}>
                                  {vent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Sub Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sub_event" onChange={handleChangeEdit} value={formData.sub_event} required>
                            <option value="">Pilih Sub Event</option>
                            {Array.isArray(subEvent) &&
                              subEvent.map((subvent) => (
                                <option key={subvent.id} value={subvent.id}>
                                  {subvent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <input type="checkbox" checked={formData.notification === 1} onChange={handleCheckboxEdit} />
                          <label className="ml-2">Muncul di Inbox? </label>
                        </div>
                        {/* <div className="form-group">
                                                    <label>Syarat<span className="text-danger">*</span></label>
                                                    <input
                                                    className="form-control"
                                                    name="syarat"
                                                    required
                                                    />
                                                </div> */}
                        <div className="form-group">
                          <label>
                            Tipe Pesan<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="message_type" value={formData.message_type} onChange={handleChangeEdit} required>
                            <option value="">Pilih Tipe Pesan</option>
                            {Array.isArray(tipePesan) &&
                              tipePesan.map((tipes) => (
                                <option key={tipes.id} value={tipes.description}>
                                  {tipes.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {formData.message_type === "2" && (
                          <div className="form-group">
                            <label>
                              Link To<span className="text-danger">*</span>
                            </label>
                            <select className="form-control" name="link_to" value={formData.link_to} onChange={handleChangeEdit} required>
                              <option value="">Pilih Link</option>
                              {Array.isArray(link) &&
                                link.map((lint) => (
                                  <option key={lint.id} value={lint.id}>
                                    {lint.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                        {formData.message_type === "2" && (
                          <div className="form-group">
                            <label>
                              Button Message<span className="text-danger">*</span>
                            </label>
                            <input className="form-control" name="button_message" value={formData.button_message} onChange={handleChangeEdit} required />
                          </div>
                        )}
                        {formData.message_type === "3" && (
                          <div className="form-group">
                            <label>
                              Gambar <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <div>
                                <div className="input-group">
                                  <button type="button" className="btn btn-primary my-2" onClick={() => fileInputRef.current.click()}>
                                    Ganti Foto
                                  </button>
                                </div>
                                <input type="file" ref={fileInputRef} className="d-none" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .gif" />
                              </div>
                              {imagePreviewUrl && isNewFileSelected && (
                                <div>
                                  <Form.Label>Preview</Form.Label>
                                  <div className="d-flex flex-column my-1">
                                    <img src={imagePreviewUrl} alt="Gambar Preview" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                  </div>
                                </div>
                              )}
                              <div>
                                {id && !isNewFileSelected && formData && formData?.image ? (
                                  <div>
                                    {formData?.image ? (
                                      <img src={`${process.env.REACT_APP_API_BASE_URL}files/${formData.image.id}/download`} alt="image" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                    ) : (
                                      <p>Tidak ada foto gambar</p>
                                    )}
                                  </div>
                                ) : null}
                                {imagePreviewUrl && (
                                  <button
                                    type="button"
                                    className="btn btn-danger" // Smaller button
                                    onClick={handleDeleteImage}
                                  >
                                    Hapus
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="form-group">
                          <label>
                            Penerima<span className="text-danger">*</span>
                          </label>
                          {/* <input
                                                    className="form-control"
                                                    name="penerima"
                                                    onChange={handleChangeEdit}
                                                    value={formData.penerima}
                                                    required
                                                    /> */}
                          {/* <div>
                                                        {Array.isArray(formData.penerima) && formData.penerima.length > 0 ? (
                                                            formData.penerima.map((penerima) => (
                                                                <div key={penerima.id}>
                                                                    {penerima.name}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p>Tidak ada penerima.</p>
                                                        )}
                                                    </div> */}
                          <input
                            className="form-control"
                            name="penerima"
                            value={Array.isArray(formData.penerima) ? formData.penerima.map((p) => p.name).join(", ") : ""}
                            // value={formData.penerima.map(p => p.name).join(', ')}
                            //value={formData.penerima} // Set value dari state
                            onChange={handleChangeEdit} // Handle perubahan pada input
                            required
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-inbox`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100">
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Detail" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            Judul Pesan<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="title" value={formData.title} onChange={handleChangeEdit} disabled required />
                        </div>
                        <div className="form-group">
                          <label>
                            Isi Pesan<span className="text-danger">*</span>
                          </label>
                          <textarea name="content" className="form-control" value={formData.content} onChange={handleChangeEdit} disabled required />
                        </div>
                        <div className="form-group">
                          <label>
                            Sender<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="sender" value={formData.sender} onChange={handleChangeEdit} disabled required />
                        </div>
                        <div className="form-group">
                          <label>
                            Klasifikasi<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="classification" value={formData.classification} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Klasifikasi</option>
                            {Array.isArray(klasifikasi) &&
                              klasifikasi.map((klasik) => (
                                <option key={klasik.id} value={klasik.description}>
                                  {klasik.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="event" value={formData.event} onChange={handleChangeEdit} disabled required>
                            <option value=""> Pilih Event</option>
                            {Array.isArray(event) &&
                              event.map((vent) => (
                                <option key={vent.id} value={vent.id}>
                                  {vent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Sub Event<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="sub_event" value={formData.sub_event} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Sub Event</option>
                            {Array.isArray(subEvent) &&
                              subEvent.map((subvent) => (
                                <option key={subvent.id} value={subvent.id}>
                                  {subvent.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <input type="checkbox" checked={formData.notification === 1} onChange={handleCheckboxEdit} disabled />
                          <label className="ml-2">Muncul di Inbox? </label>
                        </div>
                        {/* <div className="form-group">
                                                    <label>Syarat<span className="text-danger">*</span></label>
                                                    <input
                                                    className="form-control"
                                                    name="syarat"
                                                    required
                                                    />
                                                </div> */}
                        <div className="form-group">
                          <label>
                            Tipe Pesan<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="message_type" value={formData.message_type} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Tipe Pesan</option>
                            {Array.isArray(tipePesan) &&
                              tipePesan.map((tipes) => (
                                <option key={tipes.id} value={tipes.description}>
                                  {tipes.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Link To<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" name="link_to" value={formData.link_to} onChange={handleChangeEdit} disabled required>
                            <option value="">Pilih Link</option>
                            {Array.isArray(link) &&
                              link.map((lint) => (
                                <option key={lint.id} value={lint.id}>
                                  {lint.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Button Message<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="button_mesaage" value={formData.button_message} onChange={handleChangeEdit} disabled required />
                        </div>
                        <div className="form-group">
                          <label>
                            Gambar <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            {!isNewFileSelected && formData && formData?.image ? (
                              <div>
                                {formData?.image ? (
                                  <img src={`${process.env.REACT_APP_API_BASE_URL}files/${formData.image?.id}/download`} alt="image" className="img-fluid rounded my-1" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                                ) : (
                                  <p>Tidak ada foto gambar</p>
                                )}
                              </div>
                            ) : null}

                            <input
                              className="form-control"
                              value={formData.image?.id}
                              name="image"
                              onChange={handleFileChange}
                              //type="file"
                              //className="d-none"
                              //placeholder="Upload Gambar"
                              //required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            Penerima<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="penerima" value={Array.isArray(formData.penerima) ? formData.penerima.map((p) => p.name).join(", ") : ""} onChange={handleChangeEdit} disabled required />
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DaftarFormInbox;
