import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from "../../../services/AxiosService";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleCheck, faCheckCircle, faClose } from "@fortawesome/free-solid-svg-icons";

const DaftarAlokasiPengeluaranInfaq = () => {
  const navigate = useNavigate();
  const [dataAlokasiInfaq, setDataAloaksiInfaq] = useState([]);
  const [dataPengeluaranInfaq, setDataPengeluaranInfaq] = useState([]);
  const [typeBuku, setTypeBuku] = useState([]);
  const [period, setPeriod] = useState([]);
  //server side
  const [role, setRole] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedNamePeriod, setSelectedNamePeriod] = useState("");

  const [selectedTypeBuku, setSelectedTypeBuku] = useState("");
  const [selectedNameTypeBuku, setSelectedNameTypeBuku] = useState("");

  const [selectedStatusBuku, setSelectedStatusBuku] = useState("");
  const [selectedNameStatusBuku, setSelectedNameStatusBuku] = useState("");
  // validate
  const permissions = JSON.parse(localStorage.getItem("INFAQ_ALOKASI-PENGELUARAN") || "[]");
  const canModify = permissions.some((permission) => ["CREATE", "UPDATE", "DELETE", "LOCK", "VIEW", "APPROVE"].includes(permission));

  // GET ACCESS_ROLE FROM LOCAL STORAGE
  useEffect(() => {
    // Retrieve the access_role value from localStorage
    const userRole = localStorage.getItem("accessRole");
    setRole(userRole);
  }, []);

  const fetchData = async (page, perPage, sortField, sortOrder, search, selectedPeriod, selectedTypeBuku) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      periodeId: selectedPeriod,
      tipeBuku: selectedTypeBuku,
    };
    const res = await GET(`/alokasi-pengeluaran`, params);
    const transformedData = [
      {
        "Saldo Awal Periode": `Rp ${res?.data?.saldo_awal_periode.toLocaleString("id-ID") || 0}`,
        "Pemasukan Periode": `Rp ${res?.data?.pemasukan_periode.toLocaleString("id-ID") || 0}`,
        "Pengeluaran Periode": `Rp ${res?.data?.pengeluaran_periode.toLocaleString("id-ID") || 0}`,
        "Saldo Akhir Periode": `Rp ${res?.data?.saldo_akhir_periode.toLocaleString("id-ID") || 0}`,
        "Menunggu Persetujuan": `Rp ${res?.data?.menunggu_persetujuan.toLocaleString("id-ID") || 0}`,
        "Tipe donasi": res?.data?.tipe_donasi,
        "Start date": res?.data?.start_date,
        "End date": res?.data?.end_date,
      },
    ];
    setDataAloaksiInfaq(transformedData);
    setDataPengeluaranInfaq(res?.pengeluaran);
    setTotalRows(res?.pagination?.total);
  };

  const fetchPeriode = async () => {
    const res = await GET(`/master-data/period`);
    setPeriod(res?.data);
  };

  const getCurrentPeriodName = () => {
    const currentDate = new Date();
    // Find the period where the current date falls within the start_date and end_date
    const currentPeriod = period.find((period) => {
      const startDate = new Date(period.start_date);
      const endDate = new Date(period.end_date);
      return currentDate >= startDate && currentDate <= endDate;
    });
    // Return the name if a period is found, or a fallback message
    return currentPeriod ? currentPeriod.name : "No period found for the current date";
  };
  // Usage
  const currentPeriodName = getCurrentPeriodName();
  console.log("Current Period Name", currentPeriodName);

  const fetchTypeBuku = async () => {
    const res = await GET(`book-package`);
    setTypeBuku(res?.data);
  };

  const handleSelectTipeBuku = (e) => {
    setSelectedTypeBuku(e.target.getAttribute("value"));
    setSelectedNameTypeBuku(e.target.textContent);
  };

  const handleSelectStatusBuku = (e) => {
    setSelectedPeriod(e.target.getAttribute("value"));
    setSelectedNamePeriod(e.target.textContent);
  };

  console.log("namess", selectedNamePeriod);

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedPeriod, selectedTypeBuku);
  };

  useEffect(() => {
    fetchServerData();
    fetchTypeBuku();
    fetchPeriode();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTypeBuku, selectedStatusBuku, selectedPeriod]);

  // Set default selected period on component load
  useEffect(() => {
    if (period?.length > 0 && !selectedPeriod) {
      const defaultPeriod = period.find((item) => item.status === 1);
      if (defaultPeriod) {
        setSelectedPeriod(defaultPeriod.id);
        setSelectedNamePeriod(defaultPeriod.name);
      }
    }
  }, [period, selectedPeriod]); // Add selectedPeriod as a dependency

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  const handleAccepted = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const res = await PATCH(`alokasi-pengeluaran/approove/${id}`);
      Swal.close(); // Close loading state

      // Handle success response
      Swal.fire({
        icon: "success",
        title: res.message, // Show the success message from the response
      }).then(() => {
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedPeriod);
      });
    } catch (error) {
      Swal.close(); // Close loading state

      // Handle error response
      if (error.response) {
        // Check if the error has a response (e.g., status 401, 400, etc.)
        const errorMessage = error.response.data.message || "An error occurred";

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage, // Show error message from the response
        });
      } else {
        // Handle case where there is no response (network issues, etc.)
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "Please check your connection and try again.",
        });
      }
    }
  };

  const handleRejected = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const res = await PATCH(`alokasi-pengeluaran/decline/${id}`);
      Swal.close(); // Close loading state

      // Handle success response
      Swal.fire({
        icon: "success",
        title: res.message, // Show the success message from the response
      }).then(() => {
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedPeriod);
      });
    } catch (error) {
      Swal.close(); // Close loading state

      // Handle error response
      if (error.response) {
        // Check if the error has a response (e.g., status 401, 400, etc.)
        const errorMessage = error.response.data.message || "An error occurred";

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage, // Show error message from the response
        });
      } else {
        // Handle case where there is no response (network issues, etc.)
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "Please check your connection and try again.",
        });
      }
    }
  };

  const columnss = [
    {
      name: "Saldo Awal Periodeee",
      selector: (row) => row.saldo_awal_periode,
    },
    {
      name: "Pemasukan Period",
      selector: (row) => row.pemasukan_periode,
      cell: (row) => (
        <button
          onClick={() =>
            navigate("/daftar-donasi", {
              state: {
                type: row.type,
                start_date: row.start_date,
              },
            })
          }
          style={{ color: "blue", textDecoration: "underline", cursor: "pointer", background: "none", border: "none" }}
        >
          {row.pemasukan_periode}
        </button>
      ),
    },
    {
      name: "Pengeluaran Period",
      selector: (row) => row.pengeluaran_periode,
    },
    {
      name: "Saldo Akhir Periode",
      selector: (row) => row.saldo_akhir_periode,
    },
    {
      name: "Menunggu Persetujuan",
      selector: (row) => row.menunggu_persetujuan,
    },
  ];
  const columns = [
    {
      name: "Saldo Awal Periode",
      selector: (row) => row["Saldo Awal Periode"],
    },
    {
      name: "Pemasukan Periode",
      selector: (row) => row["Pemasukan Periode"],
      cell: (row) => (
        <button
          onClick={() =>
            navigate("/daftar-donasi", {
              state: {
                tipe: row["Tipe donasi"], // Ensure `row.type` exists in the data
                start: row["Start date"], // Adjusted to match column value
                end: row["End date"],
              },
            })
          }
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
            background: "none",
            border: "none",
          }}
        >
          {row["Pemasukan Periode"]}
        </button>
      ),
    },
    {
      name: "Pengeluaran Periode",
      selector: (row) => row["Pengeluaran Periode"],
    },
    {
      name: "Saldo Akhir Periode",
      selector: (row) => row["Saldo Akhir Periode"],
    },
    {
      name: "Menunggu Persetujuan",
      selector: (row) => row["Menunggu Persetujuan"],
    },
  ];
  const columnsPengeluaranInfaq = [
    {
      name: "Waktu Alokasi",
      sortable: true,
      selector: (row) => row.waktu_alokasi,
      cell: (row) => {
        const date = new Date(row.waktu_alokasi);

        // Extract date and time components
        const day = date.toLocaleString("id-ID", { day: "2-digit" });
        const month = date.toLocaleString("id-ID", { month: "long" });
        const year = date.toLocaleString("id-ID", { year: "numeric" });
        const hours = date.toLocaleString("id-ID", { hour: "2-digit", hour12: false });
        const minutes = date.getMinutes().toString().padStart(2, "0"); // Manually pad minutes

        // Construct the desired format manually
        return `${day} ${month} ${year} ${hours}:${minutes}`;
      },
    },
    {
      name: "Waktu Persetujuan",
      selector: (row) => row.waktu_persetujuan,
      cell: (row) => {
        if (!row.waktu_persetujuan) {
          return "-"; // Return a dash if the value is null or undefined
        }
        const date = new Date(row.waktu_persetujuan);

        // Extract date and time components
        const day = date.toLocaleString("id-ID", { day: "2-digit" });
        const month = date.toLocaleString("id-ID", { month: "long" });
        const year = date.toLocaleString("id-ID", { year: "numeric" });
        const hours = date.toLocaleString("id-ID", { hour: "2-digit", hour12: false });
        const minutes = date.getMinutes().toString().padStart(2, "0"); // Manually pad minutes

        // Construct the desired format manually
        return `${day} ${month} ${year} ${hours}:${minutes}`;
      },
    },
    {
      name: "Buku",
      selector: (row) => row.book_package_name,
    },
    {
      name: "Harga",
      selector: (row) => row.harga,
      cell: (row) => `Rp ${new Intl.NumberFormat("id-ID").format(row.harga)}`,
    },
    {
      name: "Jumlah",
      selector: (row) => row.jumlah,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      cell: (row) => `Rp ${new Intl.NumberFormat("id-ID").format(row.total)}`,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      //   width: "180px",
      cell: (row) => {
        let displayText;
        if (row.status === "Diterima") {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Diterima
            </Badge>
          );
        } else if (row.status === "Ditolak") {
          displayText = (
            <Badge as="a" variant="outline-danger badge-rounded">
              Ditolak
            </Badge>
          );
        } else if (row.status === "Pengajuan") {
          displayText = (
            <Badge as="a" variant="outline-info badge-rounded">
              Pengajuan
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      },
    },
    {
      name: "Aksi",
      selector: (row) => row.id,
      width: "180px",
      cell: (row) => (
        <div>
          {row.status === "Pengajuan" && permissions.includes("APPROVE") && (
            <>
              <Link
                className="btn btn-success shadow btn-xs sharp mx-1"
                onClick={() => handleAccepted(row.id)}
                title="Diterima"
              >
                <FontAwesomeIcon icon={faCheckCircle} />
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp"
                onClick={() => handleRejected(row.id)}
                title="Ditolak"
              >
                <FontAwesomeIcon icon={faClose} />
              </Link>
            </>
          )}
          <button
            className="btn btn-info shadow btn-xs sharp mx-1"
            title="Detail"
            onClick={() =>
              navigate(`/${row.id}/tambah-pengeluaran/Detail`, {
                state: { saldo: dataAlokasiInfaq[0] },
              })
            }
          >
            <i className="fa fa-eye"></i>
          </button>
        </div>
      ),
    },

  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  return (
    <Fragment>
      {/* ALOKASI INFAQ */}
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Alokasi Infaq</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                    width="200px"
                  >
                    {selectedPeriod?.length > 0 ? selectedNamePeriod : "Semua Periode"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Array.isArray(period) &&
                      period.map((periodStatus) => (
                        <Dropdown.Item key={periodStatus.id} value={periodStatus.id} onClick={handleSelectStatusBuku}>
                          {periodStatus.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4" hidden>
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                      // borderRadius: "0",
                    }}
                    id="dropdown-basic"
                    width="200px"
                  >
                    {selectedTypeBuku?.length > 0 ? selectedNameTypeBuku : "Semua Tipe Buku"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipeBuku}>
                      Semua Buku
                    </Dropdown.Item>
                    {Array.isArray(typeBuku) &&
                      typeBuku.map((bukuType) => (
                        <Dropdown.Item key={bukuType.id} value={bukuType.name} onClick={handleSelectTipeBuku}>
                          {bukuType.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/* <div className="d-flex align-self-baseline px-4">
                    <div className="input-group search-area d-xl-inline-flex" hidden>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Cari Donasi"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text">
                            <Link to="#">
                                <i className="flaticon-381-search-2" />
                            </Link>
                            </span>
                        </div>
                    </div>
                </div> */}
            </div>
            <Card.Body>{dataAlokasiInfaq && dataAlokasiInfaq.length > 0 ? <DataTable columns={columns} data={dataAlokasiInfaq} customStyles={customStyles} /> : <p>Tidak ada data</p>}</Card.Body>
          </Card>
        </Col>
      </Row>

      {/* PENGELUARAN INFAQ */}
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Pengeluaran Infaq</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                      // borderRadius: "0",
                    }}
                    id="dropdown-basic"
                    width="200px"
                  >
                    {selectedTypeBuku?.length > 0 ? selectedNameTypeBuku : "Semua Tipe Buku"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipeBuku}>
                      Semua Buku
                    </Dropdown.Item>
                    {Array.isArray(typeBuku) &&
                      typeBuku.map((bukuType) => (
                        <Dropdown.Item key={bukuType.id} value={bukuType.name} onClick={handleSelectTipeBuku}>
                          {bukuType.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-self-baseline px-4">
                {permissions.includes("CREATE") && (
                  <button className="btn btn-primary mr-2" disabled={currentPeriodName !== selectedNamePeriod} onClick={() => navigate(`/tambah-pengeluaran/Tambah`, { state: { saldo: dataAlokasiInfaq[0] } })}>
                    Tambah Pengeluaran
                  </button>
                )}
                {/* <Button onClick={() => navigate(`/daftar-alokasi-pengeluaran-infaq`, { state: { from: "Tambah" } })}>Alokasi dan Pengeluaran Infaq</Button> */}
                {/* <div className="input-group search-area d-xl-inline-flex">
                  <input type="text" className="form-control" placeholder="Cari Donasi" value={searchTerm} onChange={handleSearchChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            <Card.Body>
              {dataPengeluaranInfaq && dataPengeluaranInfaq.length > 0 ? (
                <DataTable
                  columns={columnsPengeluaranInfaq}
                  data={dataPengeluaranInfaq}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DaftarAlokasiPengeluaranInfaq;
