import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from '../../../services/AxiosService';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { Dropdown } from 'react-bootstrap';

const DaftarTemanBelajarRekomendasi = () => {
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  // validate
  const permissions = JSON.parse(localStorage.getItem('STUDENT_RECOMMENDATION') || '[]');
  const canModify = permissions.some(permission => ['CREATE', 'UPDATE', 'DELETE', 'LOCK', 'VIEW'].includes(permission));

  const statusOptions = [
    { label: 'Semua Status', value: '' },
    { label: 'Sudah Bergabung', value: 1 },
    { label: 'Belum Bergabung', value: 0 },
  ];

  const fetchData = async (page, perPage, sortField, sortOrder, search, dateFrom, dateTo, selectedStatus) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      start_date: dateFrom,
      end_date: dateTo,
      status: selectedStatus === '' ? undefined : selectedStatus,
    };
    const res = await GET(`/student-recommendation`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, dateFrom, dateTo, selectedStatus);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    // setSortField(column.field);
    if (column.field === 'jml_perekomen') {
      setSortField('jml_perekomen');
    } else if (column.field === 'jml_perekomen_tunanetra') {
      setSortField('jml_perekomen_tunanetra');
    } else {
      setSortField(column.field);
    }
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, dateFrom, dateTo, selectedStatus]);

  const columns = [
    {
      name: 'Nama Teman',
      //sortable: true,
      field: "name",
      selector: row => row.recommendation_name,
    },
    {
      name: 'No HP Teman',
      //sortable: true,
      field: "no_hp",
      selector: row => row.recommendation_hp_no,
      cell: (row) => (
        <div style={{ position: "center" }}>{row.recommendation_hp_no}</div>
      )
    },
    {
      name: 'Status',
      //sortable: true,
      field: "status",
      selector: row => row.status,
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant='outline-success budge-rounded'>
              Sudah Bergabung
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant='outline-primary badge-rounded'>
              Belum Bergabung
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
        // <div style={{ position: "center" }}>{row.status}</div>
      }
    },
    {
      //name: 'Nama Pelajar (yang merekomendasikan)',
      name: 'Jumlah yang merekomendasikan',
      sortable: true,
      field: "jml_perekomen",
      selector: row => row.jml_perekomen,
      cell: (row) => (
        <div style={{ textAlign: "center", width: "100%" }}>{row.jml_perekomen}</div>
      )
    },
    {
      name: 'Jumlah yang merekomendasikan (Tuna Netra)',
      sortable: true,
      field: "jml_perekomen_tunanetra",
      selector: row => row.jml_perekomen_tunanetra,
      cell: (row) => (
        <div style={{ textAlign: "center", width: "100%" }}>{row.jml_perekomen_tunanetra}</div>
      )
      //selector: row => row.user_no_hp,
    },
    ...(canModify
      ? [{
        name: 'Aksi',
        selector: row => row.id,
        width: "130px",
        cell: (row) => (
          <div>
            <button
              className="btn btn-info shadow btn-xs sharp mx-1"
              title="Detail"
              onClick={() => navigate(`/${row.id}/form-teman-belajar/Detail`)}
            >
              <i className="fa fa-eye"></i>
            </button>

            {permissions.includes('DELETE') && (
              <Link
                className="btn btn-danger shadow btn-xs sharp"
                title="Delete"
                onClick={() => handleDeleteClick(row.id)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            )}
          </div>
        ),
      }]
      : [])
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
        textAlign: "center", // tambahkan ini untuk menempatkan semua konten baris di tengah
      },
    },
  };

  const handleDateFromChange = (date) => {
    const formattedDate = date?.toLocaleDateString('en-CA');
    setDateFrom(formattedDate);
  };
  const handleDateToChange = (date) => {
    const formattedDate = date?.toLocaleDateString('en-CA');
    setDateTo(formattedDate);
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/student-recommendation/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus teman belajar!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const DatePickerWithClearIcon = ({ value, onChange, placeholderText }) => {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <DatePicker
          className='form-control'
          value={value || ''}
          onChange={onChange}
          dateFormat="yyyy-MM-dd"
          placeholderText={placeholderText}
          style={{ maxHeight: "45px", paddingRight: '30px' }}
        />
        {value && (
          <i
            className="fa fa-times"
            onClick={() => onChange(null)}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              color: '#aaa'
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Teman Belajar Rekomendasi</Card.Title>
            </Card.Header>
            <div className="d-flex flex-wrap px-4">
              <Dropdown className="mr-4">
                <Dropdown.Toggle
                  style={{
                    background: "transparent",
                    borderColor: "grey",
                    color: "grey",
                  }}
                  id="dropdown-status"
                >
                  {selectedStatus ? statusOptions.find(option => option.value === selectedStatus)?.label : "Semua Status"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {statusOptions.map((option) => (
                    <Dropdown.Item
                      key={option.value}
                      onClick={() => {
                        setSelectedStatus(option.value);
                        setCurrentPage(1);
                        fetchServerData();
                      }}
                    >
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex mt-4">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex px-4">
                <div className="d-flex">
                  <DatePickerWithClearIcon value={dateFrom} onChange={handleDateFromChange} placeholderText='Waktu Merekomendasikan (from)' />
                  <DatePickerWithClearIcon value={dateTo} onChange={handleDateToChange} placeholderText='Waktu Merekomendasikan (to)' />
                </div>
              </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Data"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarTemanBelajarRekomendasi