import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { Badge } from "react-bootstrap";

const FormPesananBuku = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const router = useNavigate();
  const { dataParams } = location.state || { dataParams: [] };
  const [statusPesanan, setStatusPesanan] = useState([]);
  const [ekspedisi, setEkspedisi] = useState([]);
  const [statusPesananId, setStatusPesananId] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [touched, setTouched] = useState(false);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    status_id: "",
    print_name: "",
    print_order_no: "",
    print_order_date: "",
    estimated_time_arrival: "",
    expedition_id: "",
    receipt_no: "",
  });
  const [data, setData] = useState([]);
  const { status } = location.state || {};

  console.log("haii", status);
  console.log("datass", data);

  // const fetchData = async () => {
  //   const res = await GET(`/book-ordering`);

  //   const selectedIds = id.split(",");
  //   const filtered = res?.data?.filter((row) => selectedIds.includes(row.id));
  //   setFilteredData(filtered);
  // };
  useEffect(() => {
    console.log("Data Params", dataParams);
    const selectedIds = id.split(",");
    const filtered = dataParams?.filter((row) => selectedIds.includes(row.id));
    console.log("filtered", filtered);
    setFilteredData(filtered);
  }, [dataParams]);

  console.log("filtered", filteredData);

  const fetchDataStatusPesanan = async () => {
    const res = await GET(`master-data/type?type=status pesanan buku`);
    setStatusPesanan(res?.data);
  };

  const getFilteredStatusPesanan = (statusPesanan, status, currentStatus) => {
    if (!Array.isArray(statusPesanan) || !Array.isArray(status)) {
      return [];
    }

    const filterMapping = {
      "Pesanan Terkonfirmasi": ["Proses cetak"],
      "Proses cetak": ["Dalam pengiriman"],
      "Dalam pengiriman": ["Sudah dimiliki"],
      "Sudah dimiliki": ["Dalam pengiriman"],
    };

    const allowedStatuses = filterMapping[currentStatus] || [];
    return allowedStatuses.length > 0 ? statusPesanan.filter((item) => allowedStatuses.includes(item.name)) : statusPesanan;
  };
  const filteredStatusPesanan = getFilteredStatusPesanan(statusPesanan, status, status?.[0]);

  const fetchDataEskpedisi = async () => {
    const res = await GET(`master-data/type?type=Ekspedisi`);
    setEkspedisi(res?.data);
  };

  const fetchDataStatusPesananId = async () => {
    const res = await GET(`master-data/${formData.status_id}`);
    setStatusPesananId(res?.data);
  };

  const fetchDataById = async () => {
    // Utility function to format date and time
    const formatDateTime = (dateString) => {
      if (!dateString) return "-"; // Handle null or undefined dates
      const utcDate = new Date(dateString);
      const formattedDate = utcDate.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        // timeZone: "UTC", // Force UTC to prevent timezone conversion
      });
      const formattedTime = utcDate
        .toLocaleTimeString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false, // Ensures 24-hour format
          // timeZone: "UTC", // Force UTC to prevent timezone conversion
        })
        .replace(":", "."); // Replace ":" with "."

      return `${formattedDate} ${formattedTime}`;
    };

    try {
      const res = await GET(`/book-ordering/${id}`);
      const detail = res?.data?.book_detail;

      // Format `created_at` and `paid_at` using the utility function
      const formattedCreatedAt = formatDateTime(res?.data?.created_at);
      const formattedPaidAt = formatDateTime(res?.data?.paid_at);

      // Set the state with formatted data
      setData({
        ...res?.data,
        book_detail: Array.isArray(detail) ? detail : [detail], // Ensure book_detail is an array
        created_at: formattedCreatedAt,
        paid_at: formattedPaidAt,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function currencyFormat(num) {
    num = parseFloat(num);
    return num
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(/\.00$/, "");
  }
  const columns = [
    {
      name: "No Pesanan",
      selector: (row) => row.no,
    },
    {
      name: "Judul",
      selector: (row) => row.judul,
      cell: (row) => (
        <div>
          {row.book_detail?.map((book, index) => (
            <span key={book.id}>
              {book.book_name}
              {index < row.book_detail?.length - 1 ? ", " : ""}
            </span>
          ))}
        </div>
      ),
    },
    {
      name: "Nama Pelajar",
      selector: (row) => row.user?.name,
    },
    {
      name: "Tipe Pelajar",
      selector: (row) => row.students?.type_user,
      cell: (row) => <div>{row.students[0]?.type_user}</div>,
    },
    {
      name: "Tipe Pesanan",
      selector: (row) => row.type_orders?.name,
    },
    {
      name: "Total yang Dibayar (Rp)",
      selector: (row) => currencyFormat(row.total_payment),
    },
    {
      name: "Cara Pembayaran",
      selector: (row) => row.payment?.name_payment,
    },
    {
      name: "Waktu Bayar",
      selector: (row) => {
        if (!row.paid_at) {
          return "-"; // Return a fallback value if paid_at is null or undefined
        }

        // Parse the original UTC date string
        const utcDate = new Date(row.paid_at);

        // Format the date as "13 January 2025"
        const formattedDate = utcDate.toLocaleDateString("id-ID", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });

        // Format the time as "hh.mm"
        const formattedTime = utcDate
          .toLocaleTimeString("id-ID", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false, // Ensures 24-hour format
          })
          .replace(":", "."); // Replace ":" with "."

        return `${formattedDate} ${formattedTime}`;
      },
      // selector: (row) => {
      //   if (row.paid_at == null) {
      //     return "-";
      //   } else {
      //     const tanggal = new Date(row.paid_at);
      //     const formatTanggal = tanggal.toLocaleDateString("id-ID", {
      //       day: "2-digit",
      //       month: "long",
      //       year: "numeric",
      //     });
      //     const formatWaktu = tanggal.toLocaleTimeString("id-ID", {
      //       hour: "2-digit",
      //       minute: "2-digit",
      //     });
      //     return `${formatTanggal} ${formatWaktu}`;
      //   }
      // },
    },
    {
      name: "Status Pesanan",
      selector: (row) => row.status.name,
      width: "200px",
      cell: (row) => (
        <Badge as="a" variant="outline-success badge-rounded">
          {row.status?.name}
        </Badge>
      ),
    },
    {
      name: "No Pesanan Percetakan",
      selector: (row) => row.print_order_no,
    },
    {
      name: "Tanggal Order Percetakan",
      selector: (row) => row.print_order_date,
    },
    {
      name: "Estimasi Datang",
      selector: (row) => row.estimated_time_arrival,
    },
    {
      name: "Ekspedisi",
      selector: (row) => row.expedition_name,
    },
    {
      name: "No Resi Pengiriman",
      selector: (row) => row.receipt_no,
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  useEffect(() => {
    fetchDataStatusPesanan();
    fetchDataEskpedisi();

    if (flag === "Detail") {
      fetchDataById();
    }
  }, []);

  // useEffect(() => {
  //   fetchData();
  // }, [id]);

  useEffect(() => {
    fetchDataStatusPesananId();
  }, [formData.status_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setTouched(true);

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleDateChange = (date, name) => {
    const isoDateString = date?.toISOString();
    const formattedDate = isoDateString?.split("T")[0];
    setFormData({
      ...formData,
      [name]: formattedDate,
    });

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      status_id: formData.status_id,
      print_name: formData.print_name,
      print_order_no: formData.print_order_no,
      print_order_date: formData.print_order_date,
      estimated_time_arrival: formData.estimated_time_arrival,
      expedition_id: formData.expedition_id,
      receipt_no: formData.receipt_no,
    };

    const selectedIds = id.split(",");

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let allSuccess = true;
    let messages = []; // To collect response messages

    for (const id of selectedIds) {
      const res = await PATCH(`/book-ordering/${id}`, requestBody);
      console.log("res", res);
      if (!res) {
        allSuccess = false;
        messages.push(res?.message);
      } else {
        messages.push(res?.message);
      }
    }

    Swal.close();

    if (allSuccess) {
      Swal.fire({
        icon: "success",
        title: "success",
        html: messages.join("<br>"), // Join messages for display
      }).then(() => {
        setFormData({
          status_id: "",
          print_name: "",
          print_order_no: "",
          print_order_date: "",
          estimated_time_arrival: "",
          expedition_id: "",
          receipt_no: "",
        });
        router("/daftar-pesanan-buku");
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "error",
        html: "Estimasi Datang harus diisi jika memilih status selain Sudah dimiliki!", // Join messages for display
      });
    }
  };

  const columnsDetail = [
    {
      name: "Buku",
      selector: (row) => row.book_name,
    },
    {
      name: "Harga (Rp)",
      selector: (row) => row.price_before_discount,
      cell: (row) => <div>{currencyFormat(row.price_before_discount || 0)}</div>,
    },
    {
      name: "Qty",
      selector: (row) => row.qty,
    },
    {
      name: "Total (Rp)",
      selector: (row) => row.price,
      cell: (row) => <div>{currencyFormat(row.price)}</div>,
    },
  ];
  const customStylesDetail = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
    table: {
      style: {
        marginBottom: "0",
      },
    },
  };

  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Daftar Pesanan Buku`} motherMenu="Daftar Pesanan Buku" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Pesanan Buku</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-pesanan-buku`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              {flag === "Edit" && (
                <>
                  {filteredData && filteredData.length > 0 ? (
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      // pagination
                      // paginationServer
                      // paginationRowsPerPageOptions={[10, 25, 50]}
                      // paginationResetDefaultPage={resetPaginationToggle}
                      // paginationPerPage={perPage}
                      // paginationTotalRows={totalRows}
                      // onChangePage={handlePageChange}
                      // onChangeRowsPerPage={handlePerPageChange}
                      // onSort={handleSort}
                    />
                  ) : (
                    <p>Tidak ada data</p>
                  )}
                  <div className="form-validation">
                    <form className="form-valide" ref={formRef} onSubmit={handleEditSubmit}>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>
                              Status Pesanan<span className="text-danger">*</span>
                            </label>
                            <select className="form-control" name="status_id" value={formData.status_id} onChange={handleChange} required>
                              <option value="">Pilih status pesanan</option>
                              {filteredStatusPesanan.map((statusPesananBuku) => (
                                <option key={statusPesananBuku.id} value={statusPesananBuku.id}>
                                  {statusPesananBuku.name}
                                </option>
                              ))}
                              {/* {Array.isArray(statusPesanan) &&
                                statusPesanan.map((statusPesananBuku) => (
                                  <option key={statusPesananBuku.id} value={statusPesananBuku.id}>
                                    {statusPesananBuku.name}
                                  </option>
                                ))} */}
                            </select>
                            <Form.Control.Feedback type="invalid">Status pesanan harus dipilih.</Form.Control.Feedback>
                          </div>
                          <div className="form-group">
                            <label>
                              Nama Percetakan<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              name="print_name"
                              type="text"
                              value={formData.print_name}
                              onChange={handleChange}
                              minLength="5"
                              maxLength="25"
                              required
                              disabled={statusPesananId?.name?.toLowerCase() != "proses cetak"}
                            />
                            <Form.Control.Feedback type="invalid">Nama percetakan harus diisi minimal 5 karakter.</Form.Control.Feedback>
                          </div>
                          <div className="form-group">
                            <label>
                              No Pesanan Percetakan<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              name="print_order_no"
                              type="text"
                              value={formData.print_order_no}
                              onChange={handleChange}
                              minLength="5"
                              maxLength="25"
                              required
                              disabled={statusPesananId?.name?.toLowerCase() != "proses cetak"}
                            />
                            <Form.Control.Feedback type="invalid">No pesanan percetakan harus diisi minimal 5 karakter.</Form.Control.Feedback>
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group row">
                            <p className="mb-1">
                              Tanggal Order Percetakan<span className="text-danger">*</span>
                            </p>
                            <DatePicker
                              name="print_order_date"
                              selected={formData.print_order_date ? new Date(formData.print_order_date) : null}
                              onChange={(date) => handleDateChange(date, "print_order_date")}
                              dateFormat="yyyy-MM-dd"
                              className="form-control"
                              required
                              disabled={statusPesananId?.name?.toLowerCase() != "proses cetak"}
                            />
                            {formData.print_order_date === "" && touched && statusPesananId?.name?.toLowerCase() == "proses cetak" && (
                              <span className="mb-1 text-danger" style={{ fontSize: "14px" }}>
                                Tanggal order percetakan harus diisi.
                              </span>
                            )}
                          </div>
                          <div className="form-group row">
                            <p className="mb-1">Estimasi Datang</p>
                            <DatePicker
                              name="estimated_time_arrival"
                              selected={formData.estimated_time_arrival ? new Date(formData.estimated_time_arrival) : null}
                              onChange={(date) => handleDateChange(date, "estimated_time_arrival")}
                              dateFormat="yyyy-MM-dd"
                              className="form-control"
                              // required
                              disabled={statusPesananId?.name == "Sudah dimiliki"}
                              // disabled
                            />
                            {/* {formData.estimated_time_arrival === "" && touched && statusPesananId?.name?.toLowerCase() != "proses cetak" && (
                              <span className="mb-1 text-danger" style={{ fontSize: "14px" }}>
                                Tanggal estimasi datang harus diisi.
                              </span>
                            )} */}
                          </div>
                          <div className="form-group">
                            <label>
                              Ekspedisi<span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="expedition_id"
                              value={formData.expedition_id}
                              onChange={handleChange}
                              disabled={statusPesananId?.name === "Sudah dimiliki" || statusPesananId?.name === "Proses cetak"}
                              required
                            >
                              <option value="">Pilih ekspedisi pengiriman</option>
                              {Array.isArray(ekspedisi) &&
                                ekspedisi.map((dataEskpedisi) => (
                                  <option key={dataEskpedisi.id} value={dataEskpedisi.id}>
                                    {dataEskpedisi.name}
                                  </option>
                                ))}
                            </select>
                            <Form.Control.Feedback type="invalid">Ekspedisi harus dipilih.</Form.Control.Feedback>
                          </div>
                          <div className="form-group">
                            <label>
                              No Resi Pengiriman<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              name="receipt_no"
                              type="text"
                              value={formData.receipt_no}
                              onChange={handleChange}
                              minLength="3"
                              maxLength="30"
                              disabled={statusPesananId?.name == "Sudah dimiliki" || statusPesananId?.name === "Proses cetak"}
                              required
                            />
                            <Form.Control.Feedback type="invalid">No resi pengiriman harus diisi minimal 3 karakter.</Form.Control.Feedback>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 d-flex">
                        <div className="col-xl-6">
                          <Link className="btn btn-light w-100" to={`/daftar-pesanan-buku`}>
                            Batal
                          </Link>
                        </div>
                        <div className="col-xl-6">
                          <button type="submit" className="btn btn-primary w-100">
                            Simpan Perubahan
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
              {flag === "Detail" && (
                <div className="d-flex flex-wrap">
                  <div className="form-group col-md-6 col-12">
                    <label>
                      No Pesanan<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.no} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Waktu Pesan<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.created_at} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      ID Pelajar<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.student?.code_student} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Nama Pelajar<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.user?.name} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Tipe Pelajar<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data?.student?.type_user} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Tipe Pesanan<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data?.type_orders?.name} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Cara Pembayaran<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.payment?.name_payment} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Waktu Bayar<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.paid_at} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Tujuan Pengiriman<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.delivery} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Status Pesanan<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.status?.status_name} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Ekspedisi<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.expedition_name} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      No Resi Pengiriman<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.receipt_no} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Nama Percetakan<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.print_name} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      No Pesanan Percetakan<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.print_order_no} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Tanggal Order Percetakan<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.print_order_date} disabled />
                  </div>
                  <div className="form-group col-md-6 col-12">
                    <label>
                      Estimasi Datang<span className="text-danger">*</span>
                    </label>
                    <input className="form-control" name="" type="text" value={data.estimated_time_arrival} disabled />
                  </div>

                  {data.book_detail && data.book_detail.length > 0 ? <DataTable columns={columnsDetail} data={data.book_detail} customStyles={customStylesDetail} /> : <p>Tidak ada data</p>}
                  <table className="table table-striped">
                    <tbody>
                      <tr style={{ background: "#FFFFFF", color: "black" }}>
                        <th className="col-6" scope="row">
                          Total Buku
                        </th>
                        <td className="col-3" style={{ paddingLeft: "14px" }}>
                          {data.qty_total}
                        </td>
                        <td style={{ paddingLeft: "14px" }}>{currencyFormat(data?.price_before_discount)}</td>
                      </tr>
                      <tr style={{ background: "#F3F0FD", color: "black" }}>
                        <th scope="row">Biaya Pengiriman (Rp)</th>
                        <td style={{ paddingLeft: "14px" }}>{data.total_volume} kg</td>
                        <td style={{ paddingLeft: "14px" }}>{currencyFormat(data?.shipping_costs_before_discount)}</td>
                      </tr>
                      <tr style={{ background: "#FFFFFF", color: "black" }}>
                        <th scope="row">Total termasuk Pengiriman (Rp)</th>
                        <td></td>
                        <td style={{ paddingLeft: "14px" }}>{currencyFormat(data?.total_payment)}</td>
                      </tr>
                      <tr style={{ background: "#F3F0FD", color: "black" }}>
                        <th scope="row">Potongan</th>
                        <td></td>
                        <td style={{ paddingLeft: "14px" }}>-{data?.discount_special ? currencyFormat(data?.discount_special) : 0}</td>
                      </tr>
                      <tr style={{ background: "#FFFFFF", color: "black" }}>
                        <th scope="row">Total yang Dibayar (Rp)</th>
                        <td></td>
                        <td style={{ paddingLeft: "14px" }}>{data?.total_special ? currencyFormat(data?.total_special) : 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormPesananBuku;
