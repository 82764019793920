import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { GET } from "../../../services/AxiosService";

const FormDonasi = () => {
  const location = useLocation();
  const { id } = useParams();
  console.log("id", id);
  const navigate = useNavigate();
  const [locations, setLocations] = useState(location?.state?.from?.length > 0 ? location?.state?.from : "Detail");
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    donation_code: "",
    user_name: "",
    student_type_name: "",
    status: "",
    type_donation: "",
    paid_at: "",
    paymentMethod: "",
    status_name: "",
  });

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id) => {
    const res = await GET(`donation/${id}`);
    const detail = res?.data?.detail_donation;
    setFormData({
      ...res?.data,
      paymentMethod: res?.data.paymentMethod?.name || "",
      status_name: res?.data?.order?.status_name, // Ambil nama jika ada
    });
    setData({
      ...res?.data,
      detail_donation: Array.isArray(detail) ? detail : [detail],
    });
    // setData(res?.data.detail_donation || []); // Update data state dengan detail_donation
  };

  console.log("datas", data);

  function currencyFormat(num) {
    num = parseFloat(num);
    return num
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(/\.00$/, "");
  }

  const getStatus = (status) => {
    if (status === 0) {
      return "Menunggu Pembayaran";
    } else if (status === 1) {
      return "Sudah Dibayar";
    } else if (status === -1) {
      return "Kedaluwarsa";
    } else {
      return status;
    }
  };

  const columns = [
    {
      name: "Tipe Donasi",
      selector: (row) => row.donation_type,
    },
    {
      name: "Detail Donasi",
      selector: (row) => row.book_name,
    },
    {
      name: "Harga (Rp)",
      selector: (row) => row.price, // Safely access price
      cell: (row) => (
        <div style={{ width: "80%" }}>
          <span>{currencyFormat(row.price ?? 0)}</span>
        </div>
      ),
    },
    {
      name: "Qty",
      selector: (row) => row.book_qty,
    },
    {
      name: "Total (Rp)",
      selector: (row) => row.amount,
      cell: (row) => (
        <div style={{ width: "80%" }}>
          <span>{currencyFormat(row.amount)}</span>
        </div>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
    table: {
      style: {
        marginBottom: "0",
      },
    },
  };

  return (
    <Fragment>
      <PageTitle activeMenu={`${locations} Daftar Donasi`} motherMenu="Daftar Donasi" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{locations} Donasi</h4>

              <Link className="btn btn-primary" to={`/daftar-donasi`}>
                Kembali
              </Link>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form className="form-valide">
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className="form-group col-md-6 col-12">
                        <label>ID Donatur</label>
                        <input className="form-control" type="text" value={formData.donation_code} required disabled />
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>Nama Donatur</label>
                        <input className="form-control" type="text" value={formData.user_name} required disabled />
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>Tipe Donatur</label>
                        <input className="form-control" type="text" value={formData.student_type_name} required disabled />
                      </div>
                      {/* <div className='form-group col-md-6 col-12'>
                        <label>Status Pesanan</label>
                        <input
                          className='form-control'
                          type='text'
                          name="status"
                          value={formData.status_name}
                          required
                          disabled
                        />
                      </div> */}
                      <div className="form-group col-md-6 col-12">
                        <label>Status Donasi</label>
                        <input className="form-control" type="text" value={formData.status_name} required disabled />
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>Cara Pembayaran</label>
                        <input className="form-control" type="text" value={formData.paymentMethod} required disabled />
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>Waktu Bayar</label>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            formData.paid_at
                              ? (() => {
                                  const tanggal = new Date(formData.paid_at);
                                  const formatTanggal = tanggal?.toLocaleDateString("id-ID", {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                  });
                                  const formatWaktu = tanggal?.toLocaleTimeString("id-ID", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                  });
                                  return `${formatTanggal} ${formatWaktu}`;
                                })()
                              : ""
                          }
                          required
                          disabled
                        />
                      </div>

                      <h3 className="mb-4 mt-2">Detail Donasi</h3>

                      {data.detail_donation && data.detail_donation.length > 0 ? <DataTable columns={columns} data={data.detail_donation} customStyles={customStyles} /> : <p>Tidak ada data</p>}

                      <table className="table table-striped">
                        <tbody>
                          <tr style={{ background: "#FFFFFF", color: "black" }}>
                            <th className="col-8" scope="row">
                              Total yang Didonasikan (Rp)
                            </th>
                            <td className="col-2">{currencyFormat(formData.amount)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormDonasi;
