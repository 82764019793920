import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from '../../../services/AxiosService';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/id';

const DaftarPeriode = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  // validate
  const permissions = JSON.parse(localStorage.getItem('MASTER-DATA_PERIOD') || '[]');
  const canModify = permissions.some(permission => ['CREATE', 'UPDATE', 'DELETE', 'LOCK', 'VIEW', 'APPROVE'].includes(permission));

  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
    };
    const res = await GET(`/master-data/period`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  const columns = [
    {
      name: 'Nama Periode',
      selector: row => row.name,
    },
    {
      name: 'Bulan Periode',
      selector: row => row.masterDataPeriodDetails,
      cell: (row) => (
        <div>
          {row.masterDataPeriodDetails?.map((bulan, index) => (
            <span>
              {bulan.monthEntity.description}{index < row.masterDataPeriodDetails?.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      )
    },
    {
      name: 'Tanggal Mulai',
      selector: row => row.start_date,
      sortable: true,
      field: "start_date",
      cell: (row) => (
        <div>{row.start_date?.length > 0 ? moment(row.start_date).locale('id').format('D MMMM YYYY') : ""}</div>
      )
    },
    {
      name: 'Tanggal Akhir',
      selector: row => row.end_date,
      cell: (row) => (
        <div>{row.end_date?.length > 0 ? moment(row.end_date).locale('id').format('D MMMM YYYY') : ""}</div>
      )
    },
    {
      name: 'Status',
      selector: row => row.status,
      width: "130px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
    ...(canModify
      ? [{
        name: 'Aksi',
        selector: row => row.id,
        width: "180px",
        cell: (row) => (
          <div>
            {permissions.includes('LOCK') && (
              <>
                {row.status === 1 ? (
                  <Link
                    className="btn btn-danger shadow btn-xs sharp disabled"
                    onClick={() => handleLockOpenClick(row.id)}
                    title="Non-Aktif"
                  >
                    <i className="fa fa-unlock"></i>
                  </Link>
                ) : (
                  <Link
                    className="btn btn-success shadow btn-xs sharp"
                    onClick={() => handleLockOpenClick(row.id)}
                    title="Aktif"
                  >
                    <i className="fa fa-unlock"></i>
                  </Link>
                )}
              </>
            )}

            <button
              className="btn btn-info shadow btn-xs sharp mx-1"
              title="Detail"
              onClick={() => navigate(`/${row.id}/form-master-data-periode/Detail`)}
            >
              <i className="fa fa-eye"></i>
            </button>

            {permissions.includes('UPDATE') && (
              <button
                className="btn btn-primary shadow btn-xs sharp mr-1"
                title="Edit"
                onClick={() => navigate(`/${row.id}/form-master-data-periode/Ubah`)}
              >
                <i className="fa fa-pencil"></i>
              </button>
            )}

            {permissions.includes('DELETE') && (
              <Link
                className="btn btn-danger shadow btn-xs sharp"
                title="Delete"
                onClick={() => handleDeleteClick(row.id)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            )}
          </div>
        ),
      }]
      : [])
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/master-data/period/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus periode!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/master-data/period/${id}/activication`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: `${res?.message}`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Periode</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className='d-flex flex-wrap px-4'>
              </div>

              <div className="d-flex align-self-baseline px-4">
                {permissions.includes('CREATE') && (
                  <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-master-data-periode/Tambah`)}>
                    Tambah Periode
                  </button>
                )}
                {/* <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Periode"
                  // value={searchTerm}
                  // onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationServer
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarPeriode