import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { DELETE, GET, PATCH } from '../../../services/AxiosService';

const DaftarPencapaian = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [levelBelajar, setLevelBelajar] = useState([]);
    const [selectedLevelBelajar, setSelectedLevelBelajar] = useState("");
    
    const levelKelasOptions = [
        { id: 1, name: 'Private' },
        { id: 2, name: 'Mandiri' },
    ];
    const [levelKelas, setLevelKelas] = useState(levelKelasOptions);
    const [selectedLevelKelas, setSelectedLevelKelas] = useState("");

    const fetchData = async (page, perPage, sortField, sortOrder, search, levelBelajar, levelKelas) => {
        const params = { page, size: perPage, sort: sortField, orderby: sortOrder, search, 
            learning_level: levelBelajar, student_class: levelKelas,
        };
        const res = await GET(`/materi/pencapaian-all-students`, params);
        setData(res?.data || []);
        setTotalRows(res?.meta?.total || 0);
    };

    const fetchDataLevelBelajar = async () => {
        const res = await GET(`master-data/type?type=Level Belajar`);
        setLevelBelajar(res?.data || []);
    };

    const fetchServerData = () => {
        fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedLevelBelajar, selectedLevelKelas);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        localStorage.setItem('searchTerm', e.target.value);
    };

    const handleSort = (column, direction) => {
        setSortField(column.field);
        setSortOrder(direction);
    };

    useEffect(() => {
        fetchServerData();
    }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedLevelBelajar, selectedLevelKelas]);

    useEffect(() => {
        fetchDataLevelBelajar();
    }, []);

    const handleSelectLevelBelajar = (e) => {
        const value = e.target.getAttribute("value");
        setSelectedLevelBelajar(value);
        setCurrentPage(1);
    };

    const handleSelectLevelKelas = (e) => {
        const value = e.target.getAttribute("value");
        setSelectedLevelKelas(value);
        setCurrentPage(1);
    };

    const columns = [
        {
            name: 'Pelajar',
            selector: row => row.student_name || '-',
        },
        {
            name: 'Level',
            selector: row => row.learning_level || '-',
        },
        {
            name: 'Jenis Kelas',
            selector: row => row.student_class || '-',
        },
        {
            name: 'Rata-Rata Pengulangan Topik',
            selector: row => row.pencapaian?.repeat_all_topic,
            cell: row => <div style={{ textAlign: 'center', width: '100%' }}>{row.pencapaian?.repeat_all_topic || 0}</div>,
        },
        {
            name: 'Rata-Rata Pengulangan Baris',
            selector: row => row.pencapaian?.repeat_all_line,
            cell: row => <div style={{ textAlign: 'center', width: '100%' }}>{row.pencapaian?.repeat_all_line || 0}</div>,
        },
        {
            name: 'Aksi',
            selector: row => row.id,
            width: "150px",
            cell: (row) => (
                <div>
                    <button className="btn btn-info shadow btn-xs sharp mx-1" title="Detail" 
                        onClick={() => navigate(`/${row.student_id}/pencapaian-siswa-semua/Detail`, { state: { from: 'Detail' } })}>
                        <i className="fa fa-eye"></i>
                    </button>
                </div>
            ),
        },
    ];

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#FCFCFD",
                fontSize: "14px",
                fontWeight: "bold",
                color: "black",
                textAlign: 'center',
            },
        },
        rows: {
            style: {
                backgroundColor: "#FFFFFF",
                textAlign: 'center',
                "&:nth-child(2n)": {
                    backgroundColor: "#F3F0FD",
                },
            },
        },
    };

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Pencapaian Pelajar</Card.Title>
                        </Card.Header>
                        <div className="d-flex justify-content-between mt-4">
                            <div className='d-flex align-self-baseline px-4'>
                                <Dropdown className="mr-4">
                                    <Dropdown.Toggle
                                        style={{
                                            background: "transparent",
                                            borderColor: "grey",
                                            color: "grey",
                                        }}
                                        id="dropdown-basic"
                                    >
                                        {selectedLevelKelas?.length > 0 ? selectedLevelKelas : "Tipe Kelas"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            key="all"
                                            value=""
                                            onClick={handleSelectLevelKelas}
                                        >
                                            Tipe Kelas
                                        </Dropdown.Item>
                                        {Array.isArray(levelKelas) &&
                                            levelKelas.map((student_class) => (
                                                <Dropdown.Item
                                                    key={student_class.id}
                                                    value={student_class.name}
                                                    onClick={handleSelectLevelKelas}
                                                >
                                                    {student_class.name}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className="mr-4">
                                    <Dropdown.Toggle
                                        style={{
                                            background: "transparent",
                                            borderColor: "grey",
                                            color: "grey",
                                        }}
                                        id="dropdown-basic"
                                    >
                                        {selectedLevelBelajar?.length > 0 ? selectedLevelBelajar : "Tipe Level"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            key="all"
                                            value=""
                                            onClick={handleSelectLevelBelajar}
                                        >
                                            Tipe Level
                                        </Dropdown.Item>
                                        {Array.isArray(levelBelajar) &&
                                            levelBelajar.map((learning_level) => (
                                                <Dropdown.Item
                                                    key={learning_level.id}
                                                    value={learning_level.name}
                                                    onClick={handleSelectLevelBelajar}
                                                >
                                                    {learning_level.name}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="d-flex align-self-baseline px-4">
                                <div className="input-group search-area d-xl-inline-flex">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Cari Pelajar"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <Link to="#">
                                                <i className="flaticon-381-search-2" />
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>     
                        </div>
                        <Card.Body>
                            {data && data.length > 0 ? (
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    customStyles={customStyles}
                                    pagination
                                    paginationServer
                                    paginationRowsPerPageOptions={[10, 25, 50]}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                    paginationPerPage={perPage}
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerPageChange}
                                    onSort={handleSort}
                                />
                            ) : (
                                <p>Tidak ada data</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

export default DaftarPencapaian;
