import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { addMethod } from "yup";

const TambahPengeluaran = () => {
  const location = useLocation();
  const { saldo } = location.state || {}; // Use a default empty object in case state is undefined
  const saldoAwal = saldo ? saldo["Saldo Awal Periode"] : "Data not available";
  const pemasukanPeriode = saldo ? saldo["Pemasukan Periode"] : "Data not available";
  const pengeluaranPeriode = saldo ? saldo["Pengeluaran Periode"] : "Data not available";
  // const saldoInfaq = saldo ? saldo["Saldo Akhir Periode"] : "Data not available";
  const menungguPersetujuan = saldo ? saldo["Menunggu Persetujuan"] : "Data not available";
  const { id, flag } = useParams();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    // saldo_infaq: "",
    // menunggu_persetujuan: "",
    expense_type: [],
    book_package_id: [],
    qty: "",
    amount: 0,
    // sisa_saldo_infaq_persetujuan: ""
  });
  const [detailFormData, setDetailFormData] = useState({
    saldo_infaq: "",
    menunggu_persetujuan: "",
    expenseType: "",
    book_package_id: [],
    qty: "",
    amount: "",
    sisa_saldo_infaq_persetujuan: "",
  });
  const [validated, setValidated] = useState(false);
  const [touched, setTouched] = useState(false);
  const formRef = useRef(null);
  const [buku, setBuku] = useState([]);
  const [tipePengeluaran, setTipePengeluaran] = useState([]);
  const [prices, setPrices] = useState(0);

  const parseCurrency = (currency) => {
    return Number(currency.replace(/Rp\s?|\.|,/g, ""));
  };

  // Convert strings to numbers
  const menungguPersetujuanNumber = parseCurrency(menungguPersetujuan); // 50000
  const saldoAwalNumber = parseCurrency(saldoAwal);
  const pemasukanPeriodeNumber = parseCurrency(pemasukanPeriode);
  const pengeluaranPeriodeNumber = parseCurrency(pengeluaranPeriode);
  const saldoInfaq = saldoAwalNumber + pemasukanPeriodeNumber - pengeluaranPeriodeNumber;

  // Perform calculation
  const result = saldoInfaq - menungguPersetujuanNumber - formData.amount;

  // Dropdown Isi Buku
  const fetchDataBuku = async () => {
    const res = await GET(`book-package`);
    setBuku(res?.data);
  };

  const formatToIDR = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency", // Only format as a number, without currency symbol
      currency: "IDR",
      minimumFractionDigits: 0, // No decimals (optional, based on requirement)
    }).format(amount);
  };

  // Dropdown Isi typePengeluaran
  const fetchTypePengeluaran = async () => {
    const res = await GET(`/master-data/type?type=Tipe Pengeluaran`);
    setTipePengeluaran(res?.data);
  };
  // Get Detail
  const fetchData = async (id) => {
    try {
      const res = await GET(`alokasi-pengeluaran/${id}`);
      console.log("res", res.data);
      const formattedAmount = formatToIDR(res.data.amount);
      setDetailFormData({
        book_package_id: res.data.book_package_id?.return?.name,
        qty: res.data.qty,
        amount: formattedAmount,
        outer_id: "string",
        status: 0,
        expenseType: res.data.expenseType?.return?.name,
      });
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const bukuPromise = fetchDataBuku();
        const typePengeluaran = fetchTypePengeluaran();
        const dataPromise = id ? fetchData(id) : Promise.resolve();
        await Promise.all([bukuPromise, dataPromise, typePengeluaran]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Membatasi hingga 4 digit
    // if (value.length > 4) return;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "qty" && {
        amount: prices * value || 0, // Recalculate amount when qty changes
      }),
    }));

    setDetailFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // TAMBAH
  const handleSubmit = async (e) => {
    e.preventDefault();
    const FormData = {
      ...formData,
      outer_id: "", // Add the amount value
      status: 0,
    };
    console.log("requestBody", FormData);

    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await POST(`alokasi-pengeluaran`, FormData);
      if (res) {
        Swal.fire({
          icon: "success",
          title: res.message,
        }).then(() => {
          Swal.close();
          navigate(`/daftar-alokasi-pengeluaran-infaq`);
        });
      }
    }
  };

  // EDIT
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const transformedFormDataEditt = {
      ...detailFormData,
      book: detailFormData.book.map((bookId) => ({ id: bookId.id })), // Map valid entries to the required format
    };
    console.log("handleEditSubmit", transformedFormDataEditt);
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await PATCH(`book-package/${id}`, transformedFormDataEditt);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Paket Buku berhasil diubah!",
        }).then(() => {
          Swal.close();
          navigate(`/daftar-paket-buku`);
        });
      }
    }
  };

  const handleSelectChange = (selectedOptions, name) => {
    const matchedItem = name === "book_package_id" ? buku.find((item) => item.id === selectedOptions.value) : null;

    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions.value, // Update the selected field
      ...(name === "book_package_id" && {
        amount: matchedItem ? matchedItem.price * (prevData.qty || 0) : 0, // Recalculate amount
      }),
    }));

    setDetailFormData((prevData) => ({
      ...prevData,
      [name]: {
        id: selectedOptions.value,
        name: selectedOptions.label,
      },
      ...(name === "book_package_id" && {
        price: matchedItem ? matchedItem.price : 0,
      }),
    }));

    if (name === "book_package_id") {
      setPrices(matchedItem ? matchedItem.price : 0); // Update prices if applicable
    }

    setTouched(true);

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const currencyFormat = (number) => {
    if (!number) return "0";
    return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      // height: '50px',
      minHeight: "55px",
      borderColor: "#D9D9D9", // Border color
      boxShadow: "none", // Remove shadow
      "&:hover": { borderColor: "#1890FF" }, // Hover effect
    }),
  };

  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Alokasi Pengeluaran Infaq`} motherMenu="Daftar Alokasi Pengeluaran Infaq" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Alokasi Pengeluaran Infaq</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-alokasi-pengeluaran-infaq`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {/* TAMBAH */}
                {flag === "Tambah" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Saldo Infaq<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="saldo_infaq" type="text" value={`Rp ${saldoInfaq.toLocaleString("id-ID")}` || 0} onChange={handleInputChange} placeholder="Sado Infaq" disabled />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Menunggu Persetujuan<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="menunggu_persetujuan" type="text" value={menungguPersetujuan} onChange={handleInputChange} placeholder="Menunggu Persetujuan" disabled />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        {buku?.length > 0 && (
                          <div className="form-group col-md-6 col-12">
                            <>
                              <label>
                                Paket Buku<span className="text-danger">*</span>
                              </label>
                              <Select
                                name="paket_paket"
                                options={buku.map((item) => ({ value: item.id, label: item.name }))}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "book_package_id")}
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={
                                  buku
                                    .filter((item) => item.id === formData?.paket_buku) // Adjusted for single value
                                    .map((item) => ({ value: item.id, label: item.name }))[0] // Get the first object
                                }
                                styles={selectStyle}
                              />
                            </>
                            {/* )} */}
                          </div>
                        )}
                        {tipePengeluaran?.length > 0 && (
                          <div className="form-group col-md-6 col-12">
                            <>
                              <label>
                                Tipe pengeluaran<span className="text-danger">*</span>
                              </label>
                              <Select
                                name="tipe_pengeluaran"
                                options={tipePengeluaran.map((item) => ({ value: item.id, label: item.name }))}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, "expense_type")}
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={
                                  tipePengeluaran
                                    .filter((item) => item.id === formData?.expense_type) // Adjusted for single value
                                    .map((item) => ({ value: item.id, label: item.name }))[0] // Get the first object
                                }
                                styles={selectStyle}
                              />
                            </>
                            {/* )} */}
                          </div>
                        )}
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Jumlah<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            name="qty"
                            type="number"
                            value={formData.qty}
                            onChange={handleInputChange}
                            placeholder="Jumlah"
                            min={0}
                            required
                            disabled={formData.book_package_id.length === 0}
                            autoComplete="off"
                          />
                          {/* <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback> */}
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Pengeluaran<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="pengeluaran" type="text" value={`Rp ${formData.amount.toLocaleString("id-ID")}` || 0} onChange={handleInputChange} placeholder="Pengeluaran" required disabled />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Sisa Saldo Infaq - Menunggu Persetujuan<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            name="sisa_saldo_infaq_persetujuan"
                            type="text"
                            value={`Rp ${result.toLocaleString("id-ID")}` || 0}
                            onChange={handleInputChange}
                            placeholder="Sisa Saldo Infaq - Menunggu Persetujuan"
                            disabled
                            required
                          />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-alokasi-pengeluaran-infaq`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={handleSubmit} disabled={saldoInfaq < formData.amount || formData.qty <= 0}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Detail" ? (
                  <form className="form-valide" ref={formRef}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Saldo Infaq<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="saldo_infaq" type="text" value={`Rp ${saldoInfaq.toLocaleString("id-ID")}` || 0} onChange={handleInputChange} placeholder="Sado Infaq" minLength="5" maxLength="100" disabled />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Menunggu Persetujuan<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="menunggu_persetujuan" type="text" value={menungguPersetujuan} onChange={handleInputChange} placeholder="Menunggu Persetujuan" minLength="5" maxLength="100" disabled />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <>
                            <label>
                              Paket Buku<span className="text-danger">*</span>
                            </label>
                            <input className="form-control" name="tipe_pengeluaran" type="text" value={detailFormData.book_package_id} placeholder="Tipe pengeluaran" maxLength="100" disabled />
                            {/* <Select
                              isMulti
                              name="paket_paket"
                              options={buku.map(item => ({ value: item.id, label: item.name }))}
                              onChange={''}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              defaultValue={buku.filter(item => formData?.paket_buku?.includes(item.id)).map(item => ({ value: item.id, label: item.name }))}
                              styles={selectStyle}
                              isDisabled={true}
                            /> */}
                          </>
                          {/* )} */}
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <>
                            <label>
                              Tipe pengeluaran<span className="text-danger">*</span>
                            </label>
                            <input className="form-control" name="tipe_pengeluaran" type="text" value={detailFormData.expenseType} placeholder="Tipe pengeluaran" maxLength="100" disabled />
                            {/* <Select
                              isMulti
                              name="tipe_pengeluaran"
                              options={tipePengeluaran.map(item => ({ value: item.id, label: item.name }))}
                              onChange={detailFormData.expense_type}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              defaultValue={tipePengeluaran.filter(item => formData?.expense_type?.includes(item.id)).map(item => ({ value: item.id, label: item.name }))}
                              styles={selectStyle}
                              isDisabled={true}
                            /> */}
                            {/* <Select
                              isMulti
                              name="tipe_pengeluaran"
                              options={tipePengeluaran.map(item => ({ value: item.id, label: item.name }))}
                              value={detailFormData.expense_type} // Bind the fetched value here
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={selectStyle}
                              isDisabled={true} // Disable selection as per requirement
                            /> */}
                          </>
                          {/* )} */}
                        </div>

                        <div className="form-group col-md-6 col-12">
                          <label>
                            Jumlah<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="jumlah" type="text" value={detailFormData.qty} onChange={handleInputChange} placeholder="Jumlah" minLength="5" maxLength="100" disabled />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Pengeluaran<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="pengeluaran" type="text" value={detailFormData.amount} onChange={handleInputChange} placeholder="Pengeluaran" minLength="5" maxLength="100" disabled />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Sisa Saldo Infaq - Menunggu Persetujuan<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            name="pengeluaran"
                            type="text"
                            value={`Rp ${result.toLocaleString("id-ID")}` || 0}
                            onChange={handleInputChange}
                            placeholder="Sisa Saldo Infaq - Menunggu Persetujuan"
                            minLength="5"
                            maxLength="100"
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TambahPengeluaran;
