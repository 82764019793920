import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { GET } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Select from "react-select";

const DaftarDonasi = () => {
  const location = useLocation();
  const { tipe, start, end } = location.state || {}; // Use a default empty object in case state is undefined
  console.log("saldoAwal", tipe, start, end);
  const navigate = useNavigate();
  const [data, setData] = useState();
  //server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [tipeDonatur, setTipeDonatur] = useState([]);
  const [selectedTipeDonatur, setSelectedTipeDonatur] = useState("");
  const [tipeDonasi, setTipeDonasi] = useState([]);
  const [selectedTipeDonasi, setSelectedTipeDonasi] = useState(tipe);
  const [selectedStatusDonasi, setSelectedStatusDonasi] = useState("");
  const [selectedNameStatusDonasi, setSelectedNameStatusDonasi] = useState("");
  const [selectedInfaqBuku, setSelectedInfaqBuku] = useState("");
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [internalDateTo, setInternalDateTo] = useState(null);
  const [meta, setMeta] = useState("");
  const [buku, setBuku] = useState([]);

  const fetchData = async (page, perPage, sortField, sortOrder, search, tipeDonasi, infaqBuku, tipeDonatur, statusDonasi, startDate) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      type_donation: tipeDonasi,
      student_type_name: tipeDonatur,
      status: statusDonasi.length > 0 ? statusDonasi : "Dalam pengiriman,Pesanan Terkonfirmasi,Proses cetak,Sudah dimiliki,Menunggu Pembayaran",
      start_date: startDate,
      end_date: internalDateTo,
      infaqBuku,
    };
    const res = await GET(`/donation`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
    setMeta(res?.meta);
  };

  console.log("lloll", data);

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedTipeDonasi, selectedInfaqBuku, selectedTipeDonatur, selectedStatusDonasi, startDate, endDate);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTipeDonasi, selectedInfaqBuku, selectedTipeDonatur, selectedStatusDonasi, startDate, endDate]);

  function currencyFormat(num) {
    num = parseFloat(num);
    return num
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(/\.00$/, "");
  }

  const fetchTipeDonasi = async () => {
    const res = await GET(`master-data/type?type=Tipe Donasi`);
    setTipeDonasi(res?.data);
  };
  const fetchDataBuku = async () => {
    const res = await GET(`book?status=1`);
    let data = [];
    data.push({
      id: 1,
      name: "Infaq",
    });
    data.push(...res?.data);
    setBuku(data);
  };
  const fetchTipeDonatur = async () => {
    const res = await GET(`master-data/type?type=Tipe Pelajar`);
    setTipeDonatur(res?.data);
  };

  useEffect(() => {
    fetchTipeDonasi();
    fetchDataBuku();
    fetchTipeDonatur();
  }, []);

  const columns = [
    {
      name: "Nama Donatur",
      selector: (row) => row.user_name,
    },
    {
      name: "Tipe Donasi",
      selector: (row) => row.type_donation,
    },
    {
      name: "ID",
      selector: (row) => row.no,
    },
    {
      name: "Tipe Infaq/Buku",
      selector: (row) => row.book_name,
    },
    {
      name: "Qty",
      selector: (row) => row.book_qty,
    },
    {
      name: "Tipe Donatur",
      selector: (row) => row.student_type_name,
    },
    {
      name: "Total Donasi",
      selector: (row) => <span>{currencyFormat(row.amount)}</span>,
    },
    {
      name: "Waktu Donasi",
      field: "created_at",
      selector: (row) => {
        if (row.created_at == null) {
          return "-";
        } else {
          const tanggal = new Date(row.created_at);
          const formatTanggal = tanggal.toLocaleDateString("id-ID", {
            day: "2-digit",
            month: "long",
            year: "numeric",
            // timeZone: "UTC", // Use UTC time zone
          });

          const formatWaktu = tanggal.toLocaleTimeString("id-ID", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            // timeZone: "UTC", // Use UTC time zone
          });

          return `${formatTanggal} ${formatWaktu}`;
        }
      },
    },
    // {
    //   name: "Status Donasi",
    //   selector: (row) => (row?.order?.status_name ? row?.order?.status_name : "-"),
    // },
    {
      name: "Status Donasi",
      selector: (row) => (row?.order?.status_name ? row?.order?.status_name : "-"),
      width: "210px",
      cell: (row) => {
        let displayText;
        if (row?.order?.status_name === "Menunggu Pembayaran") {
          displayText = (
            <Badge as="a" variant="outline-warning badge-rounded">
              Menunggu Pembayaran
            </Badge>
          );
        } else if (row?.order?.status_name === "Pesanan Terkonfirmasi") {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded" style={{ outline: "yellow" }}>
              Pesanan Terkonfirmasi
            </Badge>
          );
        } else if (row?.order?.status_name === "Proses cetak") {
          displayText = (
            <Badge as="a" variant="outline-info badge-rounded">
              Proses cetak
            </Badge>
          );
        } else if (row?.order?.status_name === "Dalam Pengiriman") {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Dalam Pengiriman
            </Badge>
          );
        } else if (row?.order?.status_name === "Sudah Dimiliki") {
          displayText = (
            <Badge as="a" variant="outline-secondary badge-rounded">
              Sudah Dimiliki
            </Badge>
          );
        } else if (row?.order?.status_name === "Kedaluarsa") {
          displayText = (
            <Badge as="a" variant="outline-danger badge-rounded">
              Kedaluarsa
            </Badge>
          );
        }
        return displayText;
      },
    },
    {
      name: "Aksi",
      selector: (row) => row.id,
      width: "150px",
      cell: (row) => (
        <div>
          <button className="btn btn-info shadow btn-xs sharp mx-1" title="Detail" onClick={() => navigate(`/${row.id}/form-donasi/Detail`, { state: { from: "Detail" } })}>
            <i className="fa fa-eye"></i>
          </button>
        </div>
      ),
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleSelectTipeDonasi = (e) => {
    setSelectedTipeDonasi(e.target.getAttribute("value"));
  };

  const handleSelectTipeDonatur = (e) => {
    setSelectedTipeDonatur(e.target.getAttribute("value"));
  };

  // const handleSelectInfaqBuku = (e) => {
  //   setSelectedInfaqBuku(e.target.getAttribute("value"));
  // };

  const handleSelectInfaqBuku = (selectedOption) => {
    setSelectedInfaqBuku(selectedOption?.value || "");
  };

  const bukuOptions = Array.isArray(buku)
    ? buku.map((infaqBuku) => ({
        value: infaqBuku.name,
        label: infaqBuku.name,
      }))
    : [];

  const handleSelectStatusDonasi = (e) => {
    setSelectedStatusDonasi(e.target.getAttribute("value"));
    setSelectedNameStatusDonasi(e.target.textContent);
  };

  const handleDateFromChange = (date) => {
    const formattedDate = date?.toLocaleDateString("en-CA");
    setStartDate(formattedDate);
    setEndDate(null); // Reset dateTo jika dateFrom diubah
    setInternalDateTo(null);
  };

  const handleDateToChange = (date) => {
    const formattedDate = date?.toLocaleDateString("en-CA");
    setEndDate(formattedDate);

    if (date) {
      // Menambahkan 1 hari secara internal untuk keperluan filter
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);
      const formattedNextDay = nextDay.toLocaleDateString("en-CA");
      setInternalDateTo(formattedNextDay); // Set tanggal +1 hari secara internal
    } else {
      setInternalDateTo(null);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Donasi</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {selectedTipeDonasi?.length > 0 ? selectedTipeDonasi : "Semua Tipe Donasi"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipeDonasi}>
                      Semua Tipe Donasi
                    </Dropdown.Item>
                    {Array.isArray(tipeDonasi) &&
                      tipeDonasi.map((donasiTipe) => (
                        <Dropdown.Item key={donasiTipe.id} value={donasiTipe.name} onClick={handleSelectTipeDonasi}>
                          {donasiTipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="mr-4">
                  <Select
                    options={[{ value: "", label: "Semua Infaq/Buku" }, ...bukuOptions]}
                    value={bukuOptions.find((option) => option.value === selectedInfaqBuku) || { value: "", label: "Semua Infaq/Buku" }}
                    onChange={handleSelectInfaqBuku}
                    isClearable
                    placeholder="Cari Infaq/Buku"
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: "grey",
                        boxShadow: "none",
                      }),
                    }}
                  />
                </div>

                {/* 
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {selectedInfaqBuku?.length > 0 ? selectedInfaqBuku : "Semua Infaq/Buku"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectInfaqBuku}>
                      Semua Infaq/Buku
                    </Dropdown.Item>
                    {Array.isArray(buku) &&
                      buku.map((infaqBuku) => (
                        <Dropdown.Item key={infaqBuku.id} value={infaqBuku.name} onClick={handleSelectInfaqBuku}>
                          {infaqBuku.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown> */}

                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {selectedTipeDonatur?.length > 0 ? selectedTipeDonatur : "Semua Tipe Donatur"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipeDonatur}>
                      Semua Tipe Donatur
                    </Dropdown.Item>
                    {Array.isArray(tipeDonatur) &&
                      tipeDonatur.map((donaturTipe) => (
                        <Dropdown.Item key={donaturTipe.id} value={donaturTipe.name} onClick={handleSelectTipeDonatur}>
                          {donaturTipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                    }}
                    id="dropdown-basic"
                  >
                    {selectedNameStatusDonasi?.length > 0 ? selectedNameStatusDonasi : "Tanpa Kedaluwarsa"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item key="semua_status" value="" onClick={handleSelectStatusDonasi}>
                      Semua Status
                    </Dropdown.Item> */}
                    <Dropdown.Item key="semua_selain_kedaluarsa" value="Dalam pengiriman,Pesanan Terkonfirmasi,Proses cetak,Sudah dimiliki,Menunggu Pembayaran" onClick={handleSelectStatusDonasi}>
                      Tanpa Kedaluwarsa
                    </Dropdown.Item>
                    <Dropdown.Item key="menunggu_pembayaran" value="Menunggu Pembayaran" onClick={handleSelectStatusDonasi}>
                      Menunggu Pembayaran
                    </Dropdown.Item>
                    <Dropdown.Item key="pesanan_terkonfirmasi" value="Pesanan Terkonfirmasi" onClick={handleSelectStatusDonasi}>
                      Pesanan Terkonfirmasi
                    </Dropdown.Item>
                    <Dropdown.Item key="proses_cetak" value="Proses Cetak" onClick={handleSelectStatusDonasi}>
                      Proses Cetak
                    </Dropdown.Item>
                    <Dropdown.Item key="dalam_pengiriman" value="Dalam Pengiriman" onClick={handleSelectStatusDonasi}>
                      Dalam Pengiriman
                    </Dropdown.Item>
                    <Dropdown.Item key="sudah_dimiliki" value="Sudah Dimiliki" onClick={handleSelectStatusDonasi}>
                      Sudah Dimiliki
                    </Dropdown.Item>
                    <Dropdown.Item key="kedaluarsa" value="Kedaluarsa" onClick={handleSelectStatusDonasi}>
                      Kedaluarsa
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="d-flex align-self-baseline px-4">
                <div className="input-group search-area d-xl-inline-flex">
                  <input type="text" className="form-control" placeholder="Cari Donasi" value={searchTerm} onChange={handleSearchChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex px-4 mt-2">
              <DatePicker className="form-control mr-1" value={startDate} onChange={handleDateFromChange} style={{ maxHeight: "45px" }} dateFormat="yyyy-MM-dd" placeholderText="Waktu Donasi (from)" />
              <DatePicker className="form-control ml-4" value={endDate} onChange={handleDateToChange} style={{ maxHeight: "45px" }} dateFormat="yyyy-MM-dd" placeholderText="Waktu Donasi (to)" />
            </div>
            <Card.Body>
              <p style={{ margin: "1px 0", fontWeight: "700" }}>Qty : {meta?.qty_total != null ? meta.qty_total : 0}</p>
              <p style={{ margin: "1px 0", fontWeight: "700" }}>Biaya : {meta?.amount_total != null ? new Intl.NumberFormat("id-ID").format(meta.amount_total) : 0}</p>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DaftarDonasi;
