import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";

const FormBuku = () => {
  const location = useLocation();
  const { id, flag } = useParams();
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const [formData, setFormData] = useState({
    code: "",
    name: "",
    description: "",
    price: "",
    volume: "",
    panjang: "",
    lebar: "",
    tinggi: "",
    // tambahan
    condition: "",
    volume_materi: "",
    materi_id: "",
  });
  const [validated, setValidated] = useState(false);
  const [dataMateri, setDataMateri] = useState([]);
  const [syaratPembelian, setSyaratPembelian] = useState([]);
  const [topik, setTopik] = useState([]);
  const [condition, setCondition] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "volume_materi") {
      const selectVolume = dataMateri.find((volume_materi) => volume_materi.id === value);
      const filteredTopik = selectVolume.child.filter((child) => child.type == "Topik");
      setTopik(filteredTopik);
    }
    console.log("iniii", formData.volume);

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const prices = formData.price === 0 ? 0 : parseFloat(formData?.price?.replace(/\./g, ""));
    const condition = parseInt(formData.condition, 10); // Ensure condition is an integer
    const volume = parseFloat(formData.volume); // Convert volume to a float or integer as needed
    const requestBody = {
      code: formData.code,
      name: formData.name,
      description: formData.description,
      price: prices,
      volume: isNaN(volume) ? 0 : volume, // Ensure a default value of 0 if volume is invalid
      dimensions: `${formData.panjang} x${formData.lebar} x${formData.tinggi}`,
      condition: isNaN(condition) ? null : condition, // Set to null if condition is invalid
      materi_id: formData.condition != "2" ? null : formData.materi_id,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await POST(`/book`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil membuat buku!",
      }).then(() => {
        Swal.close();
        setFormData({
          code: "",
          name: "",
          description: "",
          price: "",
          volume: "",
          panjang: "",
          lebar: "",
          tinggi: "",
        });
        router("/daftar-buku");
      });
    }
  };

  function currencyFormat(num) {
    num = parseFloat(num);
    return num
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(/\.00$/, "");
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const priceStr = formData.price?.toString();
    const prices = parseFloat(priceStr.replace(/\./g, ""));
    const volume = parseFloat(formData.volume); // Convert volume to a float or integer as needed
    const requestBody = {
      code: formData.code,
      name: formData.name,
      description: formData.description,
      price: prices,
      volume: isNaN(volume) ? 0 : volume, // Ensure a default value of 0 if volume is invalid,
      dimensions: `${formData.panjang}x${formData.lebar}x${formData.tinggi}`,
      condition: formData.condition.toString(),
      materi_id: formData.materi_id,
    };

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/book/${id}`, requestBody);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil mengubah buku!",
      }).then(() => {
        Swal.close();
        setFormData({
          code: "",
          name: "",
          description: "",
          price: "",
          volume: "",
          panjang: "",
          lebar: "",
          tinggi: "",
        });
        router("/daftar-buku");
      });
    }
  };

  const fetchBook = async () => {
    try {
      const bookRes = await GET(`/book/${id}`);
      const prices = parseFloat(bookRes.data?.price?.replace(/,/g, "")) || 0;
      const formatPrice = currencyFormat(prices);

      const dimensions = bookRes.data?.dimensions?.split("x") || [];
      const [panjang, lebar, tinggi] = dimensions.map((dim) => parseFloat(dim) || 0);

      setFormData({
        code: bookRes?.data.code,
        name: bookRes?.data.name,
        description: bookRes?.data.description,
        price: formatPrice,
        volume: bookRes?.data?.volume,
        panjang,
        lebar,
        tinggi,
        condition: bookRes?.data.condition.toString(),
        volume_materi: bookRes?.data?.materi?.parent,
        materi_id: bookRes?.data?.materi?.id,
      });

      if (bookRes?.data.condition == "2") {
        setCondition("2");
      }
    } catch (error) {
      console.error("Error fetching book:", error);
    }
  };

  const fetchMaterial = async () => {
    try {
      const materiRes = await GET(`materi`);
      setDataMateri(materiRes?.data);
    } catch (error) {
      console.error("Error fetching material:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBook();
    }
  }, [id]);

  useEffect(() => {
    fetchMaterial();
    fetchDataMateri();
    fetchSyaratpembelian();
  }, []);

  useEffect(() => {
    if (condition === "2" && dataMateri.length > 0) {
      const selectVolume = dataMateri.find((volume_materi) => volume_materi.id === formData?.volume_materi);
      const filteredTopik = selectVolume?.child?.filter((child) => child.type == "Topik") || [];
      setTopik(filteredTopik);
    }
  }, [condition, dataMateri, formData?.volume_materi]);

  const fetchDataMateri = async () => {
    const res = await GET(`materi`);
    setDataMateri(res?.data);
  };

  const fetchSyaratpembelian = async () => {
    const res = await GET(`master-data/type?type=Syarat Pembelian Buku`);
    setSyaratPembelian(res.data);
  };
  return (
    <Fragment>
      <PageTitle activeMenu={`${flag} Daftar Buku`} motherMenu="Daftar Buku" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">{flag} Buku</h4>
              {flag === "Detail" && (
                <Link className="btn btn-primary" to={`/daftar-buku`}>
                  Kembali
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="form-validation">
                {flag === "Tambah" ? (
                  <form className="form-valide" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Kode Buku<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="code" type="text" value={formData.code} onChange={handleChange} minLength="3" maxLength="5" required disabled={location.state?.from === "Detail"} />
                          <Form.Control.Feedback type="invalid">Kode buku harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Judul<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="name" type="text" value={formData.name} onChange={handleChange} minLength="3" maxLength="50" required disabled={location.state?.from === "Detail"} />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>

                        <div className="form-group col-md-6 col-12">
                          <label>
                            Berat Paket (gram)<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={formData.volume}
                            onChange={(e) => {
                              const { value } = e.target;
                              // Hanya karakter angka
                              const numericValue = value.replace(/\D/g, "");
                              setFormData((prevState) => ({
                                ...prevState,
                                volume: numericValue,
                              }));
                              console.log("tes", formData.volume);

                              const form = e.currentTarget.form;
                              const isValid = form.checkValidity();

                              setValidated(isValid);

                              if (isValid) {
                                form.classList.remove("was-validated");
                              } else {
                                form.classList.add("was-validated");
                              }
                            }}
                            maxLength={5}
                            required
                          />
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Dimensi Paket (P x L x T)<span className="text-danger">*</span>
                          </label>
                          <div className="d-flex flex-wrap">
                            <input
                              type="text"
                              className="form-control col-md-4"
                              value={formData.panjang}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  panjang: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled={location.state?.from === "Detail"}
                            />
                            <span className="align-self-center mx-2">X</span>
                            <input
                              type="text"
                              className="form-control col-md-3"
                              value={formData.lebar}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  lebar: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled={location.state?.from === "Detail"}
                            />
                            <span className="align-self-center mx-2">X</span>
                            <input
                              type="text"
                              className="form-control col-md-3"
                              value={formData.tinggi}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  tinggi: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled={location.state?.from === "Detail"}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Harga (Rp)<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            name="price"
                            type="text"
                            value={formData?.price}
                            onChange={(e) => {
                              const { value } = e.target;
                              const parsedValue = parseFloat(value.replace(/[^0-9]/g, ""));
                              const formattedValue = isNaN(parsedValue) ? 0 : currencyFormat(parsedValue);
                              setFormData((prevState) => ({
                                ...prevState,
                                price: formattedValue,
                              }));

                              const form = e.currentTarget.form;
                              const isValid = form.checkValidity();

                              setValidated(isValid);

                              if (isValid) {
                                form.classList.remove("was-validated");
                              } else {
                                form.classList.add("was-validated");
                              }
                            }}
                            required
                            maxLength="11"
                            disabled={location.state?.from === "Detail"}
                          />
                          <Form.Control.Feedback type="invalid">Harga harus diisi.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Deskripsi<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="description" type="text" value={formData.description} onChange={handleChange} minLength="5" maxLength="100" required disabled={location.state?.from === "Detail"} />
                          <Form.Control.Feedback type="invalid">Deskripsi harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>

                        {/* <div className='form-group col-md-6 col-12'>
                        <label>Syarat Pembelian Buku<span className="text-danger">*</span></label>
                        <select
                          className='form-control'
                          value={formData.syarat_pembelian}
                          name="syarat_pembelian"
                          onChange={handleChange}
                          disabled={flag === "Detail"}
                          required
                        >
                          <option value="">Pilih Syarat Pembelian Buku</option>
                          <option key="syarat pembelian buku" value="data lengkap">
                            Data Lengkap
                          </option>
                          <option key="syarat pembelian buku" value="penyelesaian materi">
                            penyelesaian materi
                          </option>
                        </select>
                      </div> */}
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Syarat Pembelian Buku<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" value={formData.condition} name="condition" onChange={handleChange} disabled={flag === "Detail"}>
                            <option value="">Pilih Syarat Pembelian Buku</option>
                            {Array.isArray(syaratPembelian) &&
                              syaratPembelian.map((syaratPembelianBuku) => (
                                <option key={syaratPembelianBuku.id} value={syaratPembelianBuku.description}>
                                  {syaratPembelianBuku.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {formData.condition == "2" && (
                          <>
                            <div className="form-group col-md-6 col-12">
                              <label>
                                Volume<span className="text-danger">*</span>
                              </label>
                              <select className="form-control" value={formData.volume_materi} name="volume_materi" onChange={handleChange} disabled={location.state?.from === "Detail"} required>
                                <option value="">Pilih Volume</option>
                                {Array.isArray(dataMateri) &&
                                  dataMateri.map((volume) => (
                                    <option key={volume.id} value={volume.id}>
                                      {volume.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="form-group col-md-6 col-12">
                              <label>
                                Topik<span className="text-danger">*</span>
                              </label>
                              <select className="form-control" value={formData.materi_id} name="materi_id" onChange={handleChange} disabled={location.state?.from === "Detail"}>
                                <option>Pilih Topik</option>
                                {Array.isArray(topik) &&
                                  topik.map((topiks) => (
                                    <option key={topiks.id} value={topiks.id}>
                                      {topiks.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-buku`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={flag === "Tambah" ? handleSubmit : handleEditSubmit} disabled={!validated || formData.volume < 100}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Ubah" ? (
                  <form className="form-valide" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Kode Buku<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="code" type="text" value={formData.code} onChange={handleChange} minLength="3" maxLength="5" required disabled={location.state?.from === "Detail"} />
                          <Form.Control.Feedback type="invalid">Kode buku harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Judul<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="name" type="text" value={formData.name} onChange={handleChange} minLength="3" maxLength="50" required disabled={location.state?.from === "Detail"} />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>

                        <div className="form-group col-md-6 col-12">
                          <label>
                            Berat Paket (gram)<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={formData.volume}
                            onChange={(e) => {
                              const { value } = e.target;
                              // Hanya karakter angka
                              const numericValue = value.replace(/\D/g, "");
                              setFormData((prevState) => ({
                                ...prevState,
                                volume: numericValue,
                              }));
                              console.log("tes", formData.volume);

                              const form = e.currentTarget.form;
                              const isValid = form.checkValidity();

                              setValidated(isValid);

                              if (isValid) {
                                form.classList.remove("was-validated");
                              } else {
                                form.classList.add("was-validated");
                              }
                            }}
                            maxLength={5}
                            required
                          />
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Dimensi Paket (P x L x T)<span className="text-danger">*</span>
                          </label>
                          <div className="d-flex flex-wrap">
                            <input
                              type="text"
                              className="form-control col-md-4"
                              value={formData.panjang}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  panjang: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled={location.state?.from === "Detail"}
                            />
                            <span className="align-self-center mx-2">X</span>
                            <input
                              type="text"
                              className="form-control col-md-3"
                              value={formData.lebar}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  lebar: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled={location.state?.from === "Detail"}
                            />
                            <span className="align-self-center mx-2">X</span>
                            <input
                              type="text"
                              className="form-control col-md-3"
                              value={formData.tinggi}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  tinggi: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled={location.state?.from === "Detail"}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Harga (Rp)<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            name="price"
                            type="text"
                            value={formData?.price}
                            onChange={(e) => {
                              const { value } = e.target;
                              const parsedValue = parseFloat(value.replace(/[^0-9]/g, ""));
                              const formattedValue = isNaN(parsedValue) ? 0 : currencyFormat(parsedValue);
                              setFormData((prevState) => ({
                                ...prevState,
                                price: formattedValue,
                              }));

                              const form = e.currentTarget.form;
                              const isValid = form.checkValidity();

                              setValidated(isValid);

                              if (isValid) {
                                form.classList.remove("was-validated");
                              } else {
                                form.classList.add("was-validated");
                              }
                            }}
                            required
                            maxLength="11"
                            disabled={location.state?.from === "Detail"}
                          />
                          <Form.Control.Feedback type="invalid">Harga harus diisi.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Deskripsi<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="description" type="text" value={formData.description} onChange={handleChange} minLength="5" maxLength="100" required disabled={location.state?.from === "Detail"} />
                          <Form.Control.Feedback type="invalid">Deskripsi harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>

                        {/* <div className='form-group col-md-6 col-12'>
                      <label>Syarat Pembelian Buku<span className="text-danger">*</span></label>
                      <select
                        className='form-control'
                        value={formData.syarat_pembelian}
                        name="syarat_pembelian"
                        onChange={handleChange}
                        disabled={location.state?.from === "Detail"}
                        required
                      >
                        <option value="">Pilih Syarat Pembelian Buku</option>
                        <option key="syarat pembelian buku" value="data lengkap">
                          Data Lengkap
                        </option>
                        <option key="syarat pembelian buku" value="penyelesaian materi">
                          penyelesaian materi
                        </option>
                      </select>
                    </div> */}
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Syarat Pembelian Buku<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" value={formData.condition} name="condition" onChange={handleChange} disabled={location.state?.from === "Detail"}>
                            <option value="">Pilih Syarat Pembelian Buku</option>
                            {Array.isArray(syaratPembelian) &&
                              syaratPembelian.map((syaratPembelianBuku) => (
                                <option key={syaratPembelianBuku.id} value={syaratPembelianBuku.description}>
                                  {syaratPembelianBuku.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {formData.condition == "2" && (
                          <>
                            <div className="form-group col-md-6 col-12">
                              <label>
                                Volume<span className="text-danger">*</span>
                              </label>
                              <select className="form-control" value={formData.volume_materi} name="volume_materi" onChange={handleChange} disabled={location.state?.from === "Detail"} required>
                                <option value="">Pilih Volume</option>
                                {Array.isArray(dataMateri) &&
                                  dataMateri.map((volume) => (
                                    <option key={volume.id} value={volume.id}>
                                      {volume.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="form-group col-md-6 col-12">
                              <label>
                                Topik<span className="text-danger">*</span>
                              </label>
                              <select className="form-control" value={formData.materi_id} name="materi_id" onChange={handleChange} disabled={location.state?.from === "Detail"}>
                                <option>Pilih Topik</option>
                                {Array.isArray(topik) &&
                                  topik.map((topiks) => (
                                    <option key={topiks.id} value={topiks.id}>
                                      {topiks.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-buku`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" onClick={flag === "Tambah" ? handleSubmit : handleEditSubmit} disabled={!validated || formData.volume < 100}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  </form>
                ) : flag === "Detail" ? (
                  <form className="form-valide" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="d-flex flex-wrap">
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Kode Buku<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="code" type="text" value={formData.code} onChange={handleChange} minLength="3" maxLength="5" required disabled />
                          <Form.Control.Feedback type="invalid">Kode buku harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Judul<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="name" type="text" value={formData.name} onChange={handleChange} minLength="3" maxLength="50" required disabled />
                          <Form.Control.Feedback type="invalid">Judul harus diisi minimal 3 karakter.</Form.Control.Feedback>
                        </div>

                        <div className="form-group col-md-6 col-12">
                          <label>
                            Berat Paket (gram)<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={formData.volume}
                            onChange={(e) => {
                              const { value } = e.target;
                              // Hanya karakter angka
                              const numericValue = value.replace(/\D/g, "");
                              setFormData((prevState) => ({
                                ...prevState,
                                volume: numericValue,
                              }));

                              const form = e.currentTarget.form;
                              const isValid = form.checkValidity();

                              setValidated(isValid);

                              if (isValid) {
                                form.classList.remove("was-validated");
                              } else {
                                form.classList.add("was-validated");
                              }
                            }}
                            maxLength={5}
                            required
                            disabled
                          />
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Dimensi Paket (P x L x T)<span className="text-danger">*</span>
                          </label>
                          <div className="d-flex flex-wrap">
                            <input
                              type="text"
                              className="form-control col-md-4"
                              value={formData.panjang}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  panjang: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled
                            />
                            <span className="align-self-center mx-2">X</span>
                            <input
                              type="text"
                              className="form-control col-md-3"
                              value={formData.lebar}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  lebar: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled
                            />
                            <span className="align-self-center mx-2">X</span>
                            <input
                              type="text"
                              className="form-control col-md-3"
                              value={formData.tinggi}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Hanya karakter angka
                                const numericValue = value.replace(/\D/g, "");
                                setFormData((prevState) => ({
                                  ...prevState,
                                  tinggi: numericValue,
                                }));

                                const form = e.currentTarget.form;
                                const isValid = form.checkValidity();

                                setValidated(isValid);

                                if (isValid) {
                                  form.classList.remove("was-validated");
                                } else {
                                  form.classList.add("was-validated");
                                }
                              }}
                              maxLength={3}
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Harga (Rp)<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            name="price"
                            type="text"
                            value={formData?.price}
                            onChange={(e) => {
                              const { value } = e.target;
                              const parsedValue = parseFloat(value.replace(/[^0-9]/g, ""));
                              const formattedValue = isNaN(parsedValue) ? 0 : currencyFormat(parsedValue);
                              setFormData((prevState) => ({
                                ...prevState,
                                price: formattedValue,
                              }));

                              const form = e.currentTarget.form;
                              const isValid = form.checkValidity();

                              setValidated(isValid);

                              if (isValid) {
                                form.classList.remove("was-validated");
                              } else {
                                form.classList.add("was-validated");
                              }
                            }}
                            required
                            maxLength="11"
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">Harga harus diisi.</Form.Control.Feedback>
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Deskripsi<span className="text-danger">*</span>
                          </label>
                          <input className="form-control" name="description" type="text" value={formData.description} onChange={handleChange} minLength="5" maxLength="100" required disabled />
                          <Form.Control.Feedback type="invalid">Deskripsi harus diisi minimal 5 karakter.</Form.Control.Feedback>
                        </div>

                        {/* <div className='form-group col-md-6 col-12'>
                      <label>Syarat Pembelian Buku<span className="text-danger">*</span></label>
                      <select
                        className='form-control'
                        value={formData.syarat_pembelian}
                        name="syarat_pembelian"
                        onChange={handleChange}
                        disabled
                        required
                      >
                        <option value="">Pilih Syarat Pembelian Buku</option>
                        <option key="syarat pembelian buku" value="data lengkap">
                          Data Lengkap
                        </option>
                        <option key="syarat pembelian buku" value="penyelesaian materi">
                          penyelesaian materi
                        </option>
                      </select>
                    </div> */}
                        <div className="form-group col-md-6 col-12">
                          <label>
                            Syarat Pembelian Buku<span className="text-danger">*</span>
                          </label>
                          <select className="form-control" value={formData.condition} name="condition" onChange={handleChange} disabled>
                            <option value="">Pilih Syarat Pembelian Buku</option>
                            {Array.isArray(syaratPembelian) &&
                              syaratPembelian.map((syaratPembelianBuku) => (
                                <option key={syaratPembelianBuku.id} value={syaratPembelianBuku.description}>
                                  {syaratPembelianBuku.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {formData.condition == "2" && (
                          <>
                            <div className="form-group col-md-6 col-12">
                              <label>
                                Volume<span className="text-danger">*</span>
                              </label>
                              <select className="form-control" value={formData.volume_materi} name="volume_materi" onChange={handleChange} disabled required>
                                <option value="">Pilih Volume</option>
                                {Array.isArray(dataMateri) &&
                                  dataMateri.map((volume) => (
                                    <option key={volume.id} value={volume.id}>
                                      {volume.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="form-group col-md-6 col-12">
                              <label>
                                Topik<span className="text-danger">*</span>
                              </label>
                              <select className="form-control" value={formData.materi_id} name="materi_id" onChange={handleChange} disabled>
                                <option>Pilih Topik</option>
                                {Array.isArray(topik) &&
                                  topik.map((topiks) => (
                                    <option key={topiks.id} value={topiks.id}>
                                      {topiks.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormBuku;
