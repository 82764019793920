import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";
import { POST } from "../../services/AxiosService";
import Form from "react-bootstrap/Form";

//
import logo from '../../images/logoBraille.png'

function Login(props) {
	const router = useNavigate();
	const [formData, setFormData] = useState({
		noHp: "",
		password: "",
	});
	const [validated, setValidated] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		// Validasi form di sini
		const form = e.currentTarget.form;
		const isValid = form.checkValidity();
		setValidated(isValid);
		if (isValid) {
			form.classList.remove("was-validated");
		} else {
			form.classList.add("was-validated");
		}
	};

	function callback(res) {
		Swal.close();
		Swal.fire({
			icon: "error",
			title: `${res.data.message}`,
		});
	}

	const handleLogin = async (e) => {
		e.preventDefault();

		const requestBody = {
			noHp: formData.noHp,
			password: formData.password,
		};

		Swal.fire({
			title: "Logging in...",
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});

		const res = await POST(`/auth/login-web`, requestBody, callback);
		if (res) {
			Swal.close();
			Swal.fire(
				// `${res.data.message}`,
				"Login Sukses"
				// "You clicked the button!",
				// "success"
			);

			const token = res.access_token;
			const refresh_token = res.refresh_token;
			const access_id = res.access_id;
			const access_name = res.access_name;
			const access_role = res.access_role;
			const access_resource = res.access_resource;
			// const role = res.access_role;
			// const name = res.access_name;

			// const photo = res.data.photo;
			

			localStorage.setItem("accessToken", token);
			localStorage.setItem("refreshToken", refresh_token);
			localStorage.setItem("accessId", access_id);
			localStorage.setItem("accessName", access_name);
			localStorage.setItem("accessRole", access_role);

			access_resource.forEach((item) => {
				const existingActions = JSON.parse(localStorage.getItem(item.name)) || [];
				if (!existingActions.includes(item.action)) {
					existingActions.push(item.action);
				}
				localStorage.setItem(item.name, JSON.stringify(existingActions));
			});
			// localStorage.setItem("name", name);
			// localStorage.setItem("accesRole", role);
			// localStorage.setItem("email", email);
			// localStorage.setItem("accessFullname", fullname);
			// localStorage.setItem("foodcourt", foodcourt);
			// localStorage.setItem("photo", photo);

			// console.log(token);
			// console.log(data);

			// {localStorage.getItem('accesRole') === 'tenant' ? (
			//   router("/tenant/dashboard")
			// ) : (
			//   router("/")
			// )}

			// if (role === "pengantar") {
			// 	router("/pengantar/order");
			// } else {
			// 	router("/");
			// }
			router("/");
		}
	};

	return (
		<div className="vh-100 d-flex justify-content-center align-items-center">
			<div className="bg-white p-4" style={{ width: "800px" }}>
				<div className="mb-4">
					<div className="d-flex">
						<img src={logo} alt="" width={140} height={40} className="logo-icon mr-2" />
						<h3 className="mb-1 p-2 font-w600">Yayasan Hikmah Mata Hati</h3>
					</div>
					<p style={{ fontSize: "21px" }}>Selamat Datang di Yayasan Hikmah Mata Hati</p>
				</div>

				<form onSubmit={handleLogin} noValidate validated={validated}>
					<div className="form-group">
						<label className="mb-2 ">
							<strong className="">Nomor HP</strong>
						</label>
						<input name="noHp" type="text" className="form-control" placeholder="Masukkan nomor HP" maxLength="14" pattern="[0-9]{8,}" required value={formData.noHp}
							// onChange={handleChange}
							onChange={(e) => {
								const { value } = e.target;
								// Hanya karakter angka
								const numericValue = value.replace(/\D/g, "");
								setFormData((prevState) => ({
									...prevState,
									noHp: numericValue,
								}));
								const form = e.currentTarget.form;
								const isValid = form.checkValidity();
								setValidated(isValid);
								if (isValid) {
									form.classList.remove("was-validated");
								} else {
									form.classList.add("was-validated");
								}
							}}
						/>
						<Form.Control.Feedback type="invalid">
							Nomor HP harus diisi.
						</Form.Control.Feedback>
					</div>
					<div className="form-group">
						<label className="mb-2"><strong className="">Password</strong></label>
						<input name="password" type="password" className="form-control" placeholder="Masukkan password" maxLength="199" required value={formData.password} onChange={handleChange} />
						<Form.Control.Feedback type="invalid">
							Password harus diisi.
						</Form.Control.Feedback>
					</div>
					{/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
									<div className="form-group">
										<div className="custom-control custom-checkbox ml-1 ">
											<input type="checkbox" className="form-check-input" id="basic_checkbox_1"/>
											<label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
										</div>
									</div>
								</div> */}
					<div className="text-right">
						<label className="text-right mb-4">Lupa Password</label>
					</div>
					<div className="text-center">
						<button type="submit" className="btn btn-primary btn-block" disabled={!validated}>Masuk</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Login