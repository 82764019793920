import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { addMethod } from "yup";

const SetProgressModul = () => {
  const location = useLocation();
  const { saldo } = location.state || {}; // Use a default empty object in case state is undefined
  console.log("saldoAwal", saldo);
  const { id, flag } = useParams();
  console.log(flag);
  const router = useNavigate();
  const [locations, setLocations] = useState(location.state?.from);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    studentId: "",
    no_hp: "",
    volume: [],
    topicId: "",
  });
  const [formDataCopy, setFormDataCopy] = useState({
    studentId: "",
    no_hp: "",
  });
  const [formDataCopyTo, setFormDataCopyTo] = useState({
    studentId: "",
    no_hp: "",
  });
  const [validated, setValidated] = useState(false);
  const [touched, setTouched] = useState(false);
  const formRef = useRef(null);
  const [volume, setVolume] = useState([]);
  const [topik, setTopik] = useState([]);
  const [tipePengeluaran, setTipePengeluaran] = useState([]);
  const [namaPelajar, setNamaPelajar] = useState("");
  const [idPelajar, setIdPelajar] = useState("");
  const [namaPelajarCopy, setNamaPelajarCopy] = useState("");
  const [idPelajarCopy, setIdPelajarCopy] = useState("");
  const [namaPelajarTo, setNamaPelajarTo] = useState("");
  const [idPelajarTo, setIdPelajarTo] = useState("");

  const permissions = JSON.parse(localStorage.getItem('MATERI_MATERI') || '[]');
  const canModify = permissions.some(permission => ['VIEW', 'POST'].includes(permission));


  const fetchDataByNoHp = async () => {
    const res = await GET(`/wisdom-story/pelajar/${formData.no_hp}`);
    setNamaPelajar(res?.data?.name);
    setIdPelajar(res?.data?.student_id);
  };

  const fetchDataByNoHpCopy = async () => {
    const res = await GET(`/wisdom-story/pelajar/${formDataCopy.no_hp}`);
    setNamaPelajarCopy(res?.data?.name);
    setIdPelajarCopy(res?.data?.student_id);
  };

  const fetchDataByNoHpCopyTo = async () => {
    const res = await GET(`/wisdom-story/pelajar/${formDataCopyTo.no_hp}`);
    setNamaPelajarTo(res?.data?.name);
    setIdPelajarTo(res?.data?.student_id);
  };

  console.log("idPelajar", idPelajar);
  console.log("topik", topik);

  // Dropdown Isi Volume
  const fetchVolume = async () => {
    const res = await GET(`materi/modul`);
    setVolume(res);
  };

  // Dropdown Isi Topik
  const fetchTopik = async (id) => {
    try {
      const res = await GET(`materi/modul/${id}`);
      setTopik(res.materis);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  // Dropdown Isi typePengeluaran
  const fetchTypePengeluaran = async () => {
    const res = await GET(`/master-data/type?type=Tipe Pengeluaran`);
    setTipePengeluaran(res?.data);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const volume = fetchVolume();
        const typePengeluaran = fetchTypePengeluaran();
        const topik = formData.volume ? fetchTopik(formData.volume) : Promise.resolve(); // Fetch topic if volume exists
        if (formData?.no_hp?.length > 0) {
          fetchDataByNoHp();
        }
        if (formDataCopy?.no_hp?.length > 0) {
          fetchDataByNoHpCopy();
        }
        if (formDataCopyTo?.no_hp?.length > 0) {
          fetchDataByNoHpCopyTo();
        }
        // Tunggu semua fetch selesai
        await Promise.all([volume, typePengeluaran, topik, fetchDataByNoHp, fetchDataByNoHpCopy, fetchDataByNoHpCopyTo]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAllData();
  }, [id, formData.no_hp, formDataCopy.no_hp, formDataCopyTo.no_hp]);

  useEffect(() => {
    if (formData.volume) {
      fetchTopik(formData.volume);
    }
  }, [formData.volume]);

  console.log("topik", topik);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setFormDataCopy((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // RESET PROGRESS
  const handleSubmitReset = async (e) => {
    e.preventDefault();
    const reqBody = {
      studentId: idPelajar,
      topicId: formData.topicId,
    };
    console.log("requestBody", reqBody);

    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await POST(`materi/inject`, reqBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: res.message,
        }).then(() => {
          Swal.close();
          setFormData({ studentId: "", no_hp: "", volume: "", topicId: "" }); // Adjust as needed
        });
      }
    }
  };

  // COPY
  const handleSubmitCopy = async (e) => {
    e.preventDefault();
    const reqBody = {
      studentFrom: idPelajarCopy,
      studentTo: idPelajarTo,
    };
    console.log("reqBody Copy", reqBody);
    const result = await Swal.fire({
      title: "Apakah Anda yakin akan menyimpan perubahan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5932EA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let res;
      res = await POST(`materi/copy`, reqBody);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Berhasil dicopy!",
        }).then(() => {
          Swal.close();
          // Reset the form data
          setFormDataCopy({ no_hp: "", nama: "" }); // Adjust as needed
          setFormDataCopyTo({ no_hp: "", nama: "" }); // Adjust as needed
          //   window.location.reload(); // Reload the page after success
        });
      }
    }
  };

  const handleSelectChange = (selectedOptions, name) => {
    const { value } = selectedOptions.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = formRef.current;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  console.log("FormData", formData);
  console.log("volume", volume);

  const currencyFormat = (number) => {
    if (!number) return "0";
    return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      // height: '50px',
      minHeight: "55px",
      borderColor: "#D9D9D9", // Border color
      boxShadow: "none", // Remove shadow
      "&:hover": { borderColor: "#1890FF" }, // Hover effect
    }),
  };

  return (
    <Fragment>
      {/* SET PROGRESS MODUL */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">Set Progress Modul</h4>
              {/* <Link className="btn btn-primary" to={`/daftar-alokasi-pengeluaran-infaq`}>
                  Kembali
                </Link> */}
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form className="form-valide" ref={formRef}>
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className="form-group col-md-6 col-12">
                        <label>
                          No Hp<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="no_hp"
                          type="text"
                          value={formData.no_hp}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setFormData((prevState) => ({
                              ...prevState,
                              no_hp: numericValue,
                            }));

                            const form = e.currentTarget.form;
                            const isValid = form.checkValidity();

                            setValidated(isValid);

                            if (isValid) {
                              form.classList.remove("was-validated");
                            } else {
                              form.classList.add("was-validated");
                            }
                          }}
                          placeholder="No Hp"
                          required
                        />
                        <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>
                          Nama<span className="text-danger">*</span>
                        </label>
                        <input className="form-control" name="nama" type="text" value={formData.no_hp ? namaPelajar : ""} placeholder="Nama" required disabled />
                        <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>
                          Set Volume<span className="text-danger">*</span>
                        </label>
                        <select className="form-control" value={formData.volume} name="volume" onChange={(selectedOptions) => handleSelectChange(selectedOptions, "volume")}>
                          <option value="">Pilih Volume</option>
                          {Array.isArray(volume) &&
                            volume.map((modul) => (
                              <option key={modul.id} value={modul.id}>
                                {modul.volume}
                              </option>
                            ))}
                        </select>
                        <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <label>
                          Set Topik<span className="text-danger">*</span>
                        </label>
                        <select className="form-control" value={formData.topicId} name="topikId" onChange={(selectedOptions) => handleSelectChange(selectedOptions, "topicId")}>
                          <option value="">Pilih Topik</option>
                          {Array.isArray(topik) &&
                            topik.map((topikId) => (
                              <option key={topikId.id} value={topikId.id}>
                                {topikId.name}
                              </option>
                            ))}
                        </select>
                        <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 d-flex">
                    <div className="col-xl-6">
                      {permissions.includes('CREATE') && (

                        <button
                          type="button"
                          className="btn btn-danger w-100"
                          onClick={handleSubmitReset}
                        // disabled={!validated}
                        >
                          Reset Progress
                        </button>
                      )}
                    </div>
                    <div className="col-xl-6">
                      {permissions.includes('CREATE') && (

                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={handleSubmitReset}
                        // disabled={!validated}
                        >
                          Set Progress
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* COPY PROGRESS MODUL */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-title mb-2">Copy Progress Modul</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form className="form-valide" ref={formRef}>
                  <div className="row">
                    <div className="d-flex flex-wrap">
                      <div className="form-group col-md-6 col-12">
                        <p>
                          <b>Copy From</b>
                        </p>
                        <label>
                          No. Hp<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="no_hp"
                          type="text"
                          value={formDataCopy.no_hp}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setFormDataCopy((prevState) => ({
                              ...prevState,
                              no_hp: numericValue,
                            }));

                            const form = e.currentTarget.form;
                            const isValid = form.checkValidity();

                            setValidated(isValid);

                            if (isValid) {
                              form.classList.remove("was-validated");
                            } else {
                              form.classList.add("was-validated");
                            }
                          }}
                          placeholder="No Hp"
                          required
                        />
                        <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <p style={{ visibility: "hidden" }}>
                          <b>Copy From</b>
                        </p>
                        <label>
                          Nama<span className="text-danger">*</span>
                        </label>
                        <input className="form-control" name="nama" type="text" value={formDataCopy.no_hp ? namaPelajarCopy : ""} placeholder="Nama" required disabled />
                        <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <p>
                          <b>Copy To</b>
                        </p>
                        <label>
                          No Hp<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="no_hp"
                          type="text"
                          value={formDataCopyTo.no_hp}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Hanya karakter angka
                            const numericValue = value.replace(/\D/g, "");
                            setFormDataCopyTo((prevState) => ({
                              ...prevState,
                              no_hp: numericValue,
                            }));

                            const form = e.currentTarget.form;
                            const isValid = form.checkValidity();

                            setValidated(isValid);

                            if (isValid) {
                              form.classList.remove("was-validated");
                            } else {
                              form.classList.add("was-validated");
                            }
                          }}
                          placeholder="No Hp"
                          required
                        />
                        <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        <p style={{ visibility: "hidden" }}>
                          <b>Copy From</b>
                        </p>
                        <label>
                          Nama<span className="text-danger">*</span>
                        </label>
                        <input className="form-control" name="nama" type="text" value={formDataCopyTo.no_hp ? namaPelajarTo : ""} placeholder="Nama" required disabled />
                        <Form.Control.Feedback type="invalid">Judul harus diisi minimal 5 karakter.</Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 d-flex">
                    <div className="col-xl-6">
                      {permissions.includes('CREATE') && (

                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={handleSubmitCopy}
                        // disabled={!validated}
                        >
                          Copy
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SetProgressModul;
