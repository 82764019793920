import React, { useContext, useEffect, useReducer, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse } from 'react-bootstrap';
import { Modal } from "react-bootstrap";
/// Link
import { Link } from "react-router-dom";
import { MenuList } from "./Menu";
import foodServing from "../../../images/food-serving.png";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
// import { ThemeContext } from "../../../context/ThemeContext";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const SideBar = () => {
  const [userAccess, setUserAccess] = useState({});
  useEffect(() => {
    // Ambil semua akses dari local storage
    const accessKeys = Object.keys(localStorage).filter((key) =>
      key.includes("_")
    );

    const accessData = {};
    accessKeys.forEach((key) => {
      accessData[key] = JSON.parse(localStorage.getItem(key));
    });

    setUserAccess(accessData);
  }, []);

  var d = new Date();

  // const {
  // 	iconHover,
  // 	sidebarposition,
  // 	headerposition,
  // 	sidebarLayout,
  //   ChangeIconSidebar,  
  // } = useContext(ThemeContext);

  const [addMenus, setAddMenus] = useState(false);
  const [state, setState] = useReducer(reducer, initialState);
  let handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )


  const handleMenuActive = status => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  }
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })
    }
  }


  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  /// Active menu


  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            const hasViewAccess = !data.content || data.content.some((item) =>
              userAccess[item.access] && userAccess[item.access].includes("VIEW")
            );

            if (!hasViewAccess) {
              return null;
            }

            let menuClass = data.classsChange;

            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                <li className={` ${state.active === data.title ? "mm-active" : ""}`} key={index}>
                  {data.content && data.content.length > 0 ? (
                    <Link
                      to={"#"}
                      className="has-arrow"
                      onClick={() => {
                        handleMenuActive(data.title);
                      }}
                    >
                      {data.iconStyle} <span className="nav-text">{data.title}</span>
                    </Link>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                  <Collapse in={state.active === data.title}>
                    <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                      {data.content &&
                        data.content.map((subData, subIndex) => {
                          return userAccess[subData.access] &&
                            userAccess[subData.access].includes("VIEW") ? (
                            <li
                              key={subIndex}
                              className={`${state.activeSubmenu === subData.title
                                ? "mm-active"
                                : ""
                                }`}
                            >
                              <Link
                                to={subData.to}
                                onClick={() => handleSubmenuActive(subData.title)}
                              >
                                {subData.title}
                              </Link>
                            </li>
                          ) : null;
                        })}
                    </ul>
                  </Collapse>
                </li>
              );
            }
          })}
        </ul>
        {/* <div className="add-menu-sidebar">
            <img src={foodServing} alt="foodServing" />
            <p className="mb-3">Organize your menus through button bellow</p>
            <span className="fs-12 d-block mb-3">Lorem ipsum dolor sit amet</span>
            <Link              
              onClick={()=>setAddMenus(true)}
              className="btn btn-secondary btn-rounded"
            >
              +Add Menus
            </Link>
          </div> */}
        <Modal show={addMenus} onHide={setAddMenus} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Menus</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label className="text-black font-w500">Food Name</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label className="text-black font-w500">Order Date</label>
                <input type="date" className="form-control" />
              </div>
              <div className="form-group">
                <label className="text-black font-w500">Food Price</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <button type="button" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

      </PerfectScrollbar>
    </div>
  );
}

export default SideBar;
