/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from "../../../services/AxiosService";
import Swal from 'sweetalert2';
import moment from "moment";

const DaftarKonten = () => {
  const [data, setData] = useState([]);
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const navigate = useNavigate();
  // validate
  const permissions = JSON.parse(localStorage.getItem('MASTER-DATA_CONTENT') || '[]');
  const canModify = permissions.some(permission => ['CREATE', 'UPDATE', 'DELETE', 'LOCK', 'VIEW', 'APPROVE'].includes(permission));

  const columns = [
    {
      name: 'Page',
      selector: row => row.page,
      sortable: true,
      field: "page",
    },
    {
      name: 'Selection',
      selector: row => row.selection,
      sortable: true,
      field: "selection",
    },
    {
      name: 'Tipe',
      selector: row => row.typeEntity.name,
    },
    {
      name: 'Konten',
      cell: (row) => {
        if (row.typeEntity.name.includes('Gambar')) {
          return <img src={`data:image/jpeg;base64,${row.content}`} style={{ maxWidth: "100px", maxHeight: "100px" }} alt="konten" />
        } else {
          return row.content
        }
      },
    },
    {
      name: 'Konten Tambahan',
      cell: (row) => {
        if (row.typeEntity.name.includes('Gambar')) {
          if (row.additional_content) {
            return <img src={`data:image/jpeg;base64,${row.additional_content}`} style={{ maxWidth: "100px", maxHeight: "100px" }} alt="konten" />
          } else {
            return ''
          }
        } else {
          return row.additional_content
        }
      },
    },
    {
      name: 'Update',
      selector: row => moment(row.updated_at).format("DD MMMM YYYY"),
    },
    {
      name: 'Updated By',
      selector: row => row.user?.name,
    },
    ...(canModify
      ? [{
        name: 'Aksi',
        width: "130px",
        cell: (row) => (
          <div>
            <Link className="btn btn-info shadow btn-xs sharp" title="Detail" to={`/${row.id}/form-konten/Detail`}>
              <i className="fa fa-eye"></i>
            </Link>

            {permissions.includes('UPDATE') && (
              <Link
                className="btn btn-primary shadow btn-xs sharp mx-1"
                to={`/${row.id}/form-konten/Edit`}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
            )}

            {permissions.includes('DELETE') && (
              <Link className="btn btn-danger shadow btn-xs sharp" title="delete" onClick={() => handleDelete(row)}>
                <i className="fa fa-trash"></i>
              </Link>
            )}
          </div>
        ),
      }]
      : [])
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
        alignItems: 'start',
      },
    },
  };
  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
    };
    const res = await GET(`master_data_content`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };
  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  const handleDelete = async (row) => {
    const result = await Swal.fire({
      title: `Apakah Anda yakin akan menghapus konten?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`master_data_content/${row.id}`);
      if (res) {
        Swal.fire({
          icon: "success",
          title: `Konten berhasil dihapus.`,
        }).then(() => {
          Swal.close();
          fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
        });
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Konten</Card.Title>
            </Card.Header>
            <div className="d-flex flex-row-reverse mt-4 px-5">
              <div className="input-group search-area d-xl-inline-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cari Konten"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <Link to="#">
                      <i className="flaticon-381-search-2" />
                    </Link>
                  </span>
                </div>
              </div>

              {permissions.includes('CREATE') && (
                <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-konten/Tambah`)}>
                  Tambah Konten
                </button>
              )}
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortFieldId={1}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarKonten