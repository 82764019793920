/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { Fragment, useEffect, useState } from 'react'
import PageTitle from '../../layouts/PageTitle'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Form from "react-bootstrap/Form";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import Dropdown from 'react-bootstrap/Dropdown';

const FormRole = () => {
  const { id, flag } = useParams();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  // dropdown
  const statuses = ['Read', 'Read and Write', 'No Access'];
  const [selectedStatus, setSelectedStatus] = useState(statuses);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchData();
      if (flag === 'detail' || flag === 'set-fitur') {
        fetchDataRoleModule();
      }
    }
  }, []);
  const fetchData = async () => {
    const res = await GET(`role/${id}`);
    setFormData(res?.data);
  };
  
  const fetchDataRoleModule = async () => {
    const res = await GET(`role-module/${id}`);
    setData(res?.data);
    setDataFiltered(res?.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let {
        name,
        description,
      } = formData;
  
      const requestBody = {
        name,
        description,
      };

      let res;
      let title = !id ? `Role ${name} berhasil ditambahkan.` : "Data Role berhasil diubah!";
      if (id) {
        if (flag === 'set-fitur') {
          res = await PATCH(`user/role/${id}`, { roleModule: data });
          title = "Role Fitur berhasil diubah.";
        } else {
          res = await PATCH(`user/role/${id}`, requestBody);
        }
      } else {
        res = await POST(`user/role`, requestBody);
      }
      if (res) {
        Swal.fire({
          icon: "success",
          title: title,
        }).then(() => {
          Swal.close();
          navigate(`/daftar-role`)
        });
      }
    }
  };

  const columns = [
    {
      name: 'Fitur',
      selector: row => row.menu,
    },
    {
      name: 'Read',
      selector: row => row.access,
      cell: (row) => {
        return (
          <div>
            <input
              disabled={flag === 'detail'}
              type="radio"
              name={row.module_id}
              value="Read"
              checked={row.access === "Read"}
              onChange= {handleChangeRadio}
            />
          </div>
        );
      }
    },
    {
      name: 'Read and Write',
      selector: row => row.access,
      cell: (row) => {
        return (
          <div>
            <input
              disabled={flag === 'detail'}
              type="radio"
              name={row.module_id}
              value="Write"
              checked={row.access === "Write"}
              onChange= {handleChangeRadio}
            />
          </div>
        );
      }
    },
    {
      name: 'No Access',
      selector: row => row.access,
      cell: (row) => {
        return (
          <div>
            <input
              disabled={flag === 'detail'}
              type="radio"
              name={row.module_id}
              value="No"
              checked={row.access === "No"}
              onChange= {handleChangeRadio}
            />
          </div>
        );
      }
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleChangeRadio = (e) => {
    const { name, value } = e.target;
    
    setData(data.map(item => { if (name === item.module_id) { return {"module_id":item.module_id,"is_write":(value === "Write" ? 1 : 0),"status":(value === "No" ? -1 : 1),"menu":item.menu,"access":value}; } else { return item; } }));
    setDataFiltered(dataFiltered.map(item => { if (name === item.module_id) { return {"module_id":item.module_id,"is_write":(value === "Write" ? 1 : 0),"status":(value === "No" ? -1 : 1),"menu":item.menu,"access":value}; } else { return item; } }));
    setValidated(true);
  };

  // dropdown
  const handleSelect = (option) => {
    if (selectedStatus.includes(option)) {
      setSelectedStatus(selectedStatus.filter((item) => item !== option));
    } else {
      setSelectedStatus([...selectedStatus, option]);
    }
  };

  useEffect(() => {
    setDataFiltered(data.filter((item) => (item.access === 'No' && selectedStatus.includes('No Access')) || (item.access === 'Write' && selectedStatus.includes('Read and Write')) || (item.access === 'Read' && selectedStatus.includes('Read'))));
  }, [selectedStatus]);

  return (
    <Fragment>
      <PageTitle activeMenu={`${id ? flag.split("-").map(f => f.charAt(0).toUpperCase() + f.slice(1)).join(" ") : 'Tambah'} Role`} motherMenu="Daftar Role" />
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            {(flag === 'detail' || flag === 'set-fitur') && (
              <div className="card-header">
                <div></div>
                <Link className="btn btn-primary mr-2" to={`/daftar-role`}>
                  Kembali
                </Link>
              </div>
            )}
            <div className='card-body'>
              <div className='form-validation mt-3'>
                <form className='form-valide' onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Nama Role<span className="text-danger">*</span></label>
                        <input
                          disabled={flag === 'detail' || flag === 'set-fitur'}
                          type='text'
                          className='form-control'
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          maxLength="50"
                          minLength="5"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Role harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label>Keterangan</label>
                    <textarea
                      disabled={flag === 'detail' || flag === 'set-fitur'}
                      className='form-control'
                      rows="5"
                      value={formData.description}
                      name="description"
                      onChange={handleChange}
                      maxLength="200"
                    />
                  </div>

                  {(flag === 'detail' || flag === 'set-fitur') && (
                    <>
                      <Dropdown className="mr-auto d-flex flex-row-reverse">
                        <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", width: "300px" }}>
                          {selectedStatus.join(', ')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {statuses.map((option, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => handleSelect(option)}
                              active={selectedStatus.includes(option)}
                            >
                              {option}
                            </Dropdown.Item> 
                          ))}
                        </Dropdown.Menu>
                        
                        <div className="mr-2">
                          Access :
                        </div>
                      </Dropdown>

                      <DataTable
                        columns={columns}
                        data={dataFiltered}
                        customStyles={customStyles}
                      />
                    </>
                  )}

                  {flag !== 'detail' && (
                    <div className="form-group mb-3 d-flex">
                      <div className="col-xl-6">
                        <Link className="btn btn-light w-100" to={`/daftar-role`}>
                          Batal
                        </Link>
                      </div>
                      <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary w-100" disabled={!validated}>
                          Simpan Perubahan
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormRole