import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Dropdown, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from '../../../services/AxiosService';
import moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";

const KonfigurasiMasterData = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [kota, setKota] = useState([]);
  const [selectedKota, setSelectedKota] = useState('');
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  // validate
  const permissions = JSON.parse(localStorage.getItem('MASTER-DATA_MASTER-DATA') || '[]');
  const canModify = permissions.some(permission => ['CREATE', 'UPDATE', 'DELETE', 'LOCK', 'VIEW', 'APPROVE'].includes(permission));

  const fetchData = async (page, perPage, sortField, sortOrder, search, kotaKabupaten, dateFrom, dateTo) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      city: kotaKabupaten,
      start_date: dateFrom,
      end_date: dateTo
    };
    const res = await GET(`/master-data/config`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  const fetchKotaKabupaten = async () => {
    const res = await GET(`master-data/type?type=Kota Kabupaten`);
    setKota(res?.data);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedKota, dateFrom, dateTo);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedKota, dateFrom, dateTo]);
  useEffect(() => {
    fetchKotaKabupaten();
  }, []);

  const columns = [
    {
      name: 'Nama Tipe',
      selector: row => row.type,
    },
    {
      name: 'Parent',
      selector: row => row.parent,
    },
    {
      name: 'Visibility',
      selector: row => row.show,
      // width: "130px",
      cell: (row) => {
        let displayText;
        if (row.show === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Tidak Disembunyikan
            </Badge>
          );
        } else if (row.show === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Disembunyikan
            </Badge>
          );
        } else {
          displayText = row.show;
        }
        return displayText;
      }
    },
    {
      name: 'Status',
      selector: row => row.status,
      width: "130px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
    ...(canModify
      ? [{
        name: 'Aksi',
        selector: row => row.id,
        width: "170px",
        cell: (row) => (
          <div>
            {permissions.includes('LOCK') && (
              <>
                {row.status === 1 ? (
                  <Link
                    className="btn btn-danger shadow btn-xs sharp"
                    title="Non-Aktif"
                  >
                    <i className="fa fa-lock"></i>
                  </Link>
                ) : (
                  <Link
                    className="btn btn-success shadow btn-xs sharp"
                    title="Aktif"
                  >
                    <i className="fa fa-unlock"></i>
                  </Link>
                )}&nbsp;
              </>
            )}

            {permissions.includes('UPDATE') && (
              <button
                className="btn btn-primary shadow btn-xs sharp"
                onClick={() => navigate(`/${row.id}/form-config-master-data/Ubah`)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </button>
            )}

            <button
              className="btn btn-info shadow btn-xs sharp mx-1"
              onClick={() => navigate(`/${row.id}/form-config-master-data/Detail`)}
              title="Detail"
            >
              <i className="fa fa-eye"></i>
            </button>

            {permissions.includes('DELETE') && (
              <Link className="btn btn-danger shadow btn-xs sharp mx-1" title="Delete" onClick={() => handleDeleteClick(row.id)}>
                <i className="fa fa-trash"></i>
              </Link>
            )}
          </div>
        ),
      }]
      : [])
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleSelectKota = (e) => {
    setSelectedKota(e.target.getAttribute('value'));
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(``);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus jadwal assessment!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Konfigurasi Master Data</Card.Title>
            </Card.Header>
            <div className="d-flex mt-4">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey", height: "55px" }} id="dropdown-basic">
                    {selectedKota?.length > 0 ? selectedKota : "Semua Visibility"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectKota}>
                      Semua Visibility
                    </Dropdown.Item>
                    <Dropdown.Item >
                      Visibility
                    </Dropdown.Item>
                    <Dropdown.Item >
                      Belum Visibility
                    </Dropdown.Item>
                    {/* {Array.isArray(kota) && (kota).map((kotas) => (
                          <Dropdown.Item key={kotas.id} value={kotas.name} onClick={handleSelectKota}>
                            {kotas.name}
                          </Dropdown.Item>
                        ))} */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <div className="d-flex">
                      <DatePicker className='form-control' value={dateFrom || ''} style={{ maxHeight: "45px" }} onChange={handleDateFromChange} dateFormat="yyyy-MM-dd" placeholderText='tanggal awal' />
                      <span className='mt-3 ml-1'>s</span><span className='mt-3'>/</span><span className='mt-3 mr-1'>d</span>
                      <DatePicker className='form-control' value={dateTo || ''} style={{ maxHeight: "45px" }} onChange={handleDateToChange} dateFormat="yyyy-MM-dd" placeholderText='tanggal akhir' />
                    </div> */}
              </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                {permissions.includes('CREATE') && (
                  <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-config-master-data/Tambah`)}>
                    Tambah Konfigurasi
                  </button>
                )}
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Konfigurasi"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={3}
                  defaultSortAsc={sortOrder === 'asc'}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )

















}

export default KonfigurasiMasterData;
