import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { DELETE, GET, PATCH } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { date } from "yup/lib/locale";
import { FaCheckCircle, FaTimesCircle, FaQuestionCircle } from "react-icons/fa";

const DaftarInboxConfig = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [tipePesan, setTipePesan] = useState([]);
  const [event, setEvent] = useState([]);
  const [klasifikasi, setKlasifikasi] = useState([]);
  console.log("aaa", klasifikasi);
  //server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedNameEvent, setSelectedNameEvent] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedNameKlasifikasi, setSelectedNameKlasifikasi] = useState("");
  const [selectedKlasifikasi, setSelectedKlasifikasi] = useState("");
  const [selectedNameTipePesan, setSelectedNameTipePesan] = useState("");
  const [selectedTipePesan, setSelectedTipePesan] = useState("");

  const permissions = JSON.parse(localStorage.getItem('INBOX_CONFIG') || '[]');
  const canModify = permissions.some(permission => ['VIEW', 'CREATE', 'UPDATE', 'DELETE'].includes(permission));

  const fetchData = async (page, perPage, sortField, sortOrder, search, tipePesan, selectedEvent, selectedKlasifikasi) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      message_type: tipePesan,
      event: selectedEvent,
      classification: selectedKlasifikasi,
    };
    const res = await GET(`/inbox-config`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.total);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedTipePesan, selectedEvent, selectedKlasifikasi);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };
  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTipePesan, selectedEvent, selectedKlasifikasi]);

  const fetchTipePesan = async () => {
    const res = await GET(`/master-data/type?type=Tipe Pesan`);
    setTipePesan(res?.data);
  };

  const fetchEvent = async () => {
    const res = await GET(`/master-data/type?type=Event`);
    setEvent(res?.data);
  };

  const fetchKlasifikasi = async () => {
    const res = await GET(`/master-data/type?type=Klasifikasi`);
    setKlasifikasi(res?.data);
  };

  useEffect(() => {
    fetchTipePesan();
    fetchEvent();
    fetchKlasifikasi();
  }, []);

  //   function currencyFormat(num) {
  //     num = parseFloat(num);
  //     return num
  //       .toFixed(2)
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  //       .replace(/\.00$/, "");
  //   }

  //   function currencyFormat(num) {
  //     num = parseFloat(num);
  //     return num
  //       .toFixed(2)
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  //       .replace(/\.00$/, "");
  //   }
  const columns = [
    {
      name: "Code",
      selector: (row) => row.code,
    },
    {
      name: "Tipe Pesan",
      selector: (row) => row.message_type?.name,
    },
    {
      name: "Event",
      selector: (row) => row.sub_event?.event?.name,
    },
    {
      name: "Sub Event",
      selector: (row) => row.sub_event?.name,
    },
    {
      name: "Syarat",
      selector: (row) => row.conditions,
    },
    {
      name: "Klasifikasi",
      selector: (row) => row.classification?.name,
    },
    {
      name: "Judul Pesan",
      selector: (row) => row.title,
    },
    {
      name: "Pesan",
      selector: (row) => row.content,
    },
    {
      name: "Sender",
      selector: (row) => row.sender?.name,
    },
    {
      name: "Inbox",
      selector: (row) => row.notification,
      cell: (row) => {
        let displayIcon;
        if (row.notification === 1) {
          displayIcon = <FaCheckCircle color="green" size={20} title="Aktif" />;
        }
        return displayIcon;
      },
      width: "80px",
    },
    ...(canModify
      ? [{
        name: "Aksi",
        selector: (row) => row.id,
        width: "150px",
        cell: (row) => (
          <div>
            {permissions.includes('VIEW') && (
              <button className="btn btn-info shadow btn-xs sharp mx-1" title="Detail" onClick={() => navigate(`/${row.id}/form-inbox-config/Detail`)}>
                <i className="fa fa-eye"></i>
              </button>
            )}
            {/* <button className="btn btn-info shadow btn-xs sharp mx-1" title="Edit" onClick={() => navigate(`/${row.id}/form-inbox-config/Ubah`)}>
                  <i className="fa fa-pencil"></i>
                </button> */}
            {permissions.includes('DELETE') && (
              <Link className="btn btn-danger shadow btn-xs sharp mx-1" title="Delete" onClick={() => handleDeleteClick(row.id)}>
                <i className="fa fa-trash"></i>
              </Link>
            )}

          </div>
        ),
      }]
      : [])
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/inbox-config/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus Inbox Config!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const handleSelectTipePesan = (e) => {
    setSelectedTipePesan(e.target.getAttribute("value"));
    setSelectedNameTipePesan(e.target.textContent);
  };

  const handleSelectEvent = (e) => {
    setSelectedEvent(e.target.getAttribute("value"));
    setSelectedNameEvent(e.target.textContent);
  };

  const handleSelecKlasifikasi = (e) => {
    setSelectedKlasifikasi(e.target.getAttribute("value"));
    setSelectedNameKlasifikasi(e.target.textContent);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Inbox Config</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4 ">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedTipePesan?.length > 0 ? selectedNameTipePesan : "Semua Tipe Pesan"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipePesan}>
                      Semua Tipe Pesan
                    </Dropdown.Item>
                    {Array.isArray(tipePesan) &&
                      tipePesan.map((pesantipe) => (
                        <Dropdown.Item key={pesantipe.id} value={pesantipe.description} onClick={handleSelectTipePesan}>
                          {pesantipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedEvent?.length > 0 ? selectedNameEvent : "Semua Event"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectEvent}>
                      Semua Event
                    </Dropdown.Item>
                    {Array.isArray(event) &&
                      event.map((eventTipe) => (
                        <Dropdown.Item key={eventTipe.id} value={eventTipe.id} onClick={handleSelectEvent}>
                          {eventTipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedKlasifikasi?.length > 0 ? selectedNameKlasifikasi : "Semua Klasifikasi"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelecKlasifikasi}>
                      Semua Klasifikasi
                    </Dropdown.Item>
                    {Array.isArray(klasifikasi) &&
                      klasifikasi.map((klasifikasiTipe) => (
                        <Dropdown.Item key={klasifikasiTipe.id} value={klasifikasiTipe.description} onClick={handleSelecKlasifikasi}>
                          {klasifikasiTipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-self-baseline px-4">
                {permissions.includes('CREATE') && (
                  <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-inbox-config/Tambah`)}>
                    Tambah Pesan
                  </button>
                )}

                <div className="input-group search-area d-xl-inline-flex">
                  <input type="text" className="form-control" placeholder="Cari Pesan" value={searchTerm} onChange={handleSearchChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>

              {/* <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-inbox-config`, { state: { from: 'Tambah' } })}>
                  Tambah Pesan
                </button> */}
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default DaftarInboxConfig;
