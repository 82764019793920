import React, { Fragment, useEffect, useState } from 'react';
import { Card, Row, Col } from "react-bootstrap";
import PageTitle from '../../layouts/PageTitle';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import { GET, PATCH, POST } from "../../../services/AxiosService";
import Swal from 'sweetalert2';

const FormResourceRole = () => {
  const { id, flag } = useParams();
  const [setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [isAnyChecked, setIsAnyChecked] = useState(false); // New state to track checkbox status
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchData();
      if (flag === 'detail' || flag === 'set-resource') {
        fetchDataRoleModule();
      }
    }
  }, []);

  const fetchData = async () => {
    const res = await GET(`role/${id}`);
    setFormData(res?.data);
  };

  const fetchDataRoleModule = async () => {
    const res = await GET(`role-resource/${id}`);
    console.log("Response Data:", res?.data); // Log the response data
    setData(res?.data);
    setDataFiltered(res?.data);
  };

  // Function to group data by the 'name' field
  const groupByName = (data) => {
    return data.reduce((acc, curr) => {
      // Check if group already exists
      if (!acc[curr.name]) {
        acc[curr.name] = [];
      }
      acc[curr.name].push(curr);
      return acc;
    }, {});
  };

  // Group data by name
  const groupedData = groupByName(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const form = e.currentTarget.form;
    const isValid = form.checkValidity();

    setValidated(isValid);

    if (isValid) {
      form.classList.remove("was-validated");
    } else {
      form.classList.add("was-validated");
    }
  };

  const handleCheckboxChange = (e, groupName, resourceId) => {
    const updatedData = data.map(item =>
      item.resource_id === resourceId
        ? { ...item, allow: e.target.checked }
        : item
    );
    setData(updatedData);
    setDataFiltered(updatedData);

    // Check if at least one checkbox is selected
    const isAnyChecked = updatedData.some(item => item.allow);
    setIsAnyChecked(isAnyChecked); // Update the state accordingly
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!isAnyChecked) {
  //     // If no checkboxes are checked, show an alert and prevent submission
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Peringatan",
  //       text: "Anda harus memilih set resource terlebih dahulu.",
  //     });
  //     return;
  //   }

  //   const result = await Swal.fire({
  //     title: 'Apakah Anda yakin akan menyimpan perubahan?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#5932EA',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Ya',
  //     cancelButtonText: 'Batal'
  //   });

  //   if (result.isConfirmed) {
  //     Swal.fire({
  //       title: "Loading...",
  //       allowOutsideClick: false,
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });

  //     // Filter checked items and prepare the roleResource payload
  //     const filteredData = data
  //       .filter(item => item.allow) // Include only checked items
  //       .map(item => ({
  //         resource_id: item.resource_id // Only send resource_id
  //       }));

  //     // Prepare the requestBody with only roleResource
  //     const requestBody = {
  //       roleResource: filteredData,  // Only send checked resources
  //     };

  //     // Debugging: log the requestBody before submitting it
  //     console.log("Request Body to be sent:", requestBody);

  //     // Simulate submission (Do not submit to the database yet)
  //     // Just log the response for debugging
  //     // Uncomment the following lines to actually submit to the database:
  //     /*
  //     let res;
  //     let title = !id
  //       ? "Role berhasil ditambahkan."
  //       : "Data Role berhasil diubah!";

  //     if (id && flag === 'set-resource') {
  //       res = await PATCH(`user/role/${id}`, requestBody);
  //       title = "Role Fitur berhasil diubah.";
  //     } else if (id) {
  //       res = await PATCH(`user/role/${id}`, requestBody);
  //     } else {
  //       res = await POST(`user/role`, requestBody);
  //     }

  //     if (res) {
  //       Swal.fire({
  //         icon: "success",
  //         title: title,
  //       }).then(() => {
  //         Swal.close();
  //         navigate(`/daftar-role`);
  //       });
  //     }
  //     */
  //     // For now, just log the request body and skip the actual submission
  //     Swal.fire({
  //       icon: "info",
  //       title: "Request body logged to console.",
  //     });
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: 'Apakah Anda yakin akan menyimpan perubahan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Filter checked items and prepare the roleResource payload
      const filteredData = data
        .filter(item => item.allow) // Include only checked items
        .map(item => ({
          resource_id: item.resource_id // Only send resource_id
        }));

      // Prepare the requestBody with only roleResource
      const requestBody = {
        roleResource: filteredData,  // Only send checked resources
      };

      let res;
      let title = !id
        ? "Resource berhasil ditambahkan."
        : "Resource berhasil diubah!";

      if (id && flag === 'set-resource') {
        res = await PATCH(`user/role/${id}`, requestBody);
        title = "Role Fitur berhasil diubah.";
      } else if (id) {
        res = await PATCH(`user/role/${id}`, requestBody);
      } else {
        res = await POST(`user/role`, requestBody);
      }

      if (res) {
        Swal.fire({
          icon: "success",
          title: title,
        }).then(() => {
          Swal.close();
          navigate(`/daftar-role`);
        });
      }
    }
  };

  const handleGlobalCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const updatedData = data.map(item => ({ ...item, allow: isChecked }));
    setData(updatedData);
    setDataFiltered(updatedData);

    // Update the "isAnyChecked" state
    setIsAnyChecked(isChecked);
  };

  return (
    <Fragment>
      <PageTitle activeMenu={`${id ? flag.split("-").map(f => f.charAt(0).toUpperCase() + f.slice(1)).join(" ") : 'Tambah'} Role`} motherMenu="Daftar Role" />
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            {(flag === 'detail' || flag === 'set-resource') && (
              <div className="card-header">
                <div></div>
                <Link className="btn btn-primary mr-2" to={`/daftar-role`}>
                  Kembali
                </Link>
              </div>
            )}
            <div className='card-body'>
              <div className='form-validation mt-3'>
                <form className='form-valide' onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className='form-group'>
                        <label>Nama Role<span className="text-danger">*</span></label>
                        <input
                          disabled={flag === 'set-resource'}
                          type='text'
                          className='form-control'
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          maxLength="50"
                          minLength="5"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama Role harus diisi minimal 5 karakter.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label>Keterangan</label>
                    <textarea
                      disabled={flag === 'set-resource'}
                      className='form-control'
                      rows="5"
                      value={formData.description}
                      name="description"
                      onChange={handleChange}
                      maxLength="200"
                    />
                  </div>
 

                  {/* Render Cards for Grouped Data */}
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <label className="mb-0">Set Resource<span className="text-danger">*</span></label>
                    <Form.Check
                      type="checkbox"
                      label="Check All"
                      onChange={handleGlobalCheckboxChange}
                      checked={data.every(item => item.allow)} // Check if all are checked
                      className="ml-3"
                    />
                  </div>
                  {Object.keys(groupedData).map((name, index) => (
                    <div key={index} className="col-12 mb-2">
                      <Card
                        className="mb-2"
                        style={{ border: '1px solid #17a2b8' }} // Inline style for the border
                      >
                        <Card.Header>{name}</Card.Header>
                        <Card.Body className="p-2" >
                          <div className="d-flex flex-wrap d-flex align-items-center justify-content-between">
                            {groupedData[name].map((item, subIndex) => (
                              <div
                                key={subIndex}
                                className="d-flex align-items-center justify-content-center my-1"
                                style={{ minWidth: "150px" }}
                              >
                                <Row>
                                  <Col><p className="mb-0" >{item.action}</p>
                                  </Col>
                                  <Col><label>
                                    <input
                                      type="checkbox"
                                      checked={item.allow}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, name, item.resource_id)
                                      }
                                      disabled={flag === "detail"} // Disable checkbox if 'detail'
                                    />
                                  </label></Col>
                                </Row>
                              </div>
                            ))}
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}

                  <div className="form-group mb-3 d-flex">
                    <div className="col-xl-6">
                      <Link className="btn btn-light w-100" to={`/daftar-role`}>
                        Batal
                      </Link>
                    </div>
                    <div className="col-xl-6">
                      <button type="submit" className="btn btn-primary w-100" disabled={!isAnyChecked}>
                        Simpan Perubahan
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormResourceRole;
