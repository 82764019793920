import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET } from '../../../services/AxiosService';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";

const DaftarPeminatKotaLain = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const permissions = JSON.parse(localStorage.getItem('ENTHUSIAST_ENTHUSIAST') || '[]');
  const canModify = permissions.some(permission => ['UPDATE', 'DELETE'].includes(permission));

  const fetchData = async (page, perPage, sortField, sortOrder, search, dateFrom, dateTo) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      start_date: dateFrom,
      end_date: dateTo
    };
    const res = await GET(`/enthusiast-other-city`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, dateFrom, dateTo);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, dateFrom, dateTo]);

  // const dataDummy = [
  //   {
  //     registrasi_date: '22 Juli 2024 15:19',
  //     name: "Sari Wirdaningsih Subagio",
  //     hp_no: '08838344625',
  //     city: 'Balikpapan',
  //   },
  //   {
  //     registrasi_date: '28 Juli 2024 06.13',
  //     name: "Ira A",
  //     hp_no: '08322873000',
  //     city: 'Yogyakarta',
  //   },
  //   {
  //     registrasi_date: '29 Juli 2024 08.30',
  //     name: "Ayu A",
  //     hp_no: '08322873006',
  //     city: 'Makassar',
  //   },
  //   {
  //     registrasi_date: '29 Juli 2024 21.30',
  //     name: "Budi Utomo",
  //     hp_no: '08322873007',
  //     city: 'Kupang',
  //   },
  // ]
  const columns = [
    {
      name: 'Waktu Registrasi Belajar Tatap Muka',
      selector: row => {
        const date = new Date(row.created_at);
        const formattedDate = date?.toLocaleDateString('id-ID', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        });
        const formattedTime = date?.toLocaleTimeString('id-ID', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return `${formattedDate} ${formattedTime}`;
      },
    },
    {
      name: 'Nama Pelajar',
      sortable: true,
      field: "user_name",
      selector: row => row.user_name,
      cell: row =>
        <button onClick={() => navigate(`/${row.student_id}/form-pelajar/Detail`, { state: { from: 'Detail' } })} style={{ background: "transparent", border: "none" }}>
          <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user_name}</span>
        </button>,
    },
    {
      name: 'No HP',
      sortable: true,
      field: "user_hp_no",
      selector: row => row.user_hp_no,
    },
    {
      name: 'Kota',
      sortable: true,
      field: "enthusiast_name",
      selector: row => row.enthusiast_name,
    },
    ...(canModify
      ? [{
        name: 'Aksi',
        selector: row => row.id,
        width: "130px",
        cell: (row) => (
          <div>
            {permissions.includes('UPDATE') && (

              <button
                className="btn btn-primary shadow btn-xs sharp mx-1"
                onClick={() => navigate(`/${row.id}/form-peminat-kota-lain/Ubah`)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </button>
            )}
            {permissions.includes('DELETE') && (
              <Link
                className="btn btn-danger shadow btn-xs sharp"
                title="Delete"
                onClick={() => handleDeleteClick(row.id)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            )}
          </div>
        ),
      }]
      : [])
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  }

  const handleDateFromChange = (date) => {
    const formattedDate = date?.toLocaleDateString('en-CA');
    setDateFrom(formattedDate);
  };
  const handleDateToChange = (date) => {
    const formattedDate = date?.toLocaleDateString('en-CA');
    setDateTo(formattedDate);
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/enthusiast-other-city/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus peminat kota lain!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Peminat Kota Lain</Card.Title>
            </Card.Header>
            <div className="d-flex mt-4">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex px-4">
                <div className="d-flex">
                  <DatePicker className='form-control' value={dateFrom || ''} style={{ maxHeight: "45px" }} onChange={handleDateFromChange} dateFormat="yyyy-MM-dd" placeholderText='Waktu Registrasi (from)' />
                  <DatePicker className='form-control mx-2' value={dateTo || ''} style={{ maxHeight: "45px" }} onChange={handleDateToChange} dateFormat="yyyy-MM-dd" placeholderText='Waktu Registrasi (to)' />
                </div>
              </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end px-4">
                <div className="input-group search-area d-xl-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari Pelajar"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarPeminatKotaLain