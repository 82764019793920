import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { DELETE, GET, PATCH } from "../../../services/AxiosService";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { date } from "yup/lib/locale";

const DaftarInbox = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [tipePesan, setTipePesan] = useState([]);
  //server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedNameTipePesan, setSelectedNameTipePesan] = useState("");
  const [selectedTipePesan, setSelectedTipePesan] = useState("");

  const permissions = JSON.parse(localStorage.getItem('INBOX_INBOX') || '[]');
  const canModify = permissions.some(permission => ['VIEW','CREATE', 'UPDATE', 'DELETE'].includes(permission));

  const fetchData = async (page, perPage, sortField, sortOrder, search, tipePesan) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      message_type: tipePesan,
    };
    const res = await GET(`/inbox`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.total);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedTipePesan);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const fetchTipePesan = async () => {
    const res = await GET(`/master-data/type?type=Tipe Pesan`);
    setTipePesan(res?.data);
  };

  useEffect(() => {
    fetchTipePesan();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedTipePesan]);

  // const fetchTipePesan = async () => {
  //   const res = await GET(`/master-data/type?type=Tipe Pesan`);
  //   setTipePesan(res?.data);
  // }
  // useEffect(() => {
  //   fetchTipePesan();
  // }, []);

  const columns = [
    {
      name: "Tipe Pesan",
      selector: (row) => row.message_type?.name,
    },
    {
      name: "Event",
      selector: (row) => row.sub_event?.event?.name,
    },
    {
      name: "Sub Event",
      selector: (row) => row.sub_event?.name,
    },
    {
      name: "Klasifikasi",
      selector: (row) => row.classification?.name,
    },
    {
      name: "Judul Pesan",
      selector: (row) => row.title,
    },
    {
      name: "Pesan",
      selector: (row) => row.content,
    },
    {
      name: "Penerima",
      selector: (row) => row,
      cell: (row) => (
        <div>
          {row.penerima?.map((pesan, index) => (
            <span key={pesan.id}>
              {pesan.name}
              {index < row.penerima.length - 1 ? "," : ""}
            </span>
          ))}
        </div>
      ),
    },
    {
      name: "Waktu Kirim",
      selector: (row) => {
        const date = new Date(row.waktu_kirim);
        const formattedDate = date?.toLocaleDateString("id-ID", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
        return `${formattedDate}`;
      },
    },
    ...(canModify
      ? [{
        name: "Aksi",
        selector: (row) => row.id,
        width: "150px",
        cell: (row) => (
          <div>
            {permissions.includes('VIEW') && (
              <button className="btn btn-info shadow btn-xs sharp mx-1" title="Detail" onClick={() => navigate(`/${row.id}/daftar-form-inbox/Detail`)}>
                <i className="fa fa-eye"></i>
              </button>
            )}
            {permissions.includes('UPDATE') && (
              <button className="btn btn-info shadow btn-xs sharp mx-1" title="Edit" onClick={() => navigate(`/${row.id}/daftar-form-inbox/Ubah`)}>
                <i className="fa fa-pencil"></i>
              </button>
            )}
            {permissions.includes('DELETE') && (
              <Link className="btn btn-danger shadow btn-xs sharp mx-1" title="Delete" onClick={() => handleDeleteClick(row.id)}>
                <i className="fa fa-trash"></i>
              </Link>
            )}
          </div>
        ),
      }]
      : [])
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/inbox/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus Panduan!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const handleSelectTipePesan = (e) => {
    setSelectedTipePesan(e.target.getAttribute("value"));
    setSelectedNameTipePesan(e.target.textContent);
  };
  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Inbox</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle style={{ background: "transparent", borderColor: "grey", color: "grey" }} id="dropdown-basic">
                    {selectedTipePesan?.length > 0 ? selectedNameTipePesan : "Semua Tipe Pesan"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectTipePesan}>
                      Semua Tipe Pesan
                    </Dropdown.Item>
                    {Array.isArray(tipePesan) &&
                      tipePesan.map((pesantipe) => (
                        <Dropdown.Item key={pesantipe.id} value={pesantipe.description} onClick={handleSelectTipePesan}>
                          {pesantipe.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-self-baseline px-4">
                {permissions.includes('CREATE') && (
                  <button className="btn btn-primary mr-2" onClick={() => navigate(`/daftar-form-inbox/Kirim`)}>
                    Kirim Pesan
                  </button>
                )}
              </div>
            </div>
            {/* <div className="d-flex align-self-baseline px-4">
                            <button className="btn btn-primary mr-2" 
                            onClick={() => navigate(`/daftar-form-inbox`, { state: { from: 'Kirim' } })}
                            >
                                Kirim Pesan
                            </button>
                        </div> */}
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DaftarInbox;
