import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from '../../../services/AxiosService';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtackSlash } from "@fortawesome/free-solid-svg-icons";

const DaftarCeritaHikmah = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  const permissions = JSON.parse(localStorage.getItem('WISDOM_STORY') || '[]');
  const canModify = permissions.some(permission => ['VIEW', 'CREATE', 'UPDATE', 'DELETE', 'LOCK'].includes(permission));

  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
    };
    const res = await GET(`/wisdom-story`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  const columns = [
    {
      name: 'Judul',
      selector: row => row.title,
    },
    {
      name: 'No HP Pelajar',
      selector: row => row.user?.hp_no,
    },
    {
      name: 'Nama Pelajar',
      selector: row => row.user?.name,
      cell: row =>
        <button onClick={() => navigate(`/${row.user?.student_id}/form-pelajar/Detail`, { state: { from: 'Detail' } })} style={{ background: "transparent", border: "none" }}>
          <span style={{ color: '#0C718C', textDecoration: 'underline' }}>{row.user?.name}</span>
        </button>,
    },
    {
      name: 'Narasumber',
      selector: row => row.source_person,
    },
    {
      name: 'Lokasi Narasumber',
      selector: row => row.location?.name,
    },
    {
      name: 'Tanggal Submit',
      selector: row => {
        const date = new Date(row.created_at);
        const formattedDate = date?.toLocaleDateString('id-ID', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        });
        return `${formattedDate}`;
      },
    },
    {
      name: 'Status',
      selector: row => row.status,
      width: "130px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
    permissions.includes('UPDATE') && {
      name: 'Urutan',
      selector: row => row.pin,
      width: "130px",
      cell: (row) => {
        const maxOrder = Math.max(...data.map(item => item.pin));
        const minOrder = Math.min(...data.map(item => item.pin).filter(pin => pin !== 0));

        return (
          <div>
            {row.pin !== 0 && (
              <>
                <Link className={`btn btn-success shadow btn-xs sharp mx-1 ${row.pin === minOrder ? 'disabled' : ''}`} title="up" onClick={() => handleUpClick(row.id)}>
                  <i className="fa fa-arrow-up"></i>
                </Link>

                <Link className={`btn btn-success shadow btn-xs sharp ${row.pin === maxOrder ? 'disabled' : ''}`} title="down" onClick={() => handleDownClick(row.id)}>
                  <i className="fa fa-arrow-down"></i>
                </Link>
              </>
            )}
          </div>
        );
      },
    },
    ...(canModify
      ? [{
        name: 'Aksi',
        selector: row => row.id,
        width: "180px",
        cell: (row) => {
          const totalPin = data.filter(item => item.pin > 0).length;

          return (
            <div>
              {permissions.includes('LOCK') && (
                <>
                  {row.status === 1 ? (
                    <Link
                      className="btn btn-danger shadow btn-xs sharp"
                      onClick={() => handleLockOpenClick(row.id)}
                      title="Non-Aktif"
                    >
                      <i className="fa fa-lock"></i>
                    </Link>
                  ) : (
                    <Link
                      className="btn btn-success shadow btn-xs sharp"
                      onClick={() => handleLockOpenClick(row.id)}
                      title="Aktif"
                    >
                      <i className="fa fa-unlock"></i>
                    </Link>
                  )}
                </>
              )}
              {permissions.includes('UPDATE') && (
                <>
                  {row.pin !== 0 ? (
                    <Link
                      className="btn btn-primary shadow btn-xs sharp ml-1"
                      title="Un-pin"
                      onClick={() => handlePinUnpin(row.id)}
                    >
                      <FontAwesomeIcon icon={faThumbtackSlash} />
                    </Link>
                  ) : (
                    <Link
                      className={`btn btn-primary shadow btn-xs sharp ml-1 ${totalPin === 5 ? 'disabled' : ''}`}
                      title="Pin"
                      onClick={() => handlePinUnpin(row.id)}
                    >
                      <i className="fa fa-thumb-tack"></i>
                    </Link>
                  )}
                </>
              )}
              {permissions.includes('VIEW') && (
                <button
                  className="btn btn-info shadow btn-xs sharp mx-1"
                  title="Detail"
                  onClick={() => navigate(`/${row.id}/form-cerita-hikmah/Detail`)}
                >
                  <i className="fa fa-eye"></i>
                </button>
              )}
              {permissions.includes('UPDATE') && (
                <button
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                  title="Edit"
                  onClick={() => navigate(`/${row.id}/form-cerita-hikmah/Ubah`)}
                >

                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {permissions.includes('DELETE') && (

                <Link
                  className="btn btn-danger shadow btn-xs sharp"
                  title="Delete"
                  onClick={() => handleDeleteClick(row.id)}
                >
                  <i className="fa fa-trash"></i>
                </Link>
              )}
            </div>
          )
        },
      }]
      : [])
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    // rows: {
    //   style: {
    //     backgroundColor: "#FFFFFF",
    //     "&:nth-child(2n)": {
    //       backgroundColor: "#F3F0FD",
    //     },
    //   },
    // },
  };
  const conditionalRowStyles = [
    {
      when: row => row.pin !== 0,
      style: {
        backgroundColor: '#084B5D',
        color: 'white'
      },
    },
    {
      when: row => row.pin === 0,
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    }
  ];

  const handleUpClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const res = await PATCH(`/wisdom-story/${id}/move-up`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke atas!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };
  const handleDownClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/wisdom-story/${id}/move-down`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Urutan dipindah ke bawah!",
      }).then(() => {
        Swal.close();
        fetchData();
      });
    }
  };

  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/wisdom-story/${id}/lock-unlock`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: `${res?.message}`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin hapus data?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`/wisdom-story/${id}`);
      if (res) {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Berhasil menghapus cerita hikmah!",
        }).then(() => {
          Swal.close();
        });
      }
    }
  };

  const handlePinUnpin = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`/wisdom-story/${id}/pin-unpin`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: `${res?.message}`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Cerita Hikmah</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-end mt-4 mr-5">
              {permissions.includes('CREATE') && (
                <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-cerita-hikmah/Tambah`)}>
                  Tambah Cerita
                </button>
              )}
              <div className="input-group search-area d-xl-inline-flex">
                <input type="text" className="form-control" placeholder="Cari Cerita" value={searchTerm} onChange={handleSearchChange} />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <Link to="#">
                      <i className="flaticon-381-search-2" />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  conditionalRowStyles={conditionalRowStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                // defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarCeritaHikmah