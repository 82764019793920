/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DELETE, GET, PATCH } from "../../../services/AxiosService";
import Swal from 'sweetalert2';

const DaftarPengguna = () => {
  const [data, setData] = useState([]);
  // server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const navigate = useNavigate();

  const permissions2 = JSON.parse(localStorage.getItem('USER_WEB') || '[]');
  const canModify2 = permissions2.some(permission2 => ['CREATE', "VIEW"].includes(permission2));

  const permissions = JSON.parse(localStorage.getItem('USER_USER') || '[]');
  const canModify = permissions.some(permission => ['UPDATE', 'DELETE', 'LOCK'].includes(permission));

  const columns = [
    {
      name: 'No HP',
      selector: row => row.hp_no,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Nama Lengkap',
      selector: row => row.name,
      sortable: true,
      field: "name",
    },
    {
      name: 'Role',
      sortable: true,
      field: "role",
      cell: (row) => {
        return row.userRole.filter(x => x.role).map(x => x.role.name).join(', ');
      }
    },
    {
      name: 'Status',
      selector: row => row.status,
      width: "130px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      }
    },
    {

    },
    ...(canModify
      ? [{
        name: 'Aksi',
        width: "160px",
        cell: (row) => (
          <div>
            {permissions.includes('UPDATE') && (
              <>
                {row.status === 1 ? (
                  <Link
                    className="btn btn-danger shadow btn-xs sharp"
                    onClick={() => handleLockClick(row.id)}
                    title="Non-Aktif"
                  >
                    <i className="fa fa-lock"></i>
                  </Link>
                ) : (
                  <Link
                    className="btn btn-success shadow btn-xs sharp"
                    onClick={() => handleLockOpenClick(row.id)}
                    title="Aktif"
                  >
                    <i className="fa fa-unlock"></i>
                  </Link>
                )}
              </>
            )}
            {permissions2.includes('VIEW') && (
              <Link className="btn btn-info shadow btn-xs sharp mx-1" title="Detail" to={`/form-pengguna/${row.id}/detail`}>
                <i className="fa fa-eye"></i>
              </Link>
            )}
            {permissions.includes('UPDATE') && (
              <Link
                className="btn btn-primary shadow btn-xs sharp"
                to={`/form-pengguna/${row.id}/edit`}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
            )}
            {permissions.includes('DELETE') && (
              <Link className="btn btn-danger shadow btn-xs sharp mx-1" title="delete" onClick={() => handleDelete(row)}>
                <i className="fa fa-trash"></i>
              </Link>
            )}

          </div>
        ),
      }]
      : [])
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };
  const fetchData = async (page, perPage, sortField, sortOrder, search) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
    };
    const res = await GET(`user`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.amount);
  };
  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder]);

  const handleDelete = async (row) => {
    const result = await Swal.fire({
      title: `Apakah Anda yakin akan menghapus pengguna ${row.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5932EA',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const res = await DELETE(`user/${row.id}`);
      if (res) {
        Swal.fire({
          icon: "success",
          title: `Pengguna berhasil dihapus.`,
        }).then(() => {
          Swal.close();
          fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
        });
      }
    }
  };

  const handleLockClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`user/${id}`, { status: 0 });
    if (res) {
      Swal.fire({
        icon: "success",
        title: `Pengguna ${res.name} tidak aktif.`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };
  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`user/${id}`, { status: 1 });
    if (res) {
      Swal.fire({
        icon: "success",
        title: `Pengguna ${res.name} aktif.`,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Daftar Pengguna</Card.Title>
            </Card.Header>
            <div className="d-flex flex-row-reverse mt-4 px-5">
              <div className="input-group search-area d-xl-inline-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cari Pengguna"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <Link to="#">
                      <i className="flaticon-381-search-2" />
                    </Link>
                  </span>
                </div>
              </div>
              {permissions2.includes('CREATE') && (
                <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-pengguna`)}>
                  Tambah Pengguna
                </button>
              )}
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortFieldId={3}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DaftarPengguna