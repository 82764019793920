import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { GET, PATCH } from "../../../services/AxiosService";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";

const DaftarKabupaten = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  //server side
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedNameStatus, setSelectedNameStatus] = useState("");
  const DropDownstatusBook = [
    {
      id: 1,
      description: "Aktif",
      name: "Aktif",
    },
    {
      id: 0,
      description: "Tidak Aktif",
      name: "Tidak Aktif",
    },
  ];
  // validate
  const permissions = JSON.parse(localStorage.getItem('DISTRICT_DISTRICT') || '[]');
  const canModify = permissions.some(permission => ['CREATE', 'UPDATE', 'DELETE', 'LOCK', 'VIEW', 'APPROVE'].includes(permission));

  const fetchData = async (page, perPage, sortField, sortOrder, search, DropDownstatusBook) => {
    const params = {
      page,
      size: perPage,
      sort: sortField,
      orderby: sortOrder,
      search,
      status: DropDownstatusBook,
    };
    const res = await GET(`/district`, params);
    setData(res?.data);
    setTotalRows(res?.meta?.total);
  };

  const handleSelectStatus = (e) => {
    setSelectedStatus(e.target.getAttribute("value"));
    setSelectedNameStatus(e.target.textContent);
  };

  const fetchServerData = () => {
    fetchData(currentPage, perPage, sortField, sortOrder, searchTerm, selectedStatus);
  };

  useEffect(() => {
    fetchServerData();
  }, [perPage, searchTerm, currentPage, sortField, sortOrder, selectedStatus]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
  };

  const handleSort = (column, direction) => {
    setSortField(column.field);
    setSortOrder(direction);
  };

  const handleLockClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`district/lockUnlockDistrict/${id}`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: res.message,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  const handleLockOpenClick = async (id) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await PATCH(`district/lockUnlockDistrict/${id}`);
    if (res) {
      Swal.fire({
        icon: "success",
        title: res.message,
      }).then(() => {
        Swal.close();
        fetchServerData(currentPage, perPage, sortField, sortOrder, searchTerm);
      });
    }
  };

  const columns = [
    {
      name: "ID Raja Ongkir",
      selector: (row) => row.rajaongkir_id,
    },
    {
      name: "Nama Provinsi",
      selector: (row) => row.province.name,
    },
    {
      name: "Nama Kota Kabupaten",
      selector: (row) => row.name,
    },
    {
      name: "Kode Pos",
      selector: (row) => row.postal_code,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      //   width: "180px",
      cell: (row) => {
        let displayText;
        if (row.status === 1) {
          displayText = (
            <Badge as="a" variant="outline-success badge-rounded">
              Aktif
            </Badge>
          );
        } else if (row.status === 0) {
          displayText = (
            <Badge as="a" variant="outline-primary badge-rounded">
              Tidak Aktif
            </Badge>
          );
        } else {
          displayText = row.status;
        }
        return displayText;
      },
    },
    ...(canModify
      ? [{
        name: "Aksi",
        selector: (row) => row.id,
        width: "180px",
        cell: (row) => (
          <div>
            {permissions.includes('LOCK') && (
              <>
                {row.status === 1 ? (
                  <Link className="btn btn-danger shadow btn-xs sharp" onClick={() => handleLockClick(row.id)} title="Non-Aktif">
                    {/* <FontAwesomeIcon icon={faLock} /> */}
                    <i className="fa fa-lock"></i>
                  </Link>
                ) : (
                  <Link className="btn btn-success shadow btn-xs sharp" onClick={() => handleLockOpenClick(row.id)} title="Aktif">
                    {/* <FontAwesomeIcon icon={faLockOpen} /> */}
                    <i className="fa fa-unlock"></i>
                  </Link>
                )}{" "}
                &nbsp;
              </>
            )}

            {permissions.includes('UPDATE') && (
              <button className="btn btn-primary shadow btn-xs sharp" onClick={() => navigate(`/${row.id}/form-kabupaten/Edit`)} title="Edit">
                {<i className="fa fa-pencil"></i>}
              </button>
            )}
          </div>
        ),
      }]
      : [])
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#FCFCFD",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n)": {
          backgroundColor: "#F3F0FD",
        },
      },
    },
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Kabupaten</Card.Title>
            </Card.Header>
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex flex-wrap px-4">
                <Dropdown className="mr-4">
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      borderColor: "grey",
                      color: "grey",
                      // borderRadius: "0",
                    }}
                    id="dropdown-basic"
                    width="200px"
                  >
                    {selectedStatus?.length > 0 ? selectedNameStatus : "Semua Status"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item key="all" value="" onClick={handleSelectStatus}>
                      Semua Status
                    </Dropdown.Item>
                    {Array.isArray(DropDownstatusBook) &&
                      DropDownstatusBook.map((status) => (
                        <Dropdown.Item key={status.id} value={status.id} onClick={handleSelectStatus}>
                          {status.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-self-baseline px-4">
                {permissions.includes('CREATE') && (
                  <button className="btn btn-primary mr-2" onClick={() => navigate(`/form-kabupaten/Tambah`)}>
                    Tambah Kabupaten
                  </button>
                )}
                <div className="input-group search-area d-xl-inline-flex">
                  <input type="text" className="form-control" placeholder="Cari Provinsi dan Kota" value={searchTerm} onChange={handleSearchChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Link to="#">
                        <i className="flaticon-381-search-2" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              {data && data.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                  onSort={handleSort}
                  defaultSortFieldId={1}
                />
              ) : (
                <p>Tidak ada data</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DaftarKabupaten;
